import  React, { useState , useEffect } from 'react'; 
import { makeStyles, useTheme } from '@material-ui/core/styles'; 
import Typography from '@material-ui/core/Typography'; 
import {Grid,Card,CardContent,CardActions,Button, ImageListItemBar} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'; 
import { useHistory } from 'react-router-dom';   
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import * as api from '../../Api'; 
import MobileStepper from '@material-ui/core/MobileStepper';  
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';  
import Navigation from '../../Nav/Nav';
import AlertDialog from '../Alert'; 
  
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap', 
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)', 
  }, 
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  cardroot: {
    minWidth: 275,
  }, 
  hide: {
    display: 'none',
  }, 
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
 
function WrittenTestImageAdd() { 
  const name = window.location.href.split('/').pop() 
  console.log(name) 
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory(); 
  const [alert, setAlert]= useState(false)
  const [isValidImage, setIsValidImage]=useState(null) 
  const [images, setImages]=useState("")
  const [viewImage, setViewImage] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
   
 useEffect(() => {
   getImages()   
 }, [])

 const getImages = () =>{
   api.getImages(name) 
  .then(response =>{
    if(response.data.answer_sheets)
    {
      setIsValidImage(response.data.answer_sheets)
    }
    else{
      setIsValidImage(null)
    }
    })
    .catch(e=>console.error(e))
 }

  const handleImages = (event) =>{ 
   setImages(event.target.files)
  }

  const handleClear = () =>{
    setImages("")
  }

  const handleViewImage = () =>{
    setViewImage(true)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleSubmit = ()=>
  {
    let data = new FormData()
    Object.keys(images).map((key)=>{
      data.append("answer_sheets[]",images[key])
    })
    
    console.log(data);
    api.setImages(name,data)
         .then(response => {
            if(response.data){
              getImages() 
              setImages("")
            } 
        }).catch(error => console.error(error))
  }

  const handleDelete = ()=>
  {
      api.removeImages(name) 
        .then(response => {
            if(response.data){
              getImages()
            } 
        }).catch(error => console.error(error))
  }

  return (
    <Navigation main={  
    <div className={classes.root}>
      <AlertDialog open={alert} close={()=>{setAlert(false)}} delete={()=>{history.push({pathname: `/WrittenListHandler/${name}`})}} message={`Your scores will be recorded, Confirm your action ?`} />
      <main className={classes.content}>
        <div className={classes.drawerHeader} /> 
    <Grid container xs={12} lg={12} spacing={2} justify= "center" lignItems="center">   
          <Grid item xs={12} lg={6} className={classes.listItem} id= "grid">   
        <Typography className={classes.title} style={{color:"black"}} gutterBottom>
          Candidate ID : {name}
        </Typography>  
        <Card className={classes.classroot}>
      <CardContent>
        <Typography variant="body2" component="p"> 
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table"> 
        <TableBody>  
           <TableRow>
             <TableCell colSpan="3" align="center"> 
            <div className={classes.root}>
      <ImageList className={classes.imageList} cols={2.5} > 
        {Object.keys(images).map((key,index) => (
          <ImageListItem key={index}>
            <img src={URL.createObjectURL(images[key])} alt={images[key].name}/>
            <ImageListItemBar
              title={images[key].name}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }} 
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
    </TableCell>
           </TableRow> 
    {!isValidImage ?   
            <TableRow>
              <input id="image_file" multiple type="file" style={{display:"none"}} onChange={handleImages}/>
              <TableCell colSpan="3" align="center"> 
                 <Button style={{color:"white",backgroundColor:"#f37721"}}    margin="normal" variant="contained" color='default'>{(!images) ? <label htmlFor="image_file" > Upload answer sheet    </label> : <Typography onClick={handleSubmit} >Submit</Typography>}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
       {images &&  <Button margin="normal" onClick={handleClear} variant="contained" color='default'>Clear</Button> }
               </TableCell>  
      </TableRow>  
            :
            <>
            <TableRow>
            <input id="image_file" multiple type="file" style={{display:"none"}} onChange={handleImages}/>
              <TableCell colSpan="3" align="center"> <Button style={{color:"white",backgroundColor:"#f37721"}}    margin="normal" variant="contained"   color='default'> {(!images) ? <label htmlFor="image_file" > Re-upload Answer Sheet </label> : <Typography onClick={handleSubmit} >Submit</Typography>}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
              {images &&  <Button margin="normal" onClick={handleClear} variant="contained" color='default'>Clear</Button> }
      </TableCell> 
            </TableRow> 
           { 
            viewImage && 
            <TableRow>  
              <TableCell align="center" colSpan="3"> 
                <img src={isValidImage[activeStep]} alt={isValidImage[activeStep]}  width="500px" height="600px" /> 
                  <MobileStepper
        steps={isValidImage.length}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === isValidImage.length - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />    
           </TableCell>
         </TableRow> 
         } 
            <TableRow>  
              <TableCell align="center" colSpan="3">
              {!viewImage ?
                <Button  style={{color:"#f37721",backgroundColor:"white",borderColor:"#f37721"}} variant="outlined"   margin="normal" variant="contained" onClick={handleViewImage} color='default'>View answer sheet</Button>
               :
              <>
               <Button margin="normal" onClick={()=>{setViewImage(false)}} variant="contained" color='default'>Close</Button>&nbsp;
            <Button margin="normal"  variant="contained" onClick={handleDelete} color='default'>Delete</Button>
            </>
              }
      </TableCell>
            </TableRow>
            </>
          } 
</TableBody>
      </Table>
    </TableContainer>
        </Typography>
      </CardContent>
      </Card>
</Grid>
<Grid item xs={12} lg={6} className={classes.listItem} id= "grid">   
        <Card className={classes.classroot}> 
      <br/> 
      <Button  margin="normal" variant="contained"  onClick={()=>{history.push({pathname: `/evaluation/1`})}} color='default'>Back</Button>&nbsp;&nbsp;&nbsp;&nbsp;
      <Button  style={{color:"white",backgroundColor:"#f37721"}}   margin="normal" variant="contained" onClick={()=>{setAlert(true)}} color='default'>Score</Button>
      <CardActions/>
    </Card>
     </Grid>
     </Grid> 
      </main>
    </div>
    } drawerIndex={5}/>
  );
}
export default  WrittenTestImageAdd
