import React from 'react'; 
import {useState, useEffect } from 'react'; 
import { Switch } from '@material-ui/core'; 
import {makeStyles , withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'; 
import * as api from '../../Api';
import Navigation from '../../Nav/Nav';
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
 
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    cardroot: {
        minWidth: 275
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
}));

export default function DocumentEdit(props) {
    const documentQuery = props.location.query; 
    const doc = documentQuery.document;  
    const location = doc.id 
    const classes = useStyles(); 
    const history = useHistory()
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [moodle, setMoodle] = useState(false);
    const [moodleRight, setMoodleRight] = useState(false);
    const [moodleAccess, setMoodleAccess] = useState()
    const [moodleStateCreate ,setMoodleStateCreate] = useState({candidate_id:doc.candidate_id})
    const [moodleState ,setMoodleState] = useState(doc.candidate_id)
    const [state, setState] = useState({  
        deposit_amount :doc.deposit_amount,
        photo: doc.photo,
        gov_id: doc.gov_id,
        pass_book: doc.pass_book,
        offer_letter: doc.offer_letter,
        background_information: doc.background_information,
        reg_form: doc.reg_form,
        edu_cert: doc.edu_cert,
        background_information_doc: "",
        edu_cert_doc:"",
        reg_form_doc:"",
        offer_letter_doc:"",
        pass_book_doc:"",
        gov_id_doc:"",
        photo_doc:""
      }); 

const handleChange = (event) => {
    console.log("set",event.target.checked)
setState({ ...state, [event.target.name]: event.target.checked });
};
      
const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: "#f37721",
          borderColor: "#f37721",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

useEffect(() => { 
    api.getMoodle(moodleState) 
        .then(function (response) {
          // handle success 
          setMoodleAccess(response.data);
          if(response.data.status)
          {
            setMoodleRight(true) 
          } 
        })
        .catch(function (error) { 
          console.log(error);
        }) 
}, [])
 
const createDocument = async()=>{
    setOpen(true) 
    let data = new FormData()
    Object.keys(state).map((key)=>{
        if(state[key] !== ""){
            data.append(`document_verification[${key}]`,state[key])
        } 
    })  
     await api.setDocumentVerify(location,data)
     .then(response => {
         if(response.data)
         {
             if(moodle){ 
                 api.addMoodle(moodleStateCreate)
             } 
        toast.success('Document updated Successfully !', {
            position: "bottom-center",
            autoClose: 3000,
            onClose: ()=>{setLoader(true)},
            hideProgressBar: false,
            closeOnClick: true, 
            })
    }  
    else{
        toast.error('Error in Connection!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true, 
            });
    }
}).catch(error => { 
    console.error(error) 
    toast.error('Error in Connection!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true, 
        });
})
setOpen(false)
}
 
    return (
        <Navigation main={
        <div className={
            classes.root
        }>
            <main className={
                classes.content
            }>
                <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                <BackDrop open={open} handleClose={()=>{setOpen(false)}}/>
                <Grid container direction="row" justify="flex-end" alignItems="center"
                    style={
                        {marginBottom: '2%'}
                }>
                    {/* <Fab variant="extended" color="primary" aria-label="add" component={Link} to="/candidatescan">
               <AddIcon className={classes.extendedIcon} />  
                   Scan Aadhar
           </Fab>  */} </Grid>
                <Grid container justify="center" alignItems="center">
                    <Grid item
                        xs={12}
                        lg={6}>
                        <form className="form"
                             >
                            <Card className={
                                classes.classroot
                            }>
                                <CardContent>
                                    <Typography className={
                                            classes.title
                                        }
                                        variant="h5"
                                    style={
                                        {color: "black"}
                                    }
                                        color="textSecondary"
                                        gutterBottom>
                                        Edit Verification Details
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <TableContainer component={Paper}>
                                            <Table className={
                                                    classes.table
                                                }
                                                aria-label="spanning table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        } width="150px">
                                                              
                                                            Candidate Name<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan="2" width="700px">
                                                       
                                                                {doc.candidate_name} 
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        }>Photo<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="left"   width="150px"  height="85px"  > 
                                                    
                                                        <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch   checked={state.photo} onChange={handleChange}  name="photo" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        {state.photo && 
                                                        <TextField id="photo_file"  type="file" margin="dense" variant="outlined" onChange={(event)=>{ setState(prev => ({
                                                            ...prev,
                                                            photo_doc: event.target.files[0]
                                                        }))}} />
                                                        }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        }>Government ID.Proof</TableCell>
                                                        <TableCell align="left" height="85px" >
                                                        <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch  checked={state.gov_id} onChange={handleChange}  name="gov_id" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        {state.gov_id && 
                                                        <TextField id="gov_id_file" type="file" margin="dense" variant="outlined" onChange={(event)=>{ setState(prev => ({
                                                            ...prev,
                                                            gov_id_doc: event.target.files[0]
                                                        }))}} />
                                                        }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        }>Pass Book<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="left" height="85px" >
                                                            <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch   checked={state.pass_book} onChange={handleChange}  name="pass_book" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        {state.pass_book && 
                                                        <TextField id="pass_book_file" type="file" margin="dense" variant="outlined" onChange={(event)=>{ setState(prev => ({
                                                            ...prev,
                                                            pass_book_doc: event.target.files[0]
                                                        }))}} />
                                                        }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        }>Offer Letter<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="left" height="85px" >
                                                            <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch  checked={state.offer_letter} onChange={handleChange}  name="offer_letter" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        {state.offer_letter && 
                                                        <TextField id="offer_letter_file" type="file" margin="dense" variant="outlined" onChange={(event)=>{ setState(prev => ({
                                                            ...prev,
                                                            offer_letter_doc: event.target.files[0]
                                                        }))}}/>
                                                         }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        }>Background Information<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="left" height="85px" >
                                                            <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch   checked={state.background_information} onChange={handleChange}  name="background_information" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        {state.background_information && 
                                                        <TextField id="background_information_file" type="file" margin="dense" variant="outlined" onChange={(event)=>{ setState(prev => ({
                                                            ...prev,
                                                            background_information_doc: event.target.files[0]
                                                        }))}} />
                                                         }
                                                         </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        }>Registration Form<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="left" height="85px" >
                                                            <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch   checked={state.reg_form} onChange={handleChange}  name="reg_form" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        {state.reg_form && 
                                                        <TextField id="reg_form_file" type="file" margin="dense" variant="outlined" onChange={(event)=>{ setState(prev => ({
                                                            ...prev,
                                                            reg_form_doc: event.target.files[0]
                                                        }))}} />
                                                         }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        }>Education Certificate<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="left" height="85px" >
                                                            <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch   checked={state.edu_cert} onChange={handleChange}  name="edu_cert" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        {state.edu_cert && 
                                                        <TextField id="edu_cert_file" type="file" margin="dense" variant="outlined" onChange={(event)=>{ setState(prev => ({
                                                            ...prev,
                                                            edu_cert_doc: event.target.files[0]
                                                        }))}} />
                                                         }
                                                         </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        } colSpan="2">Deposit Amount 
                                                        </TableCell>
                                                        <TableCell align="left" ><TextField fullWidth type="number"
                                                                value={state.deposit_amount}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                                                  }} 
                                                                onChange={(event)=>{ setState(prev => ({
                                                                    ...prev,
                                                                    deposit_amount: event.target.value
                                                                }))}}/>
                                                        </TableCell>
                                                        {/* <TableCell></TableCell> */}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={
                                                            {fontWeight: 'bold'}
                                                        } colSpan="2">Learning Access<spam style={
                                                                {color: 'red'}
                                                            }>*</spam>
                                                        </TableCell>
                                                        <TableCell align="left"> 
                                                        {moodleRight ? "Given Access to Moodle":
                                                            <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                            <Grid item>No</Grid>
                                                            <Grid item >
                                                                <AntSwitch   checked={moodle} onChange={()=>{setMoodle(true)}}  name="moodle" />
                                                                  </Grid>
                                                            <Grid item>Yes</Grid>
                                                            </Grid>
                                                        </Typography>}
                                                        </TableCell>
                                                    </TableRow> 
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Typography>
                                </CardContent> 
                                <Button margin="normal" variant="contained" 
                                style={
                                    {
                                        color: "white",
                                        backgroundColor: "#f37721"
                                    }
                                }
                                onClick={createDocument}  
                                color='default'>Submit</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button margin="normal" variant="contained"
                                style={
                                    {backgroundColor: "#d9d9d9"}
                                }
                                href="/documents"
                                color='default'>Cancel</Button>
                                <CardActions></CardActions>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </main>
            <div> { loader ? 
                 history.push({pathname: `/documents`})  : null }  
                </div>
        </div>
        } drawerIndex={8}/>
    );
}

