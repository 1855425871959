import React , { useState , useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider'; 
import { useHistory } from "react-router-dom";
import * as api from '../../Api';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Navigation from '../../Nav/Nav';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 100,
  },
   container: {
    maxHeight: 440,
  },
  listItem: {
    marginTop: 35,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
  },
    title: {
    marginTop: 0,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'left',    
  },
  gridwidth: {
    marginTop: 5,
    width: '95%',
    marginLeft: '2%',
    marginBottom: '2%',
  },
  button: {
    textAlign: 'center',
    marginBottom: '2%',
  },
  tabletitle:{
    marginTop: 25,
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: '40%', 
    textAlign: 'left',   
  },
  evaluator: {
    width: '40%',
  },
  evaluatorcontainer: {
    marginTop: '2%',
    marginBottom: '2%',
  },
  evaluatortable: {
    marginTop: '2%',
    width: '70%',
  },
   score: {
    marginTop: 0,
    marginLeft: '80%',
    marginBottom: '2%',
  },
  textfield: {
    marginTop: 0,
    marginLeft: '63%',
  },
});

function PersonalList() {
  const classes = useStyles()
  const name = window.location.href.split('/').pop() 
  const [list, setList] = useState([])  
  const history = useHistory()
  const cancelClick = () => history.push('/evaluation/3')
  
const getData =() =>{
  api.getScore(sessionStorage.getItem("user_id"),name,3)
   .then(function (response) {
    // handle success 
    setList(response.data); 
    //console.log(list)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
}

  useEffect(() => {
    // Make a request for a user with a given ID
  getData()
  }, []);

  const inputHandler = (id, event,max_score) =>{
    if(event.target.value < 0)
    { 
      event.target.value = 0 
      list.map((row) => {
        if (parseInt(row.id) === parseInt(id)) {
          row.outcome = 0 
        }        
    })  
}
else if(event.target.value > max_score){ 
  event.target.value = max_score
  list.map((row) => {
    if (parseInt(row.id) === parseInt(id)) {
      row.outcome = max_score
    }        
})
}
else {
  list.map((row) => {
    if (parseInt(row.id) === parseInt(id)) {
      row.outcome = event.target.value 
    }        
})
}  
}
 
const getTotalScores = (id) =>{
  let sumOfScore = 0
  list.map((row) => { 
     sumOfScore += row.outcome
 })
 return sumOfScore
 }

const handleSubmit=(programId,inputScore,processId)=>
 {
   console.log(inputScore)
   let score = {
    "candidate_id" : name,
    "program_question_id" : programId,
    "user_id" : sessionStorage.getItem("user_id"),
    "outcome" : inputScore
  }
  api.setScore(processId,score)
  .then(response => { 
          if(response.data) 
          { 
            document.getElementById(response.data.id).style.visibility="visible"
        getData()
          }     
        }).catch(error => {
           })
 } 

  return (
    <Navigation main={
    <> 
    <Grid container  spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} justify= "center" > 
           <Grid item className={classes.listItem} id= "grid">   
           <Grid className={classes.gridwidth}>
             <Typography className={classes.title} color="textSecondary" gutterBottom>
                   Personal Evaluation <span style={{fontSize:12}}>(Total Score = {list.length>0 ? getTotalScores(list.id):0})</span>
               </Typography>  
               <Divider /> <br />
                <Paper className={classes.root}>   
                <TableContainer component={Paper} id="table">
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead style={{ backgroundColor: '#eff3f5' }}>
                      <TableRow >   
                        <TableCell style={{ fontWeight: 'bold' }}>#</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Question Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Question</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {list.map((row,i) => (
                        <TableRow key={row.id} style={{cursor: 'pointer'}} id="tablerow">
                          <TableCell component="th" scope="row" >
                            {i+1}
                          </TableCell>
                          <TableCell >{row.characteristic}</TableCell>
                          <TableCell >{row.question}</TableCell>
                          <TableCell >
                           <input 
                             type="number" 
                             id="numberfield" 
                             name="number" 
                             defaultValue={row.outcome}
                             min="0" 
                             max={row.max_score} 
                             style={{ width: 50, height: 20 }}   
                             onChange={(event) => inputHandler(row.id, event,row.max_score)} 
                             onBlur={(e)=>handleSubmit(row.program_question_id,e.target.value,row.id)}
                           /> 
                            &nbsp;/{row.max_score}&nbsp;
                           <CheckCircleIcon id={row.id} style={{color:"green",visibility:"hidden"}}  />  
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> 
                  <br/>
                  <div className={classes.button} > 
                     <Button variant="contained" color="primary" onClick={()=>{history.push("/evaluation/3")}} style={  {  color: "white", backgroundColor: "#f37721",marginLeft: 15, fontWeight: 'bold'   }  } >
                        Save & Close
                      </Button>      
                    </div>
                </TableContainer>
                </Paper>  
               <Divider /> <br /> 
              </Grid>                        
           </Grid>
          </Grid>
        </Grid>
      </Grid>
      </>
    } drawerIndex={5}/>
  );
} 
export default PersonalList

