import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import {auth} from '../firebase';
import firebase from 'firebase';
import * as api from '../Api';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.alcindia.org/">
                ALC India
            </Link>
            {' '}
            {
            new Date().getFullYear()
        }
            {'.'} </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    header: {
        display: "grid",
        alignContent: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#f37721"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    textInput: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1.8px',
            borderColor: "grey"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1.5px',
            borderColor: "#f37721"
        }
    }
}));

export default function SignIn() {
    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = useState()
    const [otpNumber, setOtpNumber] = useState()
    const [otp, setOtp] = useState(false)
    const [confirmationResult, setConfirmationResult] = useState()
    const history = useHistory()
    const [loginError, setLoginError] = useState("")
    const localStorage = window.localStorage

    const handlePhoneNo = (event) => {
        if (event.target.value.length <= 10) {
            setPhoneNumber(event.target.value)
        }
    }

    const handleSubmit = () => {
        if (phoneNumber) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {'size': 'invisible'})
            console.log(phoneNumber)
            localStorage.setItem('number', phoneNumber)
            const appVerifier = window.recaptchaVerifier;
            auth.signInWithPhoneNumber(`+91${phoneNumber}`, appVerifier).then((confirmationResult) => {
                setConfirmationResult(confirmationResult)
                setOtp(true)
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    const verifyOtp = (otp) => {
        window.confirmationResult = confirmationResult;
        confirmationResult.confirm(otp).then((result) => {
            const user = result.user;
            if (user) {
                auth.currentUser.getIdTokenResult().then((res) => {
                    api.getLogin(res.token).then(function (response) { // handle success
                        if (response.data) {
                            localStorage.setItem("token", res.token)
                            localStorage.setItem("expirationTime", res.claims.exp)
                            localStorage.setItem("AuthTime", res.claims.auth_time)
                            localStorage.setItem("userdata", JSON.stringify(response.data))
                            localStorage.setItem("drawer", true)
                            // if(response.data.roles[0].role_id == 10 || response.data.roles[0].role_id == 8 || response.data.roles[0].role_id == 9)
                            // {
                            // sessionStorage.setItem("program_id", '258' )
                            // sessionStorage.setItem("batch_id", '38' )
                            // sessionStorage.setItem("location_id", '30' )
                            // sessionStorage.setItem("program_name", `Women Entrepreneurship Programme` )
                            // sessionStorage.setItem("location_name", `Gondia` )
                            // sessionStorage.setItem("batch_name", `2021` )
                            // sessionStorage.setItem("program_code", `WEP` )
                            // sessionStorage.setItem("batch_code", `WEP21` )
                            // sessionStorage.setItem("location_code", `GON` )
                            // }
                            sessionStorage.setItem("user_id", response.data.id)
                            sessionStorage.setItem("user_name", response.data.user)
                            sessionStorage.setItem("location_id", response.data.roles[0].location_id)
                            sessionStorage.setItem("user_role_id", response.data.roles[0].role_id)
                            let user_role = response.data.roles[0].role_id
                            let location_id = response.data.roles[0].location_id
                            if ((user_role === 1) || (user_role === 2) || (user_role === 3)) {
                                api.getPath(location_id).then(function (resp) { // handle success
                                    sessionStorage.setItem("batch_id", resp.data[0].batch_id)
                                    sessionStorage.setItem("program_id", resp.data[0].program_id)
                                    sessionStorage.setItem("program_name", resp.data[0].program_name)
                                    sessionStorage.setItem("location_name", resp.data[0].location_name)
                                    sessionStorage.setItem("batch_name", resp.data[0].batch_name)
                                    sessionStorage.setItem("program_code", resp.data[0].program_code)
                                    sessionStorage.setItem("batch_code", resp.data[0].batch_code)
                                    sessionStorage.setItem("location_code", resp.data[0].location_code)
                                    window.location.href = "/"
                                }).catch(function (error) { // handle error
                                    console.log(error);
                                })
                            }
                            if (user_role === 4) {
                                window.location.href = "/"
                            }
                        } else {
                            setLoginError("Invalid Login Details - Contact ALC Admin")
                        }
                    }).catch(function (error) { // handle error
                        setLoginError("Invalid Login Details - Contact ALC Admin")
                        console.log(error);
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        }).catch((error) => {
            setLoginError("Invalid OTP")
            console.log(error)
        });
    }

    return (
        <Grid container component="main"
            className={
                classes.root
        }>
            <CssBaseline/>
            <Grid item
                xs={false}
                sm={4}
                md={7}
                className={
                    classes.header
            }>
                <Typography variant="h1">
                    Access
                </Typography>
                <Typography variant="h2">
                    Livelihoods
                </Typography>
            </Grid>
            <Grid item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square>
                <div className={
                    classes.paper
                }>
                    <Avatar className={
                        classes.avatar
                    }>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <TextField variant="outlined" margin="normal" required
                        className={
                            classes.textInput
                        }
                        fullWidth
                        id="email"
                        label="Phone Number"
                        name="phone"
                        type="number"
                        autoFocus
                        onChange={handlePhoneNo}
                        value={phoneNumber}
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">+91</InputAdornment>
                            }
                        }/> {
                    otp && <>
                        <TextField variant="outlined" margin="normal" required
                            className={
                                classes.textInput
                            }
                            fullWidth
                            name="password"
                            label="OTP"
                            type="number"
                            id="otp"
                            onChange={
                                (event) => {
                                    setOtpNumber(event.target.value)
                                }
                            }
                            value={otpNumber}/>
                        <Typography onClick={
                            () => {
                                handleSubmit()
                            }
                        }>resend OTP</Typography>
                    </>
                }
                    <Button fullWidth variant="contained"
                        style={
                            {
                                color: "white",
                                backgroundColor: "#f37721"
                            }
                        }
                        className={
                            classes.submit
                        }
                        onClick={
                            otp ? () => {
                                verifyOtp(otpNumber)
                            } : handleSubmit
                    }>
                        {
                        otp ? " Verify OTP" : " Get OTP"
                    } </Button>
                    {
                    loginError && <Typography variant="h4"
                        style={
                            {color: "red"}
                    }>
                        {loginError} </Typography>
                }
                    <div id="recaptcha-container"></div>
                    <Box mt={5}>
                        <Copyright/>
                    </Box>
                </div>
            </Grid>
        </Grid>
    );
}
