import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import UserWrittenTest from './UserWrittenTest';
import GroupDiscussionList from './GroupDiscussionList';
import PersonalInterviewList from './PersonalInterviewList';
import BusinessChallangeList from './BusinessChallangeList';
import './BatchName.css'; 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext'; 
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core'; 
import Navigation from '../../Nav/Nav';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: '2%',
    Width: '100%',
  },
  card: {
    minWidth: 260,
    padding: theme.spacing(1),
    cursor: 'pointer',
  }, 
  title: {
    marginTop: 0,
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  pos: {
    marginBottom: 12,
  },
}));

function BatchName() {
  const classes = useStyles(); 
  const selected =  window.location.href.split('/').pop()
  const [ selectTest, setSelectTest ] = React.useState(selected) 
   
  return (
    <Navigation main={
    (sessionStorage.getItem("location_id") && sessionStorage.getItem('program_id') && sessionStorage.getItem('batch_id'))?
    <>
    <div className = {classes.container} >
      <Grid container className={classes.root} spacing={2}>
      <Grid item xs="12" lg="12" style={{marginBottom:15}}>                                              
      <Breadcrumbs separator={
                        <NavigateNextIcon
                    fontSize="small"/>
                    }
                    aria-label="breadcrumb">
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/program">
                        {
                        sessionStorage.getItem("program_name")
                    }
                        ({
                        sessionStorage.getItem("program_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}}to="/batch">
                        {
                        sessionStorage.getItem("batch_name")
                    }
                        ({
                        sessionStorage.getItem("batch_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/location">
                        {
                        sessionStorage.getItem("location_name")
                    }
                        ({
                        sessionStorage.getItem("location_code")
                    })
                    </Link>
                    <Typography color="textPrimary">Evaluation</Typography>
                </Breadcrumbs>   
      </Grid> 
        <Grid item xs={12} lg={12}>
          <Grid container spacing={3} justify= "center"> 
          <Grid item xs={12} lg={3}>
            <Card id="editIcon" >
              <CardContent id="CardContentedit">
                <EditIcon />
              </CardContent>
            </Card>
            <Card className={classes.card} id="card" onClick={()=>{setSelectTest(1)}} >
             <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Written Test
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
           <Card id="groupIcon">
              <CardContent id="CardContentgroup">
                <GroupIcon />
              </CardContent>
            </Card>
            <Card className={classes.card} id="card" onClick={()=>{setSelectTest(2)}}>
              <CardContent>
               <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Group Discussion
               </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
           <Card id="personIcon">
              <CardContent id="CardContentperson">
                <PersonIcon />
              </CardContent>
            </Card>
            <Card className={classes.card} id="card" onClick={()=>{setSelectTest(3)}}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Personal Interview
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
          <Card id="businessIcon">
              <CardContent id="CardContentbusiness">
                <BusinessCenterIcon />
              </CardContent>
            </Card>
            <Card className={classes.card} id="card" onClick={()=>{setSelectTest(4)}}>
             <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Business Challange
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>        
      </Grid> 
    </Grid> 
     {(selectTest==1)?  <UserWrittenTest />  : null}
     {(selectTest==2)?   <GroupDiscussionList /> : null}
     {(selectTest==3) ? <PersonalInterviewList /> : null}
     {(selectTest==4) ? <BusinessChallangeList /> : null}
  </div> 
  </>
  :
  <Paper className={classes.root}>
  <h5 style={{ color:'red' }}>Location is not Selected</h5>
</Paper>
    } drawerIndex={5}/> 
  );
} 
export default BatchName