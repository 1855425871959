import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'; 
import * as api from '../../Api';
import { Link } from 'react-router-dom'; 
import {Grid, MenuItem, TextField} from '@material-ui/core'; 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Typography } from '@material-ui/core'; 
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import Navigation from '../../Nav/Nav';
import ScrollDialog from '../TotalScoreDialoge'; 
import AlertDialog from '../Alert'; 
import BackDrop from '../Backdrop'; 
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
 
const columns = [
    { id: 'no', label: 'Sl\u00a0No.', minWidth: 100 ,align: 'left', },
  { id: 'name', label: 'Candidate\u00a0Name', minWidth: 100 ,align: 'center', },
  { id: 'written', label: 'Written Test', minWidth: 170 ,align: 'center',}, 
  { id: 'group', label: 'Group Discussion', minWidth: 170 ,align: 'center',},   
  { id: 'personal', label: 'Personal Interview', minWidth: 100 ,align: 'center',},
  { id: 'business', label: 'Business Challenge', minWidth: 100 ,align: 'center',},
  { id: 'total', label: 'Total Score', minWidth: 100 ,align: 'center',},
  { id: 'status', label: 'Status', minWidth: 100 ,align: 'center',} 
];
 
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor:'#d9d9d9',
    height: '50px', 
    fontWeight: 'bold',
    fontSize: '18px',
  }
});

 function ReportList(props) { 
  const [viewNavigate,setViewNavigate] = useState(false)
  const [program, setProgram] = useState(window.location.href.split('/').pop(1))  
  if(program == "results"){
    setProgram(sessionStorage.getItem("location_id"))
    setViewNavigate(true)

  } 
  const [open,setOpen]=useState(false)
  const [scoreOpen,setScoreOpen]=useState(false)
  const [alert, setAlert]= useState(false)
  const [scoreAlert, setScoreAlert]= useState(false)
  const [selectId,setSelectId]= useState("") 
  const [selectForce , setSelectForce] = useState("")
  const localRole = sessionStorage.getItem("user_role_id")
  const [name,setName]= useState("") 
  const [list, setList] = useState([]); 
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15); 
  const [batch_id,setBatchId]= useState(null)
  const [filterValue, setFilterValue] = useState("all") 
  const passObj = {
    "force_pass":true
  }
   

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  const filterMethod = (value)=>
  {
      if(filterValue === "all")
      {
          return value.id
      }
      else
      {
          return value.is_pass === filterValue
      } 
  }

const handleRoundOff = (data) =>
{
    if(data % 1 !== 0 )
    {
        return data.toFixed(1)
    }
    return data

}


 
const handleFilter = (event) =>{
  setFilterValue(event.target.value)
}

 
 
  const DataSet=[
    {
        columns: [
            {title: "Sl.No", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true },fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 50}}, // width in pixels
            {title: "Name", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 30}}, // width in characters
            {title: "Marks obtained in Written test", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 300}}, // width in pixels
            {title: "Marks obtained in Group discussion", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 350}}, // width in pixels
            {title: "Marks obtained in Personal Interview", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 350}}, // width in pixels
            {title: "Marks obtained in Business Challenges", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 350}}, // width in pixels
            {title: "Total Score", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 15}}, // width in characters
            {title: "Status", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 110}}, // width in pixels  
        ],
        data: list.filter(filterMethod).map((row,i) => [
            {value: i+1, style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.candidate_name, style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.assessment_scores.Written? (row.assessment_scores.Written) % 1 !== 0 ? Number(row.assessment_scores.Written).toFixed(1) : row.assessment_scores.Written : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.assessment_scores.Group_Discussion ? (row.assessment_scores.Group_Discussion) % 1 !== 0 ? Number(row.assessment_scores.Group_Discussion).toFixed(1) : row.assessment_scores.Group_Discussion : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.assessment_scores.Personal_Interview ? (row.assessment_scores.Personal_Interview) % 1 !== 0 ? Number(row.assessment_scores.Personal_Interview).toFixed(1) : row.assessment_scores.Personal_Interview : "",  style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.assessment_scores.Business_Challenge? (row.assessment_scores.Business_Challenge) % 1 !== 0 ? Number(row.assessment_scores.Business_Challenge).toFixed(1) : row.assessment_scores.Business_Challenge : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.total_score !== 0 ? (row.total_score) % 1 !== 0 ? Number(row.total_score).toFixed(1) : row.total_score : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.is_pass === 1 ? "Passed" : "Failed" , style: {alignment:{horizontal: "center"},font: {sz: "14"}}}, 
        ])
    }
  ]   

   

  const getData = () =>{
    api.getReport(program)
    .then(function (response) {
      // handle success 
      setList(response.data);
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }
    useEffect(() => {
     // Make a request for a user with a given ID
    getData()
    
   }, []);
 
  useEffect(()=>{
    setBatchId(sessionStorage.getItem("batch_id"))
   },[])
   

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handelPass = async() => {
    setScoreAlert(false)
    setScoreOpen(true)
    await api.setCandidate(selectForce,passObj)
   .then(response => {
     if(response.data){ 
      setSelectForce("")
      setName("") 
      getData()
     } 
 }).catch(error => {
   console.error(error) 
 })
 setScoreOpen(false) 
 }
   
  return (
    <Navigation main={
    (program) ?
    <>  
                <BackDrop open={scoreOpen} handleClose={()=>{setScoreOpen(false)}}/>
    <AlertDialog open={scoreAlert} close={()=>{setScoreAlert(false)}} delete={handelPass} message={`Are you sure you want to PASS the candidate ${name} ?`}/>
     <ScrollDialog open={ alert } close={()=>{setAlert(false);setSelectId("");setName("")}}   selected={selectId} name={name}/>
    <Grid container  direction="row"  justify="flex-end" alignItems="center" style={{ marginBottom: '2%'}}>  
    <Grid item xs="12" lg="8">
        {viewNavigate ? 
    <Breadcrumbs separator={
                        <NavigateNextIcon
                    fontSize="small"/>
                    }
                    aria-label="breadcrumb">
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/program">
                        {
                        sessionStorage.getItem("program_name")
                    }
                        ({
                        sessionStorage.getItem("program_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}}to="/batch">
                        {
                        sessionStorage.getItem("batch_name")
                    }
                        ({
                        sessionStorage.getItem("batch_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/location">
                        {
                        sessionStorage.getItem("location_name")
                    }
                        ({
                        sessionStorage.getItem("location_code")
                    })
                    </Link>
                    <Typography color="textPrimary">Result Report</Typography>
                </Breadcrumbs> :   <Typography variant="h4" align="left">
            Result Report
          </Typography> }
      </Grid>
      <Grid item container alignItems="center" xs="12" lg="4">
          <Grid item xs="6" lg="6">
<TextField fullWidth value={filterValue} onChange={handleFilter} variant="outlined" margin="dense" label ="Filter" select> 
    <MenuItem value="all">All</MenuItem>
    <MenuItem value={1}>Passed</MenuItem>
    <MenuItem value={0}>Failed</MenuItem>
</TextField>
</Grid>
<Grid item xs="6" lg="6">
<ExcelFile 
    filename={`ResultReport${new Date().toLocaleString()}`} 
    element={
    <Button style={{ color: "white" ,backgroundColor: "#f37721"  }}>Export
      </Button>
    }>
        <ExcelSheet dataSet={DataSet} name={`${new Date().toLocaleString()}`}/>
    </ExcelFile>
</Grid>
      </Grid> 
         </Grid>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            {(localRole === '4' ) && <TableCell className={classes.head} align="center" style={{ minWidth: "100px", fontWeight: 'bold' }}/>}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length>0 ? list.filter(filterMethod).map((row,index) => {
              return (   
                 <TableRow hover    tabIndex={-1} key={row.id}  > 
                  <TableCell align="left">{index+1}</TableCell> 
                <TableCell align="center">
                  {
                (localRole === '4' ) ? <Typography style={{color:"#f37721",cursor:"pointer"}} onClick={()=>{setAlert(true);setSelectId(row.id);setName(row.candidate_name)}} >
                {row.candidate_name}
                  </Typography> 
                :  row.candidate_name
                } 
                   </TableCell>  
                  <TableCell align="center">
                    {row.assessment_scores.Written ? 
                            (row.assessment_scores.Written) % 1 !== 0 ? 
                                Number(row.assessment_scores.Written).toFixed(1) 
                            : row.assessment_scores.Written
                            : "-"
                        }
                  </TableCell>
                  <TableCell align="center">
                    {row.assessment_scores.Group_Discussion ? 
                            (row.assessment_scores.Group_Discussion) % 1 !== 0 ? 
                                Number(row.assessment_scores.Group_Discussion).toFixed(1) 
                            : row.assessment_scores.Group_Discussion 
                            : "-"
                        }
                  </TableCell>
                  <TableCell align="center">
                    {row.assessment_scores.Personal_Interview ? 
                            (row.assessment_scores.Personal_Interview) % 1 !== 0 ? 
                                Number(row.assessment_scores.Personal_Interview).toFixed(1) 
                            : row.assessment_scores.Personal_Interview 
                            : "-"
                        }
                  </TableCell> 
                  <TableCell align="center"> 
                    {row.assessment_scores.Business_Challenge? 
                        (row.assessment_scores.Business_Challenge) % 1 !== 0 ? 
                            Number(row.assessment_scores.Business_Challenge).toFixed(1) 
                        : row.assessment_scores.Business_Challenge 
                        : "-"
                    }
                 </TableCell>
                 <TableCell align="center"> 
                    {row.total_score !== 0 ? 
                            (row.total_score) % 1 !== 0 ? 
                                Number(row.total_score).toFixed(1) 
                            : row.total_score 
                        : "-"
                        } 
                 </TableCell> 
                 {row.is_pass ? 
              <TableCell align="center"> 
              <Chip
        variant="outlined"
        size="small"
        label="PASSED" 
        style={{color:"green",borderColor:"green"}} 
        onDelete
        deleteIcon={<DoneIcon style={{color:"green"}}/>}
      />
               </TableCell>
              : 
              <TableCell align="center">
                <Chip
        variant="outlined"
        size="small"
        label="FAILED" 
        style={{color:"red",borderColor:"red"}} 
        onDelete 
        deleteIcon={<CancelIcon style={{color:"red"}}/>}
      />
              </TableCell>
              } 
             { (localRole === '4' ) &&  
              <TableCell>
              {row.is_pass !== 1 && 
              <Button onClick={()=>{setScoreAlert(true);setSelectForce(row.id);setName(row.candidate_name)}} style={{ color: "white" ,backgroundColor: "#f37721"  }}>Force&nbsp;Pass</Button>
              }
              </TableCell>
            }
                </TableRow>              
              );
            }): <TableCell colSpan="8" align="center" style={{ color:'red' }}>  <h5>No Records Found</h5> </TableCell>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </>
    :
     <Paper className={classes.root}>
       <h5 style={{ color:'red' }}>Location is not Selected</h5>
     </Paper> }drawerIndex={7}/>
  );
}
export default ReportList
