import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow'; 
import { useHistory , Link } from 'react-router-dom'; 
import {Grid} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Typography } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { Checkbox } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Navigation from '../../Nav/Nav'; 
import * as api from '../../Api';
import BackDrop from '../Backdrop';

const columns = [
  { id: 'name', label: 'Code', minWidth: 100 ,align: 'left', },
  { id: 'code', label: 'Name', minWidth: 170 ,align: 'left',}, 
  { id: 'code', label: 'Description', minWidth: 170 ,align: 'left',},   
  { id: 'code', label: 'Status', minWidth: 100 ,align: 'center',}
];
 
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  }, 
  head: {
    backgroundColor:'#d9d9d9',
    height: '50px', 
  },
  selected: { 
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#fef0e7"}
  }
});

 function LocationTable() {
  const localRole = sessionStorage.getItem("user_role_id")
  const program = sessionStorage.getItem("batch_id")
  const [list, setList] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [open , setOpen ] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const [location_id,setLocationId]= useState(null)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  const handleLocationList = async(program) =>{
    setOpen(true)
    await api.getLocations(program) 
    .then(function (response) {
      // handle success
       setList(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    setOpen(false)
  }

    useEffect(() => {
     // Make a request for a user with a given ID
    handleLocationList(program)
    setLocationId(sessionStorage.getItem("location_id"))
   }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const locationSelected = (id,location_name,location_code) =>
  {
    sessionStorage.setItem("location_id", id);
   sessionStorage.setItem("location_name", location_name);
   sessionStorage.setItem("location_code", location_code);
    setLocationId(id) 
  } 
   
  return (
    <Navigation main={
    (program) ?
    <>
     <BackDrop open={open} handleClose={()=>{setOpen(false)}}/>
    <Grid container  direction="row"  justify="flex-end" alignItems="center" style={{ marginBottom: '2%'}}> 
    <Grid item xs="6" lg="10">
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link style={{color:"#f37721",textDecoration:"none"}} to="/program"  >
          {sessionStorage.getItem("program_name")}
          ({sessionStorage.getItem("program_code")})
        </Link> 
        <Link style={{color:"#f37721",textDecoration:"none"}} to="/batch"  >
          {sessionStorage.getItem("batch_name")}
          ({sessionStorage.getItem("batch_code")})
        </Link> 
         <Typography color="textPrimary">Locations
         <span style={{align:"left",color:"green",fontWeight:"bold"}}>{sessionStorage.getItem("location_name") ? ` - ${sessionStorage.getItem("location_name")}`:null}</span> 
         </Typography>
      </Breadcrumbs>   
      </Grid>
      <Grid item xs="6" lg="2"> 
      {(localRole === '4' ) &&
            <Fab variant="extended" color="primary" aria-label="add" style={{color:"#f37721",backgroundColor:"white",fontWeight:"bold"}} onClick={()=>{history.push({pathname: `/locationadd`, query: {location:program}})}}>
               <AddIcon className={classes.selected} />  
                   Add Location
           </Fab> }
           </Grid>  
         </Grid>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}/>
              {columns.map((column) => (
                <TableCell className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold' ,fontSize: '18px'}}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell style={{width:'20px'}} className={classes.head}></TableCell>
              {(localRole === '4' ) &&<TableCell style={{width:'20px'}} className={classes.head}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length>0 ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (   
                 <TableRow hover className={classes.selected} onClick={()=>{ locationSelected(row.id,row.location_name,row.location_code)}}   tabIndex={-1} key={row.id} selected={location_id == row.id}>  
                 <Checkbox style={{color:'#f37721'}}
                    checked={location_id == row.id}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                /> 
                <TableCell align="left">{row.location_code}</TableCell>  
                  <TableCell align="left"> {row.location_name} </TableCell>   
                   <TableCell align="left"> {row.description} </TableCell> 
                  {row.isactive ? 
              <TableCell align="center"> 
              <Chip
        variant="outlined"
        size="small"
        label="Active" 
        style={{color:"green",borderColor:"green"}} 
        onDelete
        deleteIcon={<DoneIcon style={{color:"green"}}/>}
      />
               </TableCell>
              : 
              <TableCell align="center">
                <Chip
        variant="outlined"
        size="small"
        label="Inactive" 
        style={{color:"red",borderColor:"red"}} 
        onDelete 
        deleteIcon={<CancelIcon style={{color:"red"}}/>}
      />
              </TableCell>
              }   
                  <TableCell> 
                    <Fab style={{color:"#f37721",backgroundColor:"white"}} size="small"  onClick={()=>{history.push({pathname: `/locationview/${row.id}`, query: {location:row}})}}>
                      <VisibilityIcon/>
                    </Fab>
                    </TableCell>
                    {(localRole === '4' ) &&
                    <TableCell>
                    <Fab style={{color:"black",backgroundColor:"white"}} size="small"  onClick={()=>{history.push({pathname: `/locationedit/${row.id}`, query: {location:row}})}}>
                      <EditIcon />
                    </Fab> 
                  </TableCell>
            }
                </TableRow>              
              );
            }):<TableCell colSpan="5" align="center" style={{ color:'red' }}> <h5> No Locations Found </h5>   </TableCell>}
            </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
     </>
    :
     <Paper className={classes.root}>
     <h5 style={{ color:'red' }}>Batch is not Selected</h5>
   </Paper>}
    drawerIndex={3}
   />
  );
}
export default LocationTable
