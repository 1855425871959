import React from 'react';
import { useState } from 'react';
import { db } from '../../firebase';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Select } from '@material-ui/core';
import { Redirect } from 'react-router';
import Navigation from '../../Nav/Nav';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    cardroot: {
        minWidth: 275
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    }
}));

export default function CandidateEdit(props) {
    const candidateQuery = props.location.query;
    const candidate = candidateQuery.candidate;
    const classes = useStyles();
    const history = useHistory();
    let categoryProps = candidate.category
    let educationProps = candidate.education
    if (categoryProps === undefined) {
        categoryProps = ""
    }
    if (educationProps === undefined) {
        educationProps = ""
    }
    const [name, setName] = useState(candidate.candidate_name);
    const [phone, setPhone] = useState(candidate.phone_number);
    const [gender, setGender] = useState(candidate.gender);
    const [dateOfBirth, setDateOfBirth] = useState(candidate.dob);
    const [govermentId, setGovermentId] = useState(candidate.government_id);
    const [category, setCategory] = useState(categoryProps);
    const [education, setEducation] = useState(educationProps);
    const [location, setLocation] = useState(sessionStorage.getItem("location_id"));
    const [villageLocation, setVillageLocation] = useState(candidate.village_location);
    const [loader, setLoader] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        const contact = {
            candidate_name: name,
            phone_number: phone,
            gender: gender,
            dob: dateOfBirth,
            government_id: govermentId,
            location_id: location,
            village_location: villageLocation,
            category: category,
            education: education,
        }
        db.collection("candidate").doc(candidate.id).update(contact).then(() => {
            setLoader(false); <Redirect to="/candidate" />
        }).catch((error) => {
            alert(error.message);
            setLoader(false);
        });
        setName("");
        setPhone("");
        setDateOfBirth("");
        setGender("");
        setGovermentId("");
        setLocation(sessionStorage.getItem("program_id"));
        setVillageLocation("");
    };

    return (
        <Navigation main={
            <div className={
                classes.root
            }>
                <main className={
                    classes.content
                }>
                    <Grid container direction="row" justify="flex-end" alignItems="center"
                        style={
                            { marginBottom: '2%' }
                        }>
                        {/* <Fab variant="extended" color="primary" aria-label="add" component={Link} to="/candidatescan">
               <AddIcon className={classes.extendedIcon} />  
                   Scan Aadhar
           </Fab>  */} </Grid>
                    <Grid container justify="center" alignItems="center">
                        <Grid item
                            xs={12}
                            lg={6}>
                            <form className="form"
                                onSubmit={handleSubmit}>
                                <Card className={
                                    classes.classroot
                                }>
                                    <CardContent>
                                        <Typography className={
                                            classes.title
                                        }
                                            variant="h5"
                                            style={
                                                { color: "black" }
                                            }
                                            color="textSecondary"
                                            gutterBottom>
                                            Edit Candidate Registration Details
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            <TableContainer component={Paper}>
                                                <Table className={
                                                    classes.table
                                                }
                                                    aria-label="spanning table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Candidate Name<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"><TextField fullWidth
                                                                value={name}
                                                                onChange={
                                                                    (e) => setName(e.target.value)
                                                                }
                                                                lable="Candidate Name" />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Candidate Phone<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"><TextField fullWidth
                                                                value={phone}
                                                                onChange={
                                                                    (e) => {
                                                                        if (e.target.value.length <= 10) {
                                                                            setPhone(e.target.value)
                                                                        }
                                                                    }
                                                                }
                                                                lable="Candidate Phone" />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Government ID.Proof</TableCell>
                                                            <TableCell align="left"><TextField fullWidth
                                                                value={govermentId}
                                                                onChange={
                                                                    (e) => {
                                                                        if (e.target.value.length <= 12) { setGovermentId(e.target.value) }
                                                                    }
                                                                }
                                                                lable="Government ID.proof" />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Village Address<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"><TextField fullWidth
                                                                value={villageLocation}
                                                                onChange={
                                                                    (e) => setVillageLocation(e.target.value)
                                                                }
                                                                lable="Village Address" />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Date Of Birth<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"><TextField fullWidth type='date'
                                                                value={dateOfBirth}
                                                                onChange={
                                                                    (e) => setDateOfBirth(e.target.value)
                                                                } />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Gender<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Select native fullWidth
                                                                    value={gender}
                                                                    onChange={
                                                                        (e) => setGender(e.target.value)
                                                                    }>
                                                                    <option selected value=''>Select Gender</option>
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                    <option value="Other">Other</option>
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Education<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"><TextField fullWidth
                                                                value={education}
                                                                onChange={
                                                                    (e) => setEducation(e.target.value)
                                                                }
                                                                lable="Education" />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Category<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Select native fullWidth
                                                                    value={category}
                                                                    onChange={
                                                                        (e) => setCategory(e.target.value)
                                                                    }>
                                                                    <option selected value=''>Select Category</option>
                                                                    <option value="Entrepreneur">Entrepreneur</option>
                                                                    <option value="Intrapreneur">Intrapreneur</option>
                                                                    <option value="Aspiring Entrepreneur">Aspiring Entrepreneur</option>
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Typography>
                                    </CardContent>
                                    <Button margin="normal" variant="contained" type="submit"
                                        style={
                                            {
                                                color: "white",
                                                backgroundColor: "#f37721"
                                            }
                                        }
                                        color='default'>Update</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button margin="normal" variant="contained"
                                        style={
                                            { backgroundColor: "#d9d9d9" }
                                        }
                                        href="/candidate"
                                        color='default'>Cancel</Button>
                                    <CardActions></CardActions>
                                </Card>
                            </form>
                        </Grid>
                    </Grid>
                </main>
                <div> {
                    loader ? <Redirect to={history.push("/candidate")} /> : null
                } </div>
            </div>} drawerIndex={4} />
    );
}

