import React from 'react';
import { useState,useEffect } from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Select } from '@material-ui/core';
import { Redirect } from 'react-router';
import Navigation from '../../Nav/Nav';
import { useHistory } from 'react-router-dom';  
import * as api from '../../Api'; 
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIdFromRowElem } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    cardroot: {
        minWidth: 275
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    }
}));

export default function PolicyAdd() {
    const classes = useStyles();
    const history = useHistory();
    const [Open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [PolicyId, setPolicyId] = useState(1);
    const [PolicyData, setPolicyData] = useState({
        active : "true",
        effective_date : "",
        name: ""
    })
    const [AnnualListFirstTier, setAnnualListFirstTier] = useState([]);  
    const [AnnualListSecondTier, setAnnualListSecondTier] = useState([]);
    const [DependentList, setDependentList] = useState([]);
    const [LivestockList, setLivestockList] = useState([]);
    const [VehicleList, setVehicleList] = useState([]);
    const [InfraList, setInfraList] = useState([]);
    const [LandList, setLandList] = useState([]);
    const [IncomeEarnList, setIncomeEarnList] = useState([]);
    const [IncomeSourceList, setIncomeSourceList] = useState([]);
    const [AgrIncomeList, setAgrIncomeList] = useState([]); 
  
    const getId = async() =>{
       await api.getPolicyList()
         .then(function (response) {
          // handle success
          if(response.data.length){   
         setPolicyId(response.data.length+5)  
          } 
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    }

    useEffect(() => { 
        getId()
    }, []);

    const handleInputChange = (e,StateName) => {
        const { name, value } = e.target; 
        if(StateName == "AnnualListFirstTier")
        {
            setAnnualListFirstTier({
                ...AnnualListFirstTier,
                [name]: value,
            });
        }
        if(StateName == "AnnualListSecondTier")
        {
            setAnnualListSecondTier({
                ...AnnualListSecondTier,
                [name]: value,
            });
        }
        if(StateName == "DependentList")
        {
            setDependentList({
                ...DependentList,
                [name]: value,
            });
        }
        if(StateName == "LivestockList")
        {
            setLivestockList({
                ...LivestockList,
                [name]: value,
            });
        }
        if(StateName == "VehicleList")
        {
            setVehicleList({
                ...VehicleList,
                [name]: value,
            });
        }
        if(StateName == "InfraList")
        {
            setInfraList({
                ...InfraList,
                [name]: value,
            });
        }
        if(StateName == "LandList")
        {
            setLandList({
                ...LandList,
                [name]: value,
            });
        }
        if(StateName == "IncomeEarnList")
        {
            setIncomeEarnList({
                ...IncomeEarnList,
                [name]: value,
            });
        }
        if(StateName == "IncomeSourceList")
        {
            setIncomeSourceList({
                ...IncomeSourceList,
                [name]: value,
            });
        }
        if(StateName == "AgrIncomeList")
        {
            setAgrIncomeList({
                ...AgrIncomeList,
                [name]: value,
            });
        }
      };

    const handleEntry = (array,StateName) =>{
        if(StateName == "AnnualListFirstTier" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    income: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={
                location_tier_id:2, 
                policy_detail_id: PolicyId,
                annual_income_scores_attributes:temp
            } 
            api.addAnnualIncomes(out)
                             .then(response => {
                                handleEntry(AnnualListSecondTier,"AnnualListSecondTier")
                            })
                            .catch(error => {
                                console.error(error)
                                toast.error('Error in Connection!', {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            });
        }
        if(StateName == "AnnualListSecondTier" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    income: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={
                location_tier_id:3, 
                policy_detail_id: PolicyId,
                annual_income_scores_attributes:temp
            }  
            api.addAnnualIncomes(out)
                             .then(response => {
                                handleEntry(DependentList,"DependentList")
                            })
                            .catch(error => {
                                console.error(error)
                                toast.error('Error in Connection!', {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            });
        } 
        if(StateName == "DependentList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    no_of_dependents: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                dependent_scores_attributes:temp
            } 
            api.addDependents(out)
                             .then(response => {
                                handleEntry(LivestockList,"LivestockList")
                            })
                            .catch(error => {
                                console.error(error)
                                toast.error('Error in Connection!', {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            });
        } 
        if(StateName == "LivestockList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    no_of_livestock: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                livestock_scores_attributes:temp
            } 
            api.addLivestocks(out)
                             .then(response => {
                                handleEntry(VehicleList,"VehicleList")
                            })
                            .catch(error => {
                                console.error(error)
                                toast.error('Error in Connection!', {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            }); 
        }
        if(StateName == "VehicleList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    vehicles_owned: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                vehicle_scores_attributes:temp
            } 
            api.addVehicles(out)
                             .then(response => {
                                handleEntry(InfraList,"InfraList")
                            })
                            .catch(error => {
                                console.error(error)
                                toast.error('Error in Connection!', {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            }); 
        }
        if(StateName == "InfraList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    specification: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                infrastructure_scores_attributes:temp
            } 
            api.addHouseInfrastructures(out)
                             .then(response => {
                                handleEntry(LandList,"LandList")
                            })
                            .catch(error => {
                                console.error(error)
                                toast.error('Error in Connection!', {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            });
        }
        if(StateName == "LandList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    no_of_acres: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                land_scores_attributes:temp
            } 
            api.addLandHoldings(out)
                             .then(response => {
                                handleEntry(IncomeEarnList,"IncomeEarnList")
                            })
                            .catch(error => {
                                console.error(error)
                                toast.error('Error in Connection!', {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            }); 
        }
        if(StateName == "IncomeEarnList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    no_of_earning_members: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                earning_scores_attributes:temp
            } 
            api.addEarningMembers(out)
            .then(response => {
               handleEntry(IncomeSourceList,"IncomeSourceList")
            })
            .catch(error => {
                console.error(error)
                toast.error('Error in Connection!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            });  
        }
        if(StateName == "IncomeSourceList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    source: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                income_scores_attributes:temp
            } 
            api.addIncome_Sources(out)
            .then(response => {
               handleEntry(AgrIncomeList,"AgrIncomeList")
            })
            .catch(error => {
                console.error(error)
                toast.error('Error in Connection!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }); 
        }
        if(StateName == "AgrIncomeList" )
        {
            let i; 
            let temp = [];
              for(i=0;i < Object.keys(array).length;i++)
            {
                temp.push( 
                    {
                    yield: Object.values(array)[i],
                    score:Object.keys(array)[i]
                    }
                    ) 
            }
      
            let out ={  
                policy_detail_id: PolicyId,
                agricultural_scores_attributes:temp
            } 
            api.addAgriculturalIncomes(out)
            .then(response => { 
                toast.success('Policy Successfully Created!', {
                                     position: "bottom-center",
                                     autoClose: 3000,
                                     onClose: () => {  
                                        setLoader(true)} ,
                                     hideProgressBar: false,
                                     closeOnClick: true,
                                 }); 
                                 setOpen(false);
            })
            .catch(error => {
                console.error(error)
                toast.error('Error in Connection!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }); 

        }
     } 
     
    const handleSubmit = (e) => { 
        e.preventDefault();   
        setOpen(true);  
        api.addPolicyDetails(PolicyData)
        .then(response => {
                         if(response.data.id)
                         {
                            handleEntry(AnnualListFirstTier,"AnnualListFirstTier")   
                         }
                        });  
    }

    const rowGenerate = (heading,array,StateName) => {
        return(
            <TableRow>
            <TableCell align="left"  style={
            { fontWeight: 'bold' }
        }> {heading} </TableCell>
        <TableCell>
        <TextField 
            variant="outlined"
            margin="dense"
            value={array.one}
            onChange={(e)=>{handleInputChange(e,StateName)}}
            name="0" 
            id="one"  />
        </TableCell>
        <TableCell>
        <TextField 
             variant="outlined"
             margin="dense"
             value={array.two}
             onChange={(e)=>{handleInputChange(e,StateName)}}
             name="0.25" 
             id="two"  />
        </TableCell>
        <TableCell>
        <TextField 
            variant="outlined"
            margin="dense"
            value={array.three}
            onChange={(e)=>{handleInputChange(e,StateName)}}
            name="0.5"  
            id="three"/>
        </TableCell>
        <TableCell>
        <TextField 
           variant="outlined"
           margin="dense"
           value={array.four}
           onChange={(e)=>{handleInputChange(e,StateName)}}
           name="0.75" 
           id="four" />
        </TableCell>
        <TableCell>
        <TextField 
            variant="outlined"
            margin="dense"
            value={array.five}
            onChange={(e)=>{handleInputChange(e,StateName)}}
            name="1" 
            id="five"/>
        </TableCell>
        </TableRow> 
        )
    } 

    return (
        <Navigation main={
            <div className={
                classes.root
            }>
                <main className={
                    classes.content
                }>
                    <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <BackDrop open={Open} handleClose={() => { setOpen(false) }} />
                    <Grid container direction="row" justify="flex-end" alignItems="center"
                        style={
                            { marginBottom: '2%' }
                        }> </Grid>
                    <Grid container justify="center" alignItems="center">
                        <Grid item
                            xs={12}
                            lg={12}>
                            <form className="form"
                                 onSubmit={(e)=>{handleSubmit(e)}}
                                >
                                <Card className={
                                    classes.classroot
                                }>
                                    <CardContent>
                                        <Typography className={
                                            classes.title
                                        }
                                            variant="h5"
                                            style={
                                                { color: "black" }
                                            }
                                            color="textSecondary"
                                            gutterBottom>
                                            Scholarship Policy Details
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            <TableContainer component={Paper}>
                                                <Table className={
                                                    classes.table
                                                }
                                                    aria-label="spanning table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan="4"></TableCell>
                                                            <TableCell align="right" style={
                                                                { fontWeight: 'bold' }
                                                            }>Name<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell width="300px"  align="left"><TextField 
                                                                value={PolicyData.name}
                                                                fullWidth
                                                                required
                                                                onChange={
                                                                    (e) =>  {
                                                                        setPolicyData(prev => ({
                                                                            ...prev,
                                                                            name: e.target.value
                                                                        }))
                                                                    }
                                                                }
                                                                lable="Policy Name" />
                                                            </TableCell>
                                                        </TableRow>  
                                                        <TableRow> 
                                                        <TableCell colSpan="4"></TableCell>
                                                            <TableCell align="right" style={
                                                                { fontWeight: 'bold' }
                                                            }>Effective Date<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"><TextField  type='date' 
                                                                value={PolicyData.effective_date}
                                                                fullWidth
                                                                required
                                                                onChange={
                                                                    (e) =>  {
                                                                        setPolicyData(prev => ({
                                                                            ...prev,
                                                                            effective_date: e.target.value
                                                                        }))
                                                                    }
                                                                } />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow> 
                                                        <TableCell colSpan="4"></TableCell>   
                                                            <TableCell align="right" style={
                                                                { fontWeight: 'bold' }
                                                            }>Status<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left" >
                                                                <Select native 
                                                                    value={PolicyData.active}
                                                                    fullWidth
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setPolicyData(prev => ({
                                                                                ...prev,
                                                                                active: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                   <option selected value="true">Active</option>
                                                                <option value="false">Inactive</option></Select>
                                                            </TableCell>
                                                        </TableRow>
                                                       
                                                        <TableRow> 
                                                            <TableCell colSpan="6">
                                                            <Table>
                                                            <TableBody>
                                                            <TableRow style={{backgroundColor:"#EEEDE7"}}>
                                                                     <TableCell align="center"style={
                                                                { fontWeight: 'bold' }
                                                            }> Scores</TableCell>
                                                                     <TableCell align="center" style={{color:"#f37721",fontWeight:'bold',fontSize:'20px'}}>0</TableCell>
                                                                     <TableCell align="center" style={{color:"#f37721",fontWeight:'bold',fontSize:'20px'}}>0.25</TableCell>
                                                                     <TableCell align="center" style={{color:"#f37721",fontWeight:'bold',fontSize:'20px'}}>0.5</TableCell>
                                                                     <TableCell align="center" style={{color:"#f37721",fontWeight:'bold',fontSize:'20px'}}>0.75</TableCell>
                                                                     <TableCell align="center" style={{color:"#f37721",fontWeight:'bold',fontSize:'20px'}}>1</TableCell>
                                                                </TableRow>
                                                     
                                                                {rowGenerate("Income Location Tier 2", AnnualListFirstTier,"AnnualListFirstTier")}
                                                                {rowGenerate("Income Location Tier 3", AnnualListSecondTier,"AnnualListSecondTier")}
                                                                {rowGenerate("No of dependency", DependentList,"DependentList")}
                                                                {rowGenerate("Cattle/ large livestock", LivestockList,"LivestockList")}
                                                                {rowGenerate("Owns vehicle", VehicleList,"VehicleList")}
                                                                {rowGenerate("Infrastructure", InfraList,"InfraList")}
                                                                {rowGenerate("Landholdings", LandList,"LandList")}
                                                                {rowGenerate("No. of Income Earning Members", IncomeEarnList,"IncomeEarnList")}
                                                                {rowGenerate("Income Sources", IncomeSourceList,"IncomeSourceList")}
                                                                {rowGenerate("Agricultural Income", AgrIncomeList,"AgrIncomeList")}
                                                                 
                                                            </TableBody>
                                                            </Table>
                                                            </TableCell>
                                                            </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Typography>
                                    </CardContent>
                                    <Button margin="normal" variant="contained" 
                                    type="submit"
                                        style={
                                            {
                                                color: "white",
                                                backgroundColor: "#f37721"
                                            }
                                        }
                                        color='default'>Submit</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button margin="normal" variant="contained"
                                        style={
                                            { backgroundColor: "#d9d9d9" }
                                        }
                                        href="/scholarship"
                                        color='default'>Cancel</Button>
                                    <CardActions></CardActions>
                                </Card>
                            </form>
                        </Grid>
                    </Grid>
                </main>
                <div> {
                    loader ? <Redirect to={history.push("/scholarship")} /> : null
                } </div>
            </div>} drawerIndex={11} />
    );
}

