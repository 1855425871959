import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow'; 
import {Grid, Typography} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fab from '@material-ui/core/Fab';
import { Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom'; 
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import BackDrop from '../Backdrop';
import Navigation from '../../Nav/Nav';
import * as api from '../../Api';

const columns = [
  { id: 'name', label: 'Code', minWidth: 100 ,align: 'left', },
  { id: 'code', label: 'Name', minWidth: 170 ,align: 'left',}, 
  { id: 'code', label: 'Funder', minWidth: 100 ,align: 'left', },
  { id: 'code', label: 'Status', minWidth: 100 ,align: 'center',}
];
 
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor:'#d9d9d9',
    height: '50px', 
  },
  selected: { 
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#fef0e7"}
  }
});

 function ProgramTable(props) { 
  const [list, setList] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const localRole = sessionStorage.getItem("user_role_id") 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const [open, setOpen] = useState(false);
  const [program_id,setProgramId]= useState(null) 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  const handleProgramList = async() => {
    setOpen(true)
     // Make a request for a user with a given ID
     await api.getPrograms()
    .then(function (response) {
      // handle success 
      setList(response.data); 
    })
    .catch(function (error) {
      // handle error
      console.log(error);
       
    }) 
    setOpen(false)
  }

  useEffect(() => {
      handleProgramList()
   }, []);
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
 
  const programSelected = (id,program_name,program_code)=>
  { 
    sessionStorage.setItem("program_id", id);
    sessionStorage.setItem("program_name", program_name);
    sessionStorage.setItem("program_code", program_code);
    sessionStorage.setItem("batch_id","");
    sessionStorage.setItem("batch_name","");
    sessionStorage.setItem("batch_code","");
    sessionStorage.setItem("location_id","");
    sessionStorage.setItem("location_name","");
    sessionStorage.setItem("location_code","");
    setProgramId(id) 
  } 

  useEffect(()=>{
    setProgramId(sessionStorage.getItem("program_id"))
   },[])
   
  return (
    <Navigation main={
    <>
    <BackDrop open={open} handleClose={()=>{setOpen(false)}}/>
     <Grid container  direction="row"  justify="flex-end" alignItems="center" style={{ marginBottom: '2%'}}>                                              
     <Grid item xs="4" lg="2">
     <Typography variant="h4" align="left">
            Programs 
          </Typography>  
          </Grid>
          <Grid item xs="4" lg="8">
     <Typography variant="h8" style={{align:"left",color:"green",fontWeight:"bold"}}>
     {sessionStorage.getItem("program_name")? `
      Selected: ${sessionStorage.getItem("program_name")}(${sessionStorage.getItem("program_code")})`:null}
          </Typography>  
       </Grid>
     <Grid item xs="4" lg="2">
     {(localRole === '4' ) &&
           <Fab variant="extended" style={{color:"#f37721",backgroundColor:"white",fontWeight:"bold"}} aria-label="add" onClick={()=>{history.push("/programadd")}}>
               <AddIcon className={classes.selected} />  
                   Add Program
           </Fab>
    }
           </Grid>
         </Grid>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead >
            <TableRow >
            <TableCell className={classes.head}></TableCell>
              {columns.map((column) => (
                <TableCell className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold',fontSize: '18px' }}
                >
                  {column.label}
                </TableCell> 
              ))} 
              <TableCell style={{width:'20px'}} className={classes.head}></TableCell>
              {(localRole == '4' ) && <TableCell style={{width:'20px'}} className={classes.head}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length>0 ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { 
              return (        
                <TableRow hover className={classes.selected} onClick={()=>{ programSelected(row.id,row.program_name,row.program_code)}}   tabIndex={-1} key={row.id} selected={program_id == row.id}>  
                
                <Checkbox style={{color:'#f37721'}}
                    checked={program_id == row.id}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                /> 
                  <TableCell align="left">{row.program_code}</TableCell> 
                  <TableCell align="left"> {row.program_name} 
                   </TableCell> 
                  <TableCell align="left">{row.funder}</TableCell> 
                  {row.isactive ? 
              <TableCell align="center"> 
              <Chip
        variant="outlined"
        size="small"
        label="Active" 
        style={{color:"green",borderColor:"green"}} 
        onDelete
        deleteIcon={<DoneIcon style={{color:"green"}}/>}
      />
               </TableCell>
              : 
              <TableCell align="center">
                <Chip
        variant="outlined"
        size="small"
        label="Inactive" 
        style={{color:"red",borderColor:"red"}} 
        onDelete 
        deleteIcon={<CancelIcon style={{color:"red"}}/>}
      />
              </TableCell>
              }    
                  <TableCell>   
                    <Fab style={{color:"#f37721",backgroundColor:"white"}} size="small" aria-label="edit" onClick={()=>{history.push({pathname: `/programview/${row.id}`, query: {program:row}})}}>
                      <VisibilityIcon/>
                    </Fab></TableCell>
                    {(localRole == '4' ) &&
                    <TableCell>
                    <Fab style={{color:"black",backgroundColor:"white"}} size="small" aria-label="edit" onClick={()=>{history.push({pathname: `/programedit/${row.id}`, query: {program:row}})}}>
                      <EditIcon />
                    </Fab>
                  </TableCell>}
                </TableRow>              
              );
            }): <TableCell colSpan="5" align="center" style={{ color:'red' }}> <h5>No Programs Found</h5></TableCell>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper> 
    </>}
    drawerIndex={1}
    />
  );
}
export default ProgramTable
