import React, { useEffect } from 'react';
import { makeStyles , withStyles } from '@material-ui/core/styles'; 
import { Switch } from '@material-ui/core'; 
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { Link, useHistory } from 'react-router-dom'; 
import { useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Typography } from '@material-ui/core';
import BackDrop from '../Backdrop';
import Navigation from '../../Nav/Nav';
import EditIcon from '@material-ui/icons/Edit';
import { InputAdornment } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AssessmentIcon from '@material-ui/icons/Assessment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as api from '../../Api';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import * as moment from 'moment';

const columnsScholarship = [
    {
        id: 'no',
        label: 'SlNo.',
        minWidth: 5,
        align: 'center'
    },
    {
        id: 'name',
        label: 'Name',
        minWidth: 100,
        align: 'center'
    },
    {
        id: 'date',
        label: 'Effective\u00a0Date',
        minWidth: 20,
        align: 'center'
    }, 
    {
        id: 'status',
        label: 'Status',
        minWidth: 20,
        align: 'center'
    },
];

const columnsCandidate = [
    {
        id: 'no',
        label: 'SlNo.',
        minWidth: 5,
        align: 'center'
    },
    {
        id: 'name',
        label: 'Name',
        minWidth: 300,
        align: 'center'
    }, 
    {
        id: 'score',
        label: 'Score',
        minWidth: 20,
        align: 'center'
    }, 

];

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        maxHeight: 540,
    },
    head: {
        backgroundColor: '#d9d9d9',
        height: '50px',
        fontWeight: 'bold',
        fontSize: '18px'
    }
});

function ScholarshipTable() {
    const [search, setSearch] = useState("");
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState([]);
    const [Candidatelist, setListCandidate] = useState([]);
    const [open, setOpen] = useState(false);
    const location_id = sessionStorage.getItem("location_id");

    const getPolicyList = async() => {
        setOpen(true)
        await api.getPolicyList()
         .then(function (response) {
          // handle success
          if(response.data){
          setList(response.data);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getCandidatePolicyList = async() => {
        await api.getCandidatePolicyList(location_id)
         .then(function (response) {
          // handle success
          if(response.data){
          setListCandidate(response.data);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        setOpen(false)
      }

    const filterData = (data) => {
        let lower = search !== "" && search.toLowerCase()
        if (search !== "" && data.candidate_name) {
            return (
                (data.candidate_name.toLowerCase()).includes(lower)
            )
        }
        else {
            return true
        }
    }
    let row = []

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    
          
    const AntSwitch = withStyles((theme) => ({
        root: {
          width: 28,
          height: 16,
          padding: 0,
          display: 'flex',
        },
        switchBase: {
          padding: 2,
          color: theme.palette.grey[500],
          '&$checked': { 
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
              opacity: 1,
              backgroundColor: "#f37721",
              borderColor: "#f37721",
            },
          },
        },
        thumb: {
          width: 12,
          height: 12,
          boxShadow: 'none',
        },
        track: {
          border: `1px solid ${theme.palette.grey[500]}`,
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: theme.palette.common.white,
        },
        checked: {},
      }))(Switch);
    
      const updateActive = async(id,ActiveStatus) =>{
          let obj = {
              active:ActiveStatus
          } 
        await api.setPolicyDetails(id,obj)
        .then(function (response) {
         // handle success
         window.location.reload() 
       })
       .catch(function (error) {
         // handle error
         console.log(error);
       }) 

      }

    useEffect(() => {
        
        getPolicyList()
        getCandidatePolicyList() 
    }, []);

    Candidatelist.length > 0 && Candidatelist.filter(newlist => filterData(newlist)).map(e => { row.push(e) })

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+ event.target.value);
        setPage(0);
    };

    return (<Navigation main={
        (location_id && (sessionStorage.getItem("program_id")) && (sessionStorage.getItem("batch_id"))) ? <>
            <BackDrop open={open} handleClose={() => { setOpen(false) }} />
            <Grid container direction="row" justify="flex-end" alignItems="center"
            style={{ paddingBottom: '1%' }}
                 >
                <Grid item xs="10" lg="10">
                    <Breadcrumbs separator={
                        <NavigateNextIcon
                            fontSize="small" />
                    }
                        aria-label="breadcrumb">
                        <Typography style={{ color: "#f37721", cursor: "pointer" }} onClick={() => { history.push({ pathname: `/program` }) }} >
                            {
                                sessionStorage.getItem("program_name")
                            }
                            ({
                                sessionStorage.getItem("program_code")
                            })
                        </Typography>
                        <Link style={{ color: "#f37721", textDecoration: "none" }} to="/batch">
                            {
                                sessionStorage.getItem("batch_name")
                            }
                            ({
                                sessionStorage.getItem("batch_code")
                            })
                        </Link>
                        <Link style={{ color: "#f37721", textDecoration: "none" }} to="/location">
                            {
                                sessionStorage.getItem("location_name")
                            }
                            ({
                                sessionStorage.getItem("location_code")
                            })
                        </Link>
                        <Typography color="textPrimary">Scholarship</Typography>
                    </Breadcrumbs>
                </Grid> 
                <Grid item xs="2" lg="2">
                    <Fab variant="extended" style={{ color: "#f37721", backgroundColor: "white", fontWeight: "bold" }} aria-label="add"
                        onClick={() => { history.push("/scholarshipreport") }}>
                        <AssessmentIcon className={
                            classes.extendedIcon
                        } />
                        Generate Report
                    </Fab>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-end"   >
                <Grid item xs="6" lg="6"
                style={{ paddingRight: '1%' }}
                >
                
            <Paper className={
                classes.root
            }>
                 <Grid container direction="row" justify="flex-end" alignItems="center"
                 style={{ paddingTop: '1%' , paddingBottom: '10px' }}
                 >
                <Grid item xs="9" lg="9"> 
                        <Typography  style={{ color: "#f37721" , fontSize:"20px" ,fontWeight:"bold" }}>Scholarship Policy</Typography> 
                </Grid> 
                <Grid item xs="3" lg="3">
                    <Fab variant="extended" style={{ color: "#f37721", backgroundColor: "white", fontWeight: "bold" }} aria-label="add"
                        onClick={() => { history.push("/policycreate") }}>
                        <AddIcon className={
                            classes.extendedIcon
                        } />
                        Create
                    </Fab>
                </Grid>
            </Grid>
                <TableContainer className={
                    classes.container
                }>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow> {
                                columnsScholarship.map((column) => (
                                    <TableCell key={
                                        column.id
                                    }
                                        className={classes.head}
                                        align={
                                            column.align
                                        }
                                        style={
                                            {
                                                minWidth: column.minWidth,
                                                fontWeight: 'bold'
                                            }
                                        }>
                                        {
                                            column.label
                                        } </TableCell>
                                ))
                            }
                                <TableCell align="center" className={classes.head}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody> {
                            list.length > 0 ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <TableRow hover role="checkbox"
                                        tabIndex={-1}
                                        key={
                                            row.id
                                        }>
                                        <TableCell align="center">
                                            {
                                                i + 1
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.name
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.effective_date && moment(new Date(row.effective_date)).format("DD/MM/YYYY") 
                                            }</TableCell> 
                                        <TableCell align="center">  
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item> <HighlightOffRoundedIcon fontSize="small" style={{color:"red" }}/></Grid>
                                                <Grid item >
                                                    <AntSwitch   checked={row.active}  onChange={(e)=>{ updateActive(row.id,!row.active)}}   name="reg_form" />
                                                    </Grid>
                                                <Grid item><CheckCircleOutlineRoundedIcon fontSize="small" style={{color:"green"}}/></Grid>
                                                </Grid>
                                              </TableCell>
                                        <TableCell align="center">
                                            <Fab style={{ color: "#f37721", backgroundColor: "white" }} size="small" aria-label="edit" onClick={() => { history.push({ pathname: `/policyview/${row.id}`, query: { policy: row } }) }}>
                                                <VisibilityIcon />
                                            </Fab>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableCell colSpan="10" align="center"
                                style={
                                    { color: 'red' }
                                }>
                                <h5>
                                    No Policy Found
                                </h5>
                            </TableCell>
                        } </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination rowsPerPageOptions={
                    [10, 25, 100]
                }
                    component="div"
                    count={
                        list.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage} />
            </Paper>
            </Grid> 
                <Grid item xs="6" lg="6"  
                style={{ paddingLeft: '1%' }}>
                <Paper className={
                classes.root
            }>
                 <Grid container direction="row" justify="flex-end" alignItems="center"
                 style={{ paddingTop: '1%' , paddingBottom: '10px' }}
                 >
                <Grid item xs="3" lg="3"> 
                        <Typography  style={{ color: "#f37721" , fontSize:"20px" ,fontWeight:"bold" }}>Candidate List</Typography> 
                </Grid> 
                <Grid item xs="6" lg="6">
                    <Typography>
                        <TextField
                            type='text'
                            variant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment>{search && <HighlightOffIcon onClick={() => { setSearch("") }} style={{ cursor: "pointer" }} />}</InputAdornment>,
                            }}
                            label="Search Name"
                            value={search}
                            onChange={(e) => { setSearch(e.target.value); console.log(e.target.value) }}
                        />
                    </Typography>
                </Grid> 
                <Grid item xs="3" lg="3">
                    <Fab variant="extended" style={{ color: "#f37721", backgroundColor: "white", fontWeight: "bold" }} aria-label="add"
                        onClick={() => { history.push("/candidatepolicycreate") }}>
                        <AddIcon className={
                            classes.extendedIcon
                        } />
                        Create
                    </Fab>
                </Grid>
            </Grid>
                <TableContainer className={
                    classes.container
                }>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow> {
                                columnsCandidate.map((column) => (
                                    <TableCell key={
                                        column.id
                                    }
                                        className={classes.head}
                                        align={
                                            column.align
                                        }
                                        style={
                                            {
                                                minWidth: column.minWidth,
                                                fontWeight: 'bold'
                                            }
                                        }>
                                        {
                                            column.label
                                        } </TableCell>
                                ))
                            }
                                <TableCell align="center" className={classes.head}></TableCell>
                                <TableCell align="center" className={classes.head}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody> {
                            row.length > 0 ? row.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <TableRow hover role="checkbox"
                                        tabIndex={-1}
                                        key={
                                            row.id
                                        }>
                                        <TableCell align="center">
                                            {
                                                i + 1
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.candidate_name
                                            }</TableCell>   
                                             <TableCell align="center">
                                            {
                                                row.final_scholarship
                                            }</TableCell>  
                                        <TableCell align="center">
                                            <Fab style={{ color: "#f37721", backgroundColor: "white" }} size="small" aria-label="edit" onClick={() => { history.push({ pathname: `/candidatepolicyedit/${row.id}`,query: { candidate: row }}) }}>
                                                <EditIcon />
                                            </Fab>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Fab style={{ color: "#f37721", backgroundColor: "white" }} size="small" aria-label="edit" onClick={() => { history.push({ pathname: `/candidatepolicyview/${row.id}`, query: { candidate: row } }) }}>
                                                <VisibilityIcon />
                                            </Fab>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableCell colSpan="10" align="center"
                                style={
                                    { color: 'red' }
                                }>
                                <h5>
                                    No Candidates Found
                                </h5>
                            </TableCell>
                        } </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination rowsPerPageOptions={
                    [10, 25, 100]
                }
                    component="div"
                    count={
                        list.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage} />
            </Paper>
                </Grid>
           </Grid>
        </> : <Paper className={
            classes.root
        }>
            <h5 style={
                { color: 'red' }
            }>Location is not Selected</h5>
        </Paper>
    } 
        drawerIndex={11}
    />
    );
}
export default ScholarshipTable

