import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'; 
import * as api from '../../Api';
import { Link } from 'react-router-dom'; 
import {Grid} from '@material-ui/core'; 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Typography } from '@material-ui/core'; 
import Navigation from '../../Nav/Nav'; 
import BackDrop from '../Backdrop';
import ReactExport from 'react-data-export';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
 
const columns = [
    { id: 'code', label: 'No.', minWidth: 10 ,align: 'left', },
  { id: 'name', label: 'Name', minWidth: 170 ,align: 'center', },
  { id: 'code', label: 'Merit\u00a0Score (100)', minWidth: 50 ,align: 'center',}, 
  { id: 'code', label: 'Means\u00a0Score (9)', minWidth: 50 ,align: 'center',},   
  { id: 'code', label: 'Merit\u00a0Score (30)', minWidth: 50 ,align: 'center',},
  { id: 'code', label: 'Means\u00a0Score (70)', minWidth: 50 ,align: 'center',},
  { id: 'code', label: 'Scholarship Score', minWidth: 50 ,align: 'center',},
  { id: 'code', label: 'Calculated Scholarship', minWidth: 50 ,align: 'center',},
  { id: 'code', label: 'Requested Scholarship', minWidth: 50 ,align: 'center',},
  { id: 'code', label: 'Final Scholarship', minWidth: 50 ,align: 'center',}
];
 
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor:'#d9d9d9',
    height: '50px', 
    fontWeight: 'bold',
    fontSize: '15px',
  }
});

 function ScholarshipList() { 
  const [viewNavigate,setViewNavigate] = useState(true)
  const [program, setProgram] = useState(sessionStorage.getItem("location_id"))
  const [list, setList] = useState([]); 
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  

  const DataSet = [
    {
        columns: [
            {title: "Sl.No", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true },fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 50}}, // width in pixels
            {title: "Name", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 30}}, // width in characters
            {title: "Merit Score (100)", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 25}}, // width in characters
            {title: "Means Score (9)", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 25}}, // width in characters
            {title: "Merit Score (30)", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 25}}, // width in characters
            {title: "Means Score (70)", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 25}}, // width in characters
            {title: "Scholarship Score", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 25}}, // width in characters
            {title: "Calculated Scholarship", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 30}}, // width in characters  
            {title: "Requested Scholarship", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 30}}, // width in characters
            {title: "Final Scholarship", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 25}}, // width in characters
        ],
        data: list.map((row,i) => [
            {value: i+1, style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.candidate_name, style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.total_merit_score  ? Number((row.total_merit_score).toFixed(2)) : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.total_mean_score  ? row.total_mean_score  : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.merit_score_30  ? row.merit_score_30  : "",  style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.mean_score_70  ? row.mean_score_70  : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.scholarship_score  ? row.scholarship_score  : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.calculated_scholarship  ? row.calculated_scholarship   : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}}, 
            {value: row.requested_scholarship  ? row.requested_scholarship   : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
            {value: row.final_scholarship  ?  row.final_scholarship : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}}, 
        ])
    }
  ] 
 
  const getReportDetails = async() =>{
      setOpen(true)
    await api.getCandidateScholarshipReport(program) 
    .then(function (response) {
      // handle success 
      setList(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    setOpen(false)
  }
    useEffect(() => {
     // Make a request for a user with a given ID
     getReportDetails()
   }, []);
 
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
   
  return (
    <Navigation main={
    (program) ?
    <>
    <BackDrop open={Open} handleClose={() => { setOpen(false) }} />
    <Grid container  direction="row"  justify="flex-end" alignItems="center" style={{ marginBottom: '2%'}}> 
    <Grid item xs="6" lg="10">
        {viewNavigate ? 
    <Breadcrumbs separator={
                        <NavigateNextIcon
                    fontSize="small"/>
                    }
                    aria-label="breadcrumb">
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/program">
                        {
                        sessionStorage.getItem("program_name")
                    }
                        ({
                        sessionStorage.getItem("program_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}}to="/batch">
                        {
                        sessionStorage.getItem("batch_name")
                    }
                        ({
                        sessionStorage.getItem("batch_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/location">
                        {
                        sessionStorage.getItem("location_name")
                    }
                        ({
                        sessionStorage.getItem("location_code")
                    })
                    </Link>
                    <Typography color="textPrimary">Scholarship Report</Typography>
                </Breadcrumbs> :  
                 <Typography variant="h4" align="left">
            Candidate Report
          </Typography> }
      </Grid>
      <Grid item alignItems="center" xs="6" lg="2"> 
      <ExcelFile 
                         filename={`ScholarshipReport${new Date().toLocaleString()}`} 
                         element={
                          <Button style={{ color: "white" ,backgroundColor: "#f37721"  }}>Export
                           </Button>
                         }>
                             <ExcelSheet dataSet={DataSet} name={`${new Date().toLocaleString()}`}/>
                         </ExcelFile>
      </Grid> 
         </Grid>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))} 
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length>0 ? list.map((row,index) => {
              return (   
                 <TableRow hover    tabIndex={-1} key={row.id}  > 
                  <TableCell align="left">{index+1}</TableCell> 
                <TableCell align="center">{row.candidate_name}</TableCell>  
                  <TableCell align="center">
                    {row.total_merit_score ?  
                            Number((row.total_merit_score).toFixed(2)) 
                            : "-"
                        }
                  </TableCell>
                  <TableCell align="center">
                    {row.total_mean_score? 
                            Number((row.total_mean_score).toFixed(2))
                            : "-"
                        }
                  </TableCell>
                  <TableCell align="center">
                  {row.merit_score_30?  
                            row.merit_score_30
                            : "-"
                        }
                  </TableCell> 
                  <TableCell align="center"> 
                  {row.mean_score_70?  
                            row.mean_score_70
                            : "-"
                        }
                 </TableCell>
                 <TableCell align="center"> 
                  {row.scholarship_score?  
                            row.scholarship_score
                            : "-"
                        }
                 </TableCell>
                 <TableCell align="center"> 
                  {row.calculated_scholarship?  
                             row.calculated_scholarship
                            : "-"
                        }
                 </TableCell>
                 <TableCell align="center"> 
                  {row.requested_scholarship?   
                            row.requested_scholarship
                            : "-"
                        }
                 </TableCell>
                 <TableCell align="center"> 
                  {row.final_scholarship? 
                            row.final_scholarship  
                            : "-"
                        }
                 </TableCell>
                </TableRow>              
              );
            }): <TableCell colSpan="10" align="center" style={{ color:'red' }}>  <h5>No Records Found</h5> </TableCell>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </>
    :
     <Paper className={classes.root}>
       <h5 style={{ color:'red' }}>Location is not Selected</h5>
     </Paper> }drawerIndex={9}/>
  );
}
export default ScholarshipList
