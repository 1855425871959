
import { BrowserRouter as Router } from 'react-router-dom'; 
import React from 'react';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import './App.css'; 
import SignIn from './Utils/SignIn';  
import Dashboard from './Dashboard/Dashboard';
import Program from './components/Program/program_table'; 
import ProgramView from './components/Program/program_view';
import ProgramAdd from './components/Program/program_add';
import ProgramEdit from './components/Program/program_edit';
import BatchView from './components/Batch/BatchView';
import BatchAdd from './components/Batch/BatchAdd';
import BatchEdit from './components/Batch/BatchEdit';
import Batch from './components/Batch/BatchTable';
import Location from './components/Location/location_table';
import LocationView from './components/Location/location_view';
import LocationEdit from './components/Location/location_edit';
import LocationAdd from './components/Location/location_add';
import CandidateTable from './components/Candidate/candidate_table';
import CandidateAdd from './components/Candidate/candidate_add'; 
//import CandidateScan from '../components/Candidate/candidate_scan';
import Evaluation from './components/Evaluation/BatchName'; 
import WrittenlistHandler from './components/Score_page/WrittenList';
import WrittenTestImageAdd from './components/Evaluation/writtenTestImageAdd';
import personallistHandler from'./components/Score_page/PersonalList'; 
import GroupAdd from './components/Group_creation/add_group';
import GroupListHandler from './components/Score_page/GroupList';
import BusinessChallangeList from './components/Score_page/BusinessList';
import SearchReport from './components/Report/SearchReport';
import ReportList from './components/Report/ReportList';
import DocumentAdd from './components/Document_verify/document_add';
import DocumentTable from './components/Document_verify/document_table';
import DocumentEdit from './components/Document_verify/document_edit';
import UserList from './components/Create_user/UserList';
import UserAdd from './components/Create_user/UserAdd';
import UserEdit from './components/Create_user/UserEdit';
import UserView from './components/Create_user/UserView';
import CandidateList from './components/Report/CandidateList';
import CandidateEdit from './components/Candidate/candidate_edit';
import Scholarship from './components/Scholarship/Scholarship_table';
import PolicyAdd from './components/Scholarship/policy_create';
import PolicyView from './components/Scholarship/policy_view';
import CandidatePolicyAdd from './components/Scholarship/candidate_policy_create';
import CandidatePolicyEdit from './components/Scholarship/candidate_policy_edit';
import CandidatePolicyView from './components/Scholarship/candidate_view';
import ScholarshipList from './components/Report/ScholarshipList';
import Run from './components/Report/run';

function App(){
  return (
    <div className="App">
        <main>
           <Router>  
            <PrivateRoute exact path="/" component={Dashboard}/>   
            <PrivateRoute path="/program" component={Program} /> 
            <PrivateRoute path="/programview/:id?" component={ProgramView}  />
            <PrivateRoute path="/programadd" component={ProgramAdd} />
            <PrivateRoute path="/programedit/:id?" component={ProgramEdit}  /> 
            <PrivateRoute path="/batch" component={Batch} />
            <PrivateRoute path="/batchview/:id?" component={BatchView} />
            <PrivateRoute path="/batchadd" component={BatchAdd} />
            <PrivateRoute path="/batchedit/:id?" component={BatchEdit} /> 
            <PrivateRoute path="/location" component={Location} />
            <PrivateRoute path="/locationview/:id?" component={LocationView} />
            <PrivateRoute path="/locationadd" component={LocationAdd} />
            <PrivateRoute path="/locationedit/:id?" component={LocationEdit} /> 
            <PrivateRoute path="/candidate" component={CandidateTable}/> 
            <PrivateRoute path="/candidateadd" component={CandidateAdd} /> 
            <PrivateRoute path="/candidateedit/:id" component={CandidateEdit} /> 
            <PrivateRoute path="/evaluation" component={Evaluation} /> 
            <PrivateRoute path="/WrittenlistHandler/:id?" component={WrittenlistHandler}/>
            <PrivateRoute path="/Writtenlist/:id?" component={WrittenTestImageAdd}/> 
            <PrivateRoute path="/personallistHandler/:id?" component={personallistHandler}/>
            <PrivateRoute path="/addgroup/:id?" component={GroupAdd}/>
            <PrivateRoute path="/grouplistHandler/:id?" component={GroupListHandler}/>
            <PrivateRoute path="/businessHandler/:id?" component={BusinessChallangeList}/> 
            <PrivateRoute exact path="/report/:id?" component={SearchReport}/>
            <PrivateRoute exact path="/report/results/:id?" component={ReportList}/>
            <PrivateRoute exact path="/report/candidates/:id?" component={CandidateList}/>
            {/* <PrivateRoute exact path="/report/scholarship/:id?" component={ScholarshipReport}/> */}
            <PrivateRoute path="/documents" component={DocumentTable}/>
            <PrivateRoute path="/documentadd" component={DocumentAdd}/>
            <PrivateRoute path="/documentedit" component={DocumentEdit}/>
            <PrivateRoute path="/users" component={UserList}/>
            <PrivateRoute path="/useradd" component={UserAdd}/>
            <PrivateRoute path="/useredit" component={UserEdit}/>
            <PrivateRoute path="/userview/:id?" component={UserView}/>
            <PrivateRoute path="/scholarship" component={Scholarship}/>
            <PrivateRoute path="/policycreate" component={PolicyAdd}/>
            <PrivateRoute path="/policyview" component={PolicyView}/>
            <PrivateRoute path="/candidatepolicycreate" component={CandidatePolicyAdd}/>
            <PrivateRoute path="/candidatepolicyedit" component={CandidatePolicyEdit}/>
            <PrivateRoute path="/candidatepolicyview" component={CandidatePolicyView}/>
            <PrivateRoute exact path="/scholarshipreport" component={ScholarshipList}/>
            <PrivateRoute path="/run/:id?" component={Run}/>
            <PublicRoute restricted={true} component={SignIn} path="/login" exact />  
           </Router>   
        </ main>
        </div>
    )
}
export default App

