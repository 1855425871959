import React , { useState , useEffect } from 'react'; 
import { makeStyles, useTheme } from '@material-ui/core/styles'; 
import Typography from '@material-ui/core/Typography'; 
import {Grid,Card,CardContent,CardActions,Button} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'; 
import { Select } from '@material-ui/core';
import * as api from '../../Api'; 
import MenuItem from '@material-ui/core/MenuItem'; 
import { useHistory } from 'react-router-dom';  
import Navigation from '../../Nav/Nav';
 
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardroot: {
    minWidth: 275,
  }, 
formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  content: {
    flexGrow: 1, 
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
 
export default function SearchReport() {
  const selected = window.location.href.split('/').pop() 
  const classes = useStyles(); 
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);  
  const [ programList, setProgramList] = useState([])
  const [batchList, setBatchList] = useState([]);  
  const [locationList, setLocationList] = useState([]); 
  const [location, setLocation] = useState(""); 

   const handleChangeProgram = (event) => {
     api.getBatches(event.target.value) 
    .then(function (response) {
        // handle success 
        setBatchList(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      }) 
   }

   const handleChangeBatch = (event) => {
    api.getLocations(event.target.value) 
    .then(function (response) {
        // handle success 
        setLocationList(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      }) 
   }

useEffect(() => {
    // Make a request for a user with a given ID
    api.getPrograms() 
   .then(function (response) {
     // handle success 
     setProgramList(response.data);
   })
   .catch(function (error) {
     // handle error
     console.log(error);
   }) 
  }, []);

  return (
    <Navigation main={
    <div className={classes.root}>
      <main className={classes.content}>
        <Grid container justify="center"
    alignItems="center"
  >
    <Grid item xs={12} lg={6}>
 <Card className={classes.classroot}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" 
         variant="h5"
         style={
             {color: "black"}
         }
        gutterBottom>
         {selected === 'candidate' && "Candidate Report Details"}
         {selected === 'result' && "Result Report Details"}
        </Typography>
         <Typography variant="body2" component="p">
     <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="spanning table">
       <TableBody> 
           <TableRow>
             <TableCell style={{ fontWeight: 'bold',width:"200px" }}>Select Program<spam style={{color:'red'}}>*</spam></TableCell>
             <TableCell align="left">
             <Select
             fullWidth
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select" 
          onChange={handleChangeProgram}
        > 
                { programList.map((row) => { 
                    return <MenuItem value={row.id}>{row.program_name}</MenuItem>
                 })
                }    
                </Select> 
            </TableCell>
           </TableRow>
           <TableRow>
             <TableCell style={{ fontWeight: 'bold' }}>Select Batch<spam style={{color:'red'}}>*</spam></TableCell>
             <TableCell align="left">
             <Select
             fullWidth
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select" 
          onChange={handleChangeBatch}
        > 
                { batchList.length>0 ? batchList.map((row) => { 
                    return <MenuItem value={row.id}>{row.batch_name}</MenuItem>
                 }): <MenuItem style={{color:"red"}}>Choose A Program</MenuItem>
                }   
                </Select> 
            </TableCell>
           </TableRow>
           <TableRow>
             <TableCell style={{ fontWeight: 'bold' }}>Select Location<spam style={{color:'red'}}>*</spam></TableCell>
             <TableCell align="left"> 
             <Select
             fullWidth
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select" 
          onChange={(event)=>{setLocation(event.target.value)}}
        > 
                { locationList.length>0 ? locationList.map((row) => { 
                    return <MenuItem value={row.id}>{row.location_name}</MenuItem>
                 }): <MenuItem style={{color:"red"}}>Choose A Batch</MenuItem> 
                }     
                </Select>
             </TableCell>
           </TableRow>
        </TableBody>
     </Table>
    </TableContainer>
        </Typography>
      </CardContent>
      {selected === 'candidate' &&
      <Button     margin="normal" variant="contained" onClick={()=>{history.push({pathname: `/report/candidates/${location}`})}}   style={{ color: "white",  backgroundColor: "#f37721"}}>Generate Report</Button>
      }
      {selected === 'result' &&
      <Button     margin="normal" variant="contained" onClick={()=>{history.push({pathname: `/report/results/${location}`})}}   style={{ color: "white",  backgroundColor: "#f37721"}}>Generate Report</Button>
      }
      <CardActions >
      </CardActions>
    </Card>
    </Grid></Grid>       
      </main>
      {/* <div>
        {redirect ? 
       <Redirect to = {{pathname: `/evaluation/`+selected}} />
                : null}
      </div> */}
     </div>
     }/>
  );
}
 