import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
  // Your firebase credentials 
  apiKey: "AIzaSyBVa0VFk0BH_vInTEygY5IBlZ1fl_7JKOU",
  authDomain: "alc-learning-lab-dev.firebaseapp.com",
  projectId: "alc-learning-lab-dev",
  storageBucket: "alc-learning-lab-dev.appspot.com",
  messagingSenderId: "955571844904",
  appId: "1:955571844904:web:05f5905a23ffb1fd8b421d",
  measurementId: "G-DGN5Y0MYG6"
});

var db = firebaseApp.firestore();
db.enablePersistence()
  .catch((err) => {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });
// Subsequent queries will use persistence, if it was enabled successfully
export { db };
export const auth = firebase.auth()
