import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { Link, useHistory } from 'react-router-dom';
import { db } from '../../firebase';
import { useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Typography } from '@material-ui/core';
import BackDrop from '../Backdrop';
import Navigation from '../../Nav/Nav';
import EditIcon from '@material-ui/icons/Edit';
import { InputAdornment } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const columns = [
    {
        id: 'no',
        label: 'SlNo.',
        minWidth: 5,
        align: 'center'
    },
    {
        id: 'name',
        label: 'Name',
        minWidth: 170,
        align: 'center'
    },
    {
        id: 'phone',
        label: 'Phone',
        minWidth: 50,
        align: 'center'
    },
    {
        id: 'gov',
        label: 'Aadhar\u00a0Number',
        minWidth: 170,
        align: 'center'
    },
    {
        id: 'DOB',
        label: 'DOB',
        minWidth: 100,
        align: 'center'
    }, {
        id: 'Gender',
        label: 'Gender',
        minWidth: 100,
        align: 'center'
    }, {
        id: 'location',
        label: 'Village\u00a0Location',
        minWidth: 170,
        align: 'center'
    },
    {
        id: 'edu',
        label: 'Education',
        minWidth: 100,
        align: 'center'
    },
    {
        id: 'cat',
        label: 'Category',
        minWidth: 100,
        align: 'center'
    },
];

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        maxHeight: 540,
    },
    head: {
        backgroundColor: '#d9d9d9',
        height: '50px',
        fontWeight: 'bold',
        fontSize: '18px'
    }
});

function CandidateTable() {
    const [search, setSearch] = useState("");
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState([]);
    const [open, setOpen] = useState(false);
    const location_id = sessionStorage.getItem("location_id");

    const filterData = (data) => {
        let lower = search !== "" && search.toLowerCase()
        if (search !== "" && data.candidate_name) {
            return (
                (data.candidate_name.toLowerCase()).includes(lower)
            )
        }
        else {
            return true
        }
    }
    let row = []

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        setOpen(true)

        db.collection("candidate").where("location_id", "==", location_id).onSnapshot((docs) => {
            const list_value = [];
            docs.forEach((doc) => {
                list_value.push({ ...doc.data(), id: doc.id })
            });
            setList(list_value.sort((a, b) => { return a.candidate_name.localeCompare(b.candidate_name) }))
        });
        setOpen(false)
    }, []);

    list.length > 0 && list.filter(newlist => filterData(newlist)).map(e => { row.push(e) })

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+ event.target.value);
        setPage(0);
    };

    return (<Navigation main={
        (location_id && (sessionStorage.getItem("program_id")) && (sessionStorage.getItem("batch_id"))) ? <>
            <BackDrop open={open} handleClose={() => { setOpen(false) }} />
            <Grid container direction="row" justify="flex-end" alignItems="center"
                style={
                    { marginBottom: '2%' }
                }>
                <Grid item xs="6" lg="8">
                    <Breadcrumbs separator={
                        <NavigateNextIcon
                            fontSize="small" />
                    }
                        aria-label="breadcrumb">
                        <Typography style={{ color: "#f37721", cursor: "pointer" }} onClick={() => { history.push({ pathname: `/program` }) }} >
                            {
                                sessionStorage.getItem("program_name")
                            }
                            ({
                                sessionStorage.getItem("program_code")
                            })
                        </Typography>
                        <Link style={{ color: "#f37721", textDecoration: "none" }} to="/batch">
                            {
                                sessionStorage.getItem("batch_name")
                            }
                            ({
                                sessionStorage.getItem("batch_code")
                            })
                        </Link>
                        <Link style={{ color: "#f37721", textDecoration: "none" }} to="/location">
                            {
                                sessionStorage.getItem("location_name")
                            }
                            ({
                                sessionStorage.getItem("location_code")
                            })
                        </Link>
                        <Typography color="textPrimary">Candidates</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs="6" lg="2">
                    <Typography>
                        <TextField
                            type='text'
                            variant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment>{search && <HighlightOffIcon onClick={() => { setSearch("") }} style={{ cursor: "pointer" }} />}</InputAdornment>,
                            }}
                            label="Search Name"
                            value={search}
                            onChange={(e) => { setSearch(e.target.value); console.log(e.target.value) }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs="6" lg="2">
                    <Fab variant="extended" style={{ color: "#f37721", backgroundColor: "white", fontWeight: "bold" }} aria-label="add"
                        onClick={() => { history.push("/candidateadd") }}>
                        <AddIcon className={
                            classes.extendedIcon
                        } />
                        Add Candidate
                    </Fab>
                </Grid>
            </Grid>
            <Paper className={
                classes.root
            }>
                <TableContainer className={
                    classes.container
                }>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow> {
                                columns.map((column) => (
                                    <TableCell key={
                                        column.id
                                    }
                                        className={classes.head}
                                        align={
                                            column.align
                                        }
                                        style={
                                            {
                                                minWidth: column.minWidth,
                                                fontWeight: 'bold'
                                            }
                                        }>
                                        {
                                            column.label
                                        } </TableCell>
                                ))
                            }
                                <TableCell align="center" className={classes.head}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody> {
                            row.length > 0 ? row.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <TableRow hover role="checkbox"
                                        tabIndex={-1}
                                        key={
                                            row.id
                                        }>
                                        <TableCell align="center">
                                            {
                                                i + 1
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.candidate_name
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.phone_number
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.government_id
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                 row.dob && new Date(row.dob).toLocaleDateString()
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.gender
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.village_location
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.education
                                            }</TableCell>
                                        <TableCell align="center">
                                            {
                                                row.category
                                            }</TableCell>
                                        <TableCell align="center">
                                            <Fab style={{ color: "#f37721", backgroundColor: "white" }} size="small" aria-label="edit" onClick={() => { history.push({ pathname: `/candidateedit/${row.id}`, query: { candidate: row } }) }}>
                                                <EditIcon />
                                            </Fab>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableCell colSpan="10" align="center"
                                style={
                                    { color: 'red' }
                                }>
                                <h5>
                                    No Candidates Found
                                </h5>
                            </TableCell>
                        } </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination rowsPerPageOptions={
                    [10, 25, 100]
                }
                    component="div"
                    count={
                        list.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage} />
            </Paper>
        </> : <Paper className={
            classes.root
        }>
            <h5 style={
                { color: 'red' }
            }>Location is not Selected</h5>
        </Paper>
    }
        drawerIndex={4}
    />
    );
}
export default CandidateTable

