import axios from 'axios'; 
const api = process.env.REACT_APP_API_URL
const token = localStorage.getItem("token") 

export function addMoodle(id){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/moodle_user`,
    data: id
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getMoodle(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/moodle_user_status?candidate_id=${id}`,
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}
 
export function getLogin(validToken){
  return axios({
    method: 'get', 
    headers: {"Authorization":validToken},
    url: `${api}/v1/profile`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function deleteGroup(id){
  return axios({
    method: 'delete', 
    headers: {"Authorization":token},
    url: `${api}/v1/groups/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}


export function getPath(location_id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/location_parent/${location_id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getUsers(){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/user_list`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getScoreByUsers(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/candidate_evaluator_wise_score_report/?candidate_id=${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}
 
export function setUser(id,data){
  return axios({
    method: 'patch', 
    headers: {"Authorization":token},
    url: `${api}/v1/users/${id}`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function setCandidate(id,data){
  return axios({
    method: 'patch', 
    headers: {"Authorization":token},
    url: `${api}/v1/candidates/${id}`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function addUser(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/users`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getRoles(){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/roles`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getDashboardDetails(){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/dashboard`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}
 
export function getPrograms(){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/programs`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function setProgram(id,data){
    return axios({
      method: 'patch', 
      headers: {"Authorization":token},
      url: `${api}/v1/programs/${id}`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function addProgram(data){
    return axios({
      method: 'post', 
      headers: {"Authorization":token},
      url: `${api}/v1/programs`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }
 
  export function getBatches(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/programs/${id}/batches`  
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function setBatch(id,data){
    return axios({
      method: 'patch', 
      headers: {"Authorization":token},
      url: `${api}/v1/batches/${id}`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function addBatch(data){
    return axios({
      method: 'post', 
      headers: {"Authorization":token},
      url: `${api}/v1/batches`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getLocations(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/batches/${id}/locations`  
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function setLocation(id,data){
    return axios({
      method: 'patch', 
      headers: {"Authorization":token},
      url: `${api}/v1/locations/${id}`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function addLocation(data){
    return axios({
      method: 'post', 
      headers: {"Authorization":token},
      url: `${api}/v1/locations`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getCandidatesList(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/locations/${id}/candidates`  
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getUnallocatedCandidates(id,type){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/candidates_unassigned?assessment_type_id=${type}&location_id=${id}`  
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getGroupsList(id,type){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/locations/${id}/assessment_types/${type}/groups ` 
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function addGroup(data){
    return axios({
      method: 'post', 
      headers: {"Authorization":token},
      url: `${api}/v1/groups`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getImages(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/candidates/${id}`  
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function setImages(id,data){
    return axios({
      method: 'patch', 
      headers: {"Authorization":token},
      url: `${api}/v1/update_answer_sheet/${id}`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function removeImages(id){
    return axios({
      method: 'patch', 
      headers: {"Authorization":token},
      url: `${api}/v1/delete_answer_sheet/${id}` 
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getReport(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/candidate_score_report/?location_id=${id}`  
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  
  export function setScore(id,data){
    return axios({
      method: 'patch', 
      headers: {"Authorization":token},
      url: `${api}/v1/scores/${id}`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }
   
  export function getGroupScore(userId,groupId,type){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/group_scores_by_user/?user_id=${userId}&group_id=${groupId}&assessment_type_id=${type}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  } 

  export function getScore(userId,candidateId,type){
    return axios({
      method: 'get', 
      headers: {"Authorization":token}, 
      url: `${api}/v1/candidate_scores_by_user/?user_id=${userId}&candidate_id=${candidateId}&assessment_type_id=${type}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getGroupCandidates(groupId){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/show_candidates?group_id=${groupId}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getQuestions(id,type){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/program_questions?program_id=${id}&assessment_type_id=${type}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getDocumentVerifyList(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/document_verifications?location_id=${id}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function addDocumentVerify(id,data){
    return axios({
      method: 'post', 
      headers: {"Authorization":token},
      url: `${api}/v1/document_verifications?location_id=${id}`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function setDocumentVerify(id,data){
    return axios({
      method: 'patch', 
      headers: {"Authorization":token},
      url: `${api}/v1/document_verifications/${id}`,
      data: data
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getPassedCandidates(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/unallotted_candidates/?location_id=${id}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }
  
  export function getPolicyList(){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/policy_details`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getCandidatePolicyList(id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/candidate_details/${id}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getMeanScoreDetail(Candidate_id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/mean_score_detail/${Candidate_id}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }
  
  export function getUnallottedScholarshipCandidates(Location_id){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/unallotted_scholarship_candidates?location_id=${Location_id}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  } 

  export function getActivePolicyDetails(){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/active_policy_details`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  }

  export function getAnnualIncomeDetail(PolicyId,Tier){
    return axios({
      method: 'get', 
      headers: {"Authorization":token},
      url: `${api}/v1/annual_income_detail?policy_detail_id=${PolicyId}&tier=${Tier}`
    }).then(response =>{ 
        return response
    }).catch(e => console.error("Error"+e))
  } 

  export function getDependentsDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/dependents_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getLivestockDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/livestocks_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getVehiclesDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/vehicles_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getHouseInfrastructureDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/house_infrastructure_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getLandHoldingsDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/land_holdings_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getEarningMembersDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/earning_members_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getIncomeSourceDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/income_source_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function getAgriculturalDetail(id){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/agricultural_detail/${id}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function addMeanScores(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/mean_scores`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e))
}

export function setMeanScores(id,data){
  return axios({
    method: 'patch', 
    headers: {"Authorization":token},
    url: `${api}/v1/mean_scores/${id}`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addPolicyDetails(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/policy_details`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function setPolicyDetails(id,data){
  return axios({
    method: 'patch', 
    headers: {"Authorization":token},
    url: `${api}/v1/policy_details/${id}`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addAnnualIncomes(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/annual_incomes`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addDependents(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/dependents`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addLivestocks(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/livestocks`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addVehicles(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/vehicles`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
} 

export function addHouseInfrastructures(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/house_infrastructures`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addLandHoldings(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/land_holdings`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addEarningMembers(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/earning_members`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addIncome_Sources(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/income_sources`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function addAgriculturalIncomes(data){
  return axios({
    method: 'post', 
    headers: {"Authorization":token},
    url: `${api}/v1/agricultural_incomes`,
    data: data
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}

export function getCandidateScholarshipReport(LocationId){
  return axios({
    method: 'get', 
    headers: {"Authorization":token},
    url: `${api}/v1/candidate_scholarship_report?location_id=${LocationId}`
  }).then(response =>{ 
      return response
  }).catch(e => console.error("Error"+e)) 
}
