import React, { useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow'; 
import TextField from '@material-ui/core/TextField';
import {Select} from '@material-ui/core'; 
import {Redirect} from 'react-router'; 
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../../Nav/Nav';
import * as api from '../../Api';
import { useHistory } from 'react-router-dom'; 

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    cardroot: {
        minWidth: 275
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    textInput: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1.8px',
            borderColor: "grey"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1.5px',
            borderColor: "#f37721"
        }
    }
}));

export default function ProgramAdd() {
    const [programCodeError, setProgramCodeError] = useState("")
    const [nameError, setNameError] = useState("")
    const [startDateError, setStartDateError] = useState("")
    const [open, setOpen] = useState(false);
    const [endDateError, setEndDateError] = useState("")
    const classes = useStyles();
    const history = useHistory();
    const [isAddProgram, setAddProgram] = useState(false);
    const [programObj, setProgramObj] = useState({
        "program_code": "",
        "program_name": "",
        "description": "",
        "start_date": "",
        "end_date": "",
        "isactive": "true",
        "funder": ""
    })

    const createProgram = () => {
         if(programObj.program_code === "" )
         {
            setProgramCodeError("Enter the Program Code")
         }
         else if(programObj.program_name === "" )
         {
            setNameError("Enter the Program Name")
         }
         else if(programObj.start_date === "" )
         {
            setStartDateError("Enter the Start Date")
         }
         else if(programObj.end_date === "" )
         {
            setEndDateError("Enter the End Date")
         }
         else
         {
             setOpen(true)
             api.addProgram(programObj)
             .then(response => {
                toast.success('Program Saved Successfully!', {
                position: "bottom-center",
                autoClose: 3000,
                onClose: ()=>{setAddProgram(true)},
                hideProgressBar: false,
                closeOnClick: true, 
                });  
                setOpen(false)  
        }).catch(error => {
            setOpen(false)
            console.error(error)
            toast.error('Error in Connection!', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true, 
                });
        })
        }
    }

    return (
        <Navigation main={
        <div className={
            classes.root
        }>
            <main className={
                classes.content
            }>
                <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                <BackDrop open={open} handleClose={()=>{setOpen(false)}}/>
                <Grid container justify="center" alignItems="center">
                    <Grid item
                        xs={12}
                        lg={12}>

                        <Card className={
                            classes.classroot
                        }>
                            <CardContent>
                                <Typography className={
                                        classes.title
                                    }
                                    variant="h5"
                                    style={
                                        {color: "black"}
                                    }
                                    gutterBottom>
                                    Add Program
                                </Typography> 
                                <Typography variant="body2" component="p"> 
                                    <TableContainer>
                                        <Table className={
                                                classes.table
                                            }
                                            aria-label="spanning table"> 
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width="150px"
                                                        style={
                                                            {fontWeight: 'bold'}
                                                    }>Program Code<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            error
                                                            helperText={programCodeError}
                                                            variant="outlined"
                                                            margin="dense" 
                                                            onInput={
                                                                e => {setProgramObj(prev => ({
                                                                    ...prev,
                                                                    program_code: e.target.value
                                                                }))
                                                                setProgramCodeError("")
                                                                    }
                                                            }
                                                            lable="Program Code"/></TableCell>
                                                    <TableCell width="150px"
                                                        style={
                                                            {fontWeight: 'bold'}
                                                    }>Program Name<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            error
                                                            helperText={nameError}
                                                            variant="outlined"
                                                            margin="dense"
                                                            onInput={
                                                                e => {setProgramObj(prev => ({
                                                                    ...prev,
                                                                    program_name: e.target.value
                                                                }))
                                                            setNameError("")
                                                            }
                                                            }/></TableCell>
                                                </TableRow> 
                                                <TableRow>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Start Date<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            error
                                                            helperText={startDateError}
                                                            type='date'
                                                            onInput={
                                                                e => {setProgramObj(prev => ({
                                                                    ...prev,
                                                                    start_date: e.target.value
                                                                }))
                                                                setStartDateError("")
                                                            }
                                                            }/></TableCell>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>End Date<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            error
                                                            helperText={endDateError}
                                                            type='date'
                                                            onInput={
                                                                e => {setProgramObj(prev => ({
                                                                    ...prev,
                                                                    end_date: e.target.value
                                                                }))
                                                                setEndDateError("")
                                                            }
                                                            }/></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Funder</TableCell>
                                                    <TableCell align="left"><TextField className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            onInput={
                                                                e => setProgramObj(prev => ({
                                                                    ...prev,
                                                                    funder: e.target.value
                                                                }))
                                                            }/></TableCell>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Status<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Select native
                                                            className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense" 
                                                            onChange={
                                                                e => setProgramObj(prev => ({
                                                                    ...prev,
                                                                    isactive: e.target.value
                                                                }))
                                                        }> 
                                                            <option selected value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Description</TableCell>
                                                    <TableCell align="left" colSpan="3"><TextField className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            margin="dense"
                                                            variant="outlined"
                                                            multiline
                                                            maxRows={4}
                                                            onInput={
                                                                e => setProgramObj(prev => ({
                                                                    ...prev,
                                                                    description: e.target.value
                                                                }))
                                                            }/></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </CardContent> 
                            <Button margin="normal" variant="contained"
                                style={
                                    {
                                        color: "white",
                                        backgroundColor: "#f37721"
                                    }
                                }
                                onClick={
                                    (value) => createProgram()
                                }
                                color='default'>Submit </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button margin="normal" variant="contained"
                                style={
                                    {backgroundColor: "#d9d9d9"}
                                }
                                href="/program"
                                color='default'>Cancel</Button> 
                            <CardActions></CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </main> 
            <div> {
                isAddProgram ? <Redirect to={history.push({pathname: "/program"})    
                }/> : null
            } </div>
        </div>}
         drawerIndex={1}
         />
    );
}

