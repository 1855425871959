import React , { useState , useEffect } from 'react'; 
import { makeStyles, useTheme } from '@material-ui/core/styles'; 
import Typography from '@material-ui/core/Typography'; 
import {Grid,Card,CardContent,CardActions,Button} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';  
import * as api from '../../Api'; 
import Navigation from '../../Nav/Nav';
import { useHistory } from 'react-router-dom';  
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
 
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardroot: {
    minWidth: 275,
  }, 
formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  content: {
    flexGrow: 1, 
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
}));
  
export default function GroupAdd() {
  const selected = window.location.href.split('/').pop()
  const location_id=sessionStorage.getItem("location_id")
  const classes = useStyles(); 
  const [redirect, setRedirect] = useState(false); 
  const [groupName, setGroupName] = useState()
  const [groupDes,setGroupDes] = useState()
  const [ list, setList] = useState([])
  const [personName, setPersonName] = useState([]);   

  const handleChange = (event,value) => { 
    if(value)
    {
      setPersonName(value);  
    }  
  };
  
  const createGroup = () =>
  {
    let array=[]
    personName.map(value =>{
    array.push({"candidate_id":value.id})
    })
   let groupObj= {
        "group_name" : groupName,
        "description" : groupDes,
        "location_id" : location_id,
        "assessment_type_id" : selected,
        "candidate_groups_attributes" : array} 
     api.addGroup(groupObj) 
     .then(response =>{
       setRedirect(true)
     })
     .catch(error => {
     })
  }

useEffect(() => {
    // Make a request for a user with a given ID
    api.getUnallocatedCandidates(location_id,selected)
    .then(function (response) {
     // handle success 
     setList(response.data);
   })
   .catch(function (error) {
     // handle error
     console.log(error);
   }) 
  }, []);

  return (
    <Navigation main={
    <div className={classes.root}>
      <main className={classes.content}>
        <Grid container justify="center"
    alignItems="center"
  >
    <Grid item xs={12} lg={6}>
 <Card className={classes.classroot}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
         {selected==2 && "Group Discussion Group Creation Details"}
         {selected==4 && "Business Challenges Group Creation Details"}
        </Typography>
         <Typography variant="body2" component="p">
     <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="spanning table">
       <TableBody> 
           <TableRow>
             <TableCell style={{ fontWeight: 'bold' }}>Group Name<spam style={{color:'red'}}>*</spam></TableCell>
             <TableCell align="left"><TextField  fullWidth  onChange={e => setGroupName(e.target.value)} lable="Batch Name"  /></TableCell>
           </TableRow>
           <TableRow>
             <TableCell style={{ fontWeight: 'bold' }}>Group Description</TableCell>
             <TableCell align="left"><TextField  fullWidth  onChange={e => setGroupDes(e.target.value)} lable="Batch Description"  /></TableCell>
           </TableRow>
           <TableRow>
             <TableCell style={{ fontWeight: 'bold' }}>Candidate List<spam style={{color:'red'}}>*</spam></TableCell>
             <TableCell align="left"> 
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={list} 
                        disableCloseOnSelect
                        getOptionLabel={(option) =>   option.candidate_name} 
                        value={personName}
                        ChipProps={{style:{backgroundColor:"#f37721",color:"white"}}} 
                        onChange={handleChange }
                        renderOption={(option, { selected })=> (
                          <React.Fragment>
                            <Checkbox
                              icon={icon} 
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8, color:"green"}}
                              checked={selected}
                            />
                            {option.candidate_name} 
                          </React.Fragment>
                        )}
                        style={{ width: 410 }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Add Members" placeholder="Search Candidate" />
                        )}
                      />  
             </TableCell>
           </TableRow>
        </TableBody>
     </Table>
    </TableContainer>
        </Typography>
      </CardContent>
      <Button  style={  {  color: "white", backgroundColor: "#f37721"   }  }   margin="normal" variant="contained"  onClick={(value)=>createGroup()} color='default'>Create Group</Button>
       <CardActions >
      </CardActions>
    </Card>
    </Grid></Grid>       
      </main>
      <div>
        {redirect ? 
        window.location.href= `/evaluation/`+selected
              //  <Redirect to = {{pathname: `/evaluation/`+selected}} />
                : null}
      </div>
     </div>
    } drawerIndex={5}/>
  );
}
 

