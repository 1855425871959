import React, { useEffect , useState } from 'react';
import './Dashboard.css'; 
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Divider, Grid} from '@material-ui/core'; 
import {Card,CardContent} from '@material-ui/core'; 
import Navigation from '../Nav/Nav';
import * as api from '../Api';

const useStyles = makeStyles((theme) => ({
  root: { 
    display: 'flex', 
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  title:{ 
    color:"black"
  },
  selected:
  {
    fontWeight:"bold",
    color:"green",
  } 
  }));

function Dashboard (){
  const classes = useStyles();
  const [list,setList] = useState([])

  const getDetails = async()=>{
    await api.getDashboardDetails() 
    .then(function (response) {
      // handle success 
      setList(response.data); 
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }

  useEffect(() => { 
   getDetails()
  }, [])

return(
  <Navigation main={
  <div className={classes.root}>
       <Grid container className={classes.root} >
       <Grid item xs={12} lg={12}>
       <Typography variant="h4" align="left">
            Home
          </Typography>
        </Grid>  
         <Grid item xs={12} lg={12} style={{marginTop:"3%"}}>
          <Grid container spacing={3} justify= "center"> 
          <Grid item xs={12} lg={3} >
            <Card id="editIcon" >
              <CardContent id="CardContentedit">
                {list.total_programs}
              </CardContent>
            </Card>
            <Card className={classes.card} id="card">
             <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Total Programs
                </Typography>
              </CardContent>     <Divider/>
              <br/>
            <span className={classes.selected}>{ sessionStorage.getItem("program_name") ?  `Selected: ${sessionStorage.getItem("program_name")}`:null}</span>
            <br/><br/>  
            <Button   style={{color:"#f37721",backgroundColor:"white", fontStyle:"italic"}}  href="#">View</Button>  
            {/* /program */}
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
           <Card id="groupIcon">
              <CardContent id="CardContentgroup">
              {list.total_batches}
              </CardContent>
            </Card>
            <Card className={classes.card} id="card">
              <CardContent>
               <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Total Batches
               </Typography>
              </CardContent>
              <Divider/>
              <br/>
            <span className={classes.selected}>{ sessionStorage.getItem("batch_name") ? `Selected: ${sessionStorage.getItem("batch_name")}`:null}</span>
            <br/><br/>  
            <Button   style={{color:"#f37721",backgroundColor:"white", fontStyle:"italic"}} href="#">View</Button>
            {/* /batch */}
            </Card>
          </Grid>
          <Grid item  xs={12} lg={3}>
           <Card id="personIcon">
              <CardContent id="CardContentperson">
              {list.total_locations}
              </CardContent>
            </Card>
            <Card className={classes.card} id="card">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Total Locations
                </Typography>
              </CardContent>
              <Divider/>
              <br/>
            <span className={classes.selected}>{ sessionStorage.getItem("location_name") ? `Selected: ${sessionStorage.getItem("location_name")}`:null}</span>
            <br/><br/>  
            <Button   style={{color:"#f37721",backgroundColor:"white", fontStyle:"italic"}} href="#">View</Button>
            {/* /location */}
            </Card>
          </Grid>
          <Grid item  xs={12} lg={3}>
          <Card id="businessIcon">
              <CardContent id="CardContentbusiness">
              {list.total_candidates}
              </CardContent>
            </Card>
            <Card className={classes.card} id="card">
             <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Total Candidates
                </Typography>
              </CardContent>
              <Divider/>
              <br/> 
              <span className={classes.selected}>{ sessionStorage.getItem("location_name") ? `Selected: ${sessionStorage.getItem("location_name")}`:null}</span>
            <br/><br/> 
            <Button   style={{color:"#f37721",backgroundColor:"white", fontStyle:"italic"}} href="#">View</Button>&nbsp;&nbsp;&nbsp;&nbsp;
            {/* /candidate */}
            <Button   style={{color:"#f37721",backgroundColor:"white", fontStyle:"italic"}} href="#">Evaluation</Button>
            {/* /evaluation/1 */}
            </Card>
          </Grid>
        </Grid>        
      </Grid>
    </Grid>
      </div>} 
      drawerIndex={0}
      />
  );
}
export default Dashboard
