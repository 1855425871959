import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { Typography } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Navigation from '../../Nav/Nav';
import * as api from '../../Api';
import BackDrop from '../Backdrop';

const columns = [
  { id: 'code', label: 'Sl\u00a0No.', minWidth: 100, align: 'left', },
  { id: 'name', label: 'User\u00a0Name', minWidth: 100, align: 'center', },
  { id: 'code', label: 'Role', minWidth: 100, align: 'center', },
  { id: 'code', label: 'Status', minWidth: 100, align: 'center', }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor: '#d9d9d9',
    height: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
  }
});

function UserList(props) {
  const [list, setList] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [batch_id, setBatchId] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleUsersList = async () => {
    setOpen(true)
    // Make a request for a user with a given ID
    await api.getUsers()
      .then(function (response) {
        // handle success 
        setList(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    setOpen(false)
  }

  useEffect(() => {
    handleUsersList()
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Navigation main={
      <>
        <BackDrop open={open} handleClose={() => { setOpen(false) }} />
        <Grid container direction="row" justify="flex-end" alignItems="center" style={{ marginBottom: '2%' }}>
          <Grid item xs="6" lg="10">
            <Typography variant="h4" align="left">
              Users
            </Typography>
          </Grid>
          <Grid item xs="6" lg="2">
            <Fab variant="extended" color="primary" style={{ color: "#f37721", backgroundColor: "white", fontWeight: "bold" }} aria-label="add" onClick={() => { history.push("/useradd") }}>
              <AddIcon className={classes.selected} />
              Add User
            </Fab>
          </Grid>
        </Grid>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className={classes.head}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell style={{ width: '20px' }} className={classes.head}></TableCell>
                  <TableCell style={{ width: '20px' }} className={classes.head}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.length > 0 ? list.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}  >
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">  {row.roles.map((out) => {
                        return (
                          out.role_name + " "
                        )
                      })}
                      </TableCell>
                      {row.isactive === true ?
                        <TableCell align="center">
                          <Chip
                            variant="outlined"
                            size="small"
                            label="Active"
                            style={{ color: "green", borderColor: "green" }}
                            onDelete
                            deleteIcon={<DoneIcon style={{ color: "green" }} />}
                          />
                        </TableCell>
                        :
                        <TableCell align="center">
                          <Chip
                            variant="outlined"
                            size="small"
                            label="Inactive"
                            style={{ color: "red", borderColor: "red" }}
                            onDelete
                            deleteIcon={<CancelIcon style={{ color: "red" }} />}
                          />
                        </TableCell>
                      }
                      <TableCell>
                        <Fab style={{ color: "#f37721", backgroundColor: "white" }} size="small" aria-label="edit" onClick={() => { history.push({ pathname: `/userview/${row.id}`, query: { user: row } }) }}>
                          <VisibilityIcon />
                        </Fab>
                      </TableCell>
                      <TableCell>
                        <Fab style={{ color: "black", backgroundColor: "white" }} size="small" aria-label="edit" onClick={() => { history.push({ pathname: `/useredit/${row.id}`, query: { user: row } }) }}>
                          <EditIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  );
                }) : <TableCell colSpan="8" align="center" style={{ color: 'red' }}>  <h5>No Records Found</h5> </TableCell>}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    } />
  );
}
export default UserList
