import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';  
import { useHistory } from "react-router-dom";
import { useState , useEffect } from 'react';
import * as api from '../../Api';
import { Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { TextField } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 100,
  },
   container: {
    maxHeight: 440,
  },
  listItem: {
    marginTop: 20,
    width: '95%',
    backgroundColor: '#fff',
    borderRadius: 5,
  },
    title: { 
    paddingTop: "12px",
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',    
  },
  gridwidth: {
    marginTop: '2%',
    width: '85%',
    marginLeft: '8%',
    marginBottom: '2%',
  },
 heading: {
  fontWeight: 'bold',
 }
});

function UserWrittenTest() {
  const classes = useStyles(); 
  const [search, setSearch] = useState("");  
  const [list, setList] = useState([]);
  const location_id=sessionStorage.getItem("location_id")
  const filterData = (data) =>{
  let lower = search !== "" && search.toLowerCase()
  if(search !== "" && data.candidate_name)
  {
    return (
      (data.candidate_name.toLowerCase()).includes(lower)
    )
  }
  else{
    return true
  }
}
let row = []

  useEffect(() => {
    // Make a request for a user with a given ID
    api.getCandidatesList(location_id) 
   .then(function (response) {
     // handle success
      setList(response.data);
   })
   .catch(function (error) {
     // handle error
     console.log(error);
   })
  }, []);
  
  list.length>0 && list.filter(newlist => filterData(newlist)).map(e =>{ row.push(e)})
 
  const writtenlistHandler = (participant) => { 
    window.location.href = null? `/Writtenlist` : `/Writtenlist/${participant}`;
  }
   
  return (
    <Grid container justify="center"
    alignItems="center" spacing={2}> 
        <Grid  item xs={12} lg={12}>
          <Grid container spacing={2} justify= "center" > 
           <Grid item className={classes.listItem} id= "grid">   
           <Grid className={classes.gridwidth}>
             <Grid item xs={12} lg={12} container direction="row" align="center" justify="space-between">
             <Typography className={classes.title} style={{color:"#f37721"}}>
                   Written Test List
               </Typography>  
               <Typography> 
               <TextField  
                  type='text'
                  variant="outlined"
                  margin="dense"  
                  InputProps={{
                    endAdornment: <InputAdornment>{search && <HighlightOffIcon onClick={()=>{setSearch("")}} style={{cursor:"pointer"}} />}</InputAdornment>,
                  }} 
                  label="Search Name"
                  value={search}
                  onChange={(e)=>{setSearch(e.target.value);console.log(e.target.value)}}
                  /> 
                  </Typography>
                  </Grid>
                  <br/>
                <Paper className={classes.root}>   
                <TableContainer component={Paper} id="table">
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead  style={{ backgroundColor: '#eff3f5'}}>
                      <TableRow >
                        <TableCell style={{ fontWeight: 'bold' }}># </TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold'}}>Participant Name</TableCell> 
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>Evaluation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody > 
                      {row.length>0 ? row.map((row,i) => (
                        <TableRow key={row.id} >
                          <TableCell component="th" scope="row" >
                            {i+1}
                          </TableCell>
                          <TableCell align="center">{row.candidate_name}</TableCell> 
                          <TableCell  align="right"> 
                            <Fab style={{color:"#f37721",backgroundColor:"white"}} size="small" aria-label="edit" onClick={(value)=>writtenlistHandler(row.id)}>
                              <EditIcon />
                           </Fab>
                            </TableCell>
                        </TableRow> 
                      )):  <TableCell colSpan="3" align="center" style={{ color:'red' }}>    <h5>No Data Found</h5></TableCell>}
                      </TableBody>
                  </Table>
                </TableContainer>
                </Paper>
              </Grid>                        
           </Grid>
          </Grid>
        </Grid>   
      </Grid>
  );
} 
export default UserWrittenTest

