import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import Navigation from '../../Nav/Nav';
import * as api from '../../Api'; 
import BackDrop from '../Backdrop';
 
const column = [
    {
        id: 'score',
        label: 'Score', 
        align: 'left', 
    },
    {
        id: 0 ,
        label: '0', 
        align: 'center'
    },
    {
        id: 0.25 ,
        label: '0.25', 
        align: 'center'
    }, 
    {
        id: 0.5 ,
        label: '0.5', 
        align: 'center'
    }, 
    {
        id: 0.75 ,
        label: '0.75', 
        align: 'center'
    }, 
    {
        id: 1 ,
        label: '1', 
        align: 'center'
    },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardroot: {
    minWidth: 275,
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function PolicyView(props) {
  const localRole = sessionStorage.getItem("user_role_id")
  const policyQuery = props.location.query;
  { !policyQuery && (window.location.href = "/scholarship") }
  const policy = policyQuery.policy;  
  const policyId = policy.id; 
  const classes = useStyles();
  const history = useHistory(); 
  const [Open, setOpen] = useState();
  const [AnnualListOne, setAnnualListOne] = useState([]);
  const [AnnualListTwo, setAnnualListTwo] = useState([]);
  const [DependentList, setDependentList] = useState([]);
  const [LivestockList, setLivestockList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [InfraList, setInfraList] = useState([]);
  const [LandList, setLandList] = useState([]);
  const [IncomeEarnList, setIncomeEarnList] = useState([]);
  const [IncomeSourceList, setIncomeSourceList] = useState([]);
  const [AgrIncomeList, setAgrIncomeList] = useState([]); 
  const [AllData, setAllData] = useState([])
  const [AllDataLand, setAllDataLand] = useState([])
  const [AllDataInfra, setAllDataInfra] = useState([])
  const [AllDataIncomeSource, setAllDataIncomeSource] = useState([])
  const [AllDataIncomeAgr, setAllDataIncomeAgr] = useState([])
  const [AllDataVechile, setAllDataVechile] = useState([])
  const [AllDataLivestock, setAllDataLivestock] = useState([])
  const [AllDataDependent, setAllDataDependent] = useState([])
  const [AllDataAnnualOne, setAllDataAnnualOne] = useState([])
  const [AllDataAnnualTwo, setAllDataAnnualTwo] = useState([])
  
  const getAnnualListOne = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getAnnualIncomeDetail(PolicyId,2)
   .then(function (response) {
    // handle success
    if(response.data){
        setAnnualListOne(response.data); 
    let object = {score:"Annual Income Tier 2 Location ",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        { 
            object[row.score] = row.income
        }

  })
  setAllDataAnnualOne([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

const getAnnualListtwo = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
      setOpen(true)
 await api.getAnnualIncomeDetail(PolicyId,3)
   .then(function (response) {
    // handle success
    if(response.data){
        setAnnualListTwo(response.data); 
    let object = {score:"Annual Income Tier 3 Location ",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        { 
            object[row.score] = row.income
        }

  })
  setAllDataAnnualTwo([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

  const getDependentList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getDependentsDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
        setDependentList(response.data); 
    let object = {score:"No. of Dependents ",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        { 
            object[row.score] = row.no_of_dependents
        }

  })
  setAllDataDependent([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

  const getLivestockList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getLivestockDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
        setLivestockList(response.data); 
    let object = {score:"Cattle/ large livestock ",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        {
            object[row.score] = row.no_of_livestock
        }

  })
  setAllDataLivestock([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

  const getVehicleList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getVehiclesDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
        setVehicleList(response.data); 
    let object = {score:"Households Vehicles",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        {
            object[row.score] = row.vehicles_owned
        }

  })
  setAllDataVechile([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}
  
  const getInfraList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getHouseInfrastructureDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
        setInfraList(response.data); 
    let object = {score:"Infrastructure",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        {
            object[row.score] = row.specification
        }

  })
  setAllDataInfra([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

  const getLandList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getLandHoldingsDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
    setLandList(response.data); 
    let object = {score:"Landholdings",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        {
            object[row.score] = row.no_of_acres
        }

  })
  setAllDataLand([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

  const getIncomeEarnList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getEarningMembersDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
    setIncomeEarnList(response.data); 
    let object = {score:"No of Earning Members",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        {
            object[row.score] = row.no_of_earning_members
        }

  })
  setAllData([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

const getIncomeSourceList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getIncomeSourceDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
    setIncomeSourceList(response.data); 
    let object = {score:"Income Sources",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        {
            object[row.score] = row.source
        }

  })
  setAllDataIncomeSource([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
}

const getAgrIncomeList = async(PolicyId) => {
    //need to send tier also 
    if(PolicyId){
 await api.getAgriculturalDetail(PolicyId)
   .then(function (response) {
    // handle success
    if(response.data){
    setAgrIncomeList(response.data); 
    let object = {score:"Agricultural Income",0:"-",0.25:"-",0.5:"-",0.75:"-",1:"-"} 
    response.data.map((row)=>{
        if(row.score <= 1)
        {
            object[row.score] = row.yield
        }

  })
  setAllDataIncomeAgr([object])
   }})
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
  setOpen(false)
}





  useEffect(() => {   
    getIncomeEarnList(policy.id) 
    getLandList(policy.id)
    getInfraList(policy.id)
    getIncomeSourceList(policy.id)
    getAgrIncomeList(policy.id)
    getVehicleList(policy.id)
    getLivestockList(policy.id)
    getDependentList(policy.id)
    getAnnualListOne(policy.id)
    getAnnualListtwo(policy.id)
}, []);


  return (
    <Navigation main={
      <div className={classes.root}>
        <main className={classes.content}>
        <BackDrop open={Open} handleClose={() => { setOpen(false) }} />
          <div className={classes.drawerHeader} />
          <Grid container justify="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={12}>
               
              <Card className={
                                    classes.classroot
                                }>
                                    <CardContent>
                                        <Typography className={
                                            classes.title
                                        }
                                            variant="h5"
                                            style={
                                                { color: "black" }
                                            }
                                            color="textSecondary"
                                            gutterBottom>
                                            Policy Details
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            <TableContainer component={Paper}>
                                                <Table className={
                                                    classes.table
                                                }
                                                    aria-label="spanning table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan="4"></TableCell>
                                                            <TableCell align="right" style={
                                                                { fontWeight: 'bold' }
                                                            }>Name<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell width="300px"  align="left"> 
                                                            {policy.name}
                                                            </TableCell>
                                                        </TableRow>  
                                                        <TableRow> 
                                                        <TableCell colSpan="4"></TableCell>
                                                            <TableCell align="right" style={
                                                                { fontWeight: 'bold' }
                                                            }>Effective Date<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"> 
                                                                {policy.effective_date && new Date(policy.effective_date).toLocaleDateString() }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow> 
                                                        <TableCell colSpan="4"></TableCell>   
                                                            <TableCell align="right" style={
                                                                { fontWeight: 'bold' }
                                                            }>Status<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left" > 
                                                            {policy.active ?
                              <TableCell  >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label="Active"
                                  style={{ color: "green", borderColor: "green" }}
                                  onDelete
                                  deleteIcon={<DoneIcon style={{ color: "green" }} />}
                                />
                              </TableCell>
                              :
                              <TableCell  >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label="Inactive"
                                  style={{ color: "red", borderColor: "red" }}
                                  onDelete
                                  deleteIcon={<CancelIcon style={{ color: "red" }} />}
                                />
                              </TableCell>
                            }
                                                            </TableCell>
                                                        </TableRow>
                                                       
                                                        <TableRow>
                                                            {/* <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Education<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"><TextField fullWidth
                                                                value={education}
                                                                onChange={
                                                                    (e) => setEducation(e.target.value)
                                                                }
                                                                lable="Education" />
                                                            </TableCell> */}
                                                            <TableCell colSpan="6">
                                                            <Table> 
                                                                <TableRow style={{backgroundColor:"#EEEDE7"}}> {
                                                                        column.map((column) => (
                                                                            <TableCell  key={
                                                                                column.id
                                                                            }
                                                                                className={classes.head}
                                                                                align={
                                                                                    column.align
                                                                                }
                                                                                style={
                                                                                    {
                                                                                        color:"#f37721",fontWeight:'bold',fontSize:'20px'
                                                                                    }
                                                                                }>
                                                                                {
                                                                                    column.label
                                                                                } </TableCell>
                                                                        ))
                                                                    } 
                                                                    </TableRow>
 
                                                            {AllDataAnnualOne.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })}
                                                            {AllDataAnnualTwo.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })}
                                                            {AllDataDependent.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 
                                                            {AllDataLivestock.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 

                                                            {AllDataVechile.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 

                                                            {AllDataInfra.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 
 
                                                            {AllDataLand.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 
                                                                {AllData.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 

                                                                {AllDataIncomeSource.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 

                                                                {AllDataIncomeAgr.map((row) =>{
                                                                    return(
                                                                     <TableRow>
                                                                         {column.map((col,i) =>{ 
                                                                             return( 
                                                                         <TableCell>{row[col.id]}</TableCell>)
                                                                    
                                                                })}
                                                                </TableRow>)
                                                            })} 

                                                                     

                                                            </Table>
                                                            </TableCell>
                                                        </TableRow> 
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Typography>
                                    </CardContent>
                                    
                                    <Button margin="normal" variant="contained"
                                        style={
                                            { backgroundColor: "#d9d9d9" }
                                        }
                                        href="/scholarship"
                                        color='default'>Back</Button>
                                    <CardActions></CardActions>
                                </Card>
            </Grid></Grid>
        </main>
      </div>}
      drawerIndex={11}
    />
  );
}
export default PolicyView
