import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import * as api from '../Api';
import {useEffect} from 'react';

const columns = [
    {
        id: 'no',
        label: 'Sl\u00a0No.',
        minWidth: 10,
        align: 'left'
    },
    {
        id: 'name',
        label: 'User\u00a0name',
        minWidth: 170,
        align: 'center'
    },
    {
        id: 'written',
        label: 'Written\u00a0Test',
        minWidth: 50,
        align: 'center'
    },
    {
        id: 'group',
        label: 'Group\u00a0Discussion',
        minWidth: 50,
        align: 'center'
    }, {
        id: 'personal',
        label: 'Personal\u00a0Interview',
        minWidth: 50,
        align: 'center'
    }, {
        id: 'bussiness',
        label: 'Bussiness\u00a0Challenge',
        minWidth: 50,
        align: 'center'
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },
    title: {
        color: theme.palette.primary.light
    },
    titleBar: {
        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
    },
    cardroot: {
        minWidth: 275
    },
    hide: {
        display: 'none'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    }
}));

export default function ScrollDialog(props) {
    const classes = useStyles();
    const [list, setList] = React.useState([]);
    const [scroll, setScroll] = React.useState('paper');

    useEffect(() => {
        if (props.selected) {
            api.getScoreByUsers(props.selected).then(function (response) { // handle success
                setList(response.data);
            }).catch(function (error) { // handle error
                console.log(error);
            })
        }
    }, [props.selected]);

    return (
        <div>
            <Dialog open={
                    props.open
                }
                maxWidth="lg"
                onClose={
                    props.close
                }
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle id="scroll-dialog-title">
                    <Typography variant="h5" align="left">
                        Score Records of {
                        props.name
                    } </Typography>
                </DialogTitle>
                <DialogContent dividers={
                    scroll === 'paper'
                }>
                    <DialogContentText id="description"
                        tabIndex={-1}>
                        <Typography variant="body2" component="p">
                            <TableContainer component={Paper}>
                                <Table className={
                                        classes.table
                                    }
                                    aria-label="spanning table">
                                    <TableHead>
                                        <TableRow> {
                                            columns.map((column) => (
                                                <TableCell className={
                                                        classes.head
                                                    }
                                                    key={
                                                        column.id
                                                    }
                                                    align={
                                                        column.align
                                                    }
                                                    style={
                                                        {
                                                            minWidth: column.minWidth,
                                                            fontWeight: 'bold'
                                                        }
                                                }>
                                                    {
                                                    column.label
                                                } </TableCell>
                                            ))
                                        } </TableRow>
                                    </TableHead>
                                    {
                                    list.length > 0 && list.map((row, i) => {
                                        return (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {
                                                        i + 1
                                                    }</TableCell>
                                                    <TableCell align="center">
                                                        {
                                                        row.user_name
                                                    }</TableCell>
                                                    <TableCell align="center">
                                                        {
                                                        row.assessment_scores.Written >= 0 ? row.assessment_scores.Written : "-"
                                                    }</TableCell>
                                                    <TableCell align="center">
                                                        {
                                                        row.assessment_scores.Group_Discussion >= 0 ? row.assessment_scores.Group_Discussion : "-"
                                                    }</TableCell>
                                                    <TableCell align="center">
                                                        {
                                                        row.assessment_scores.Personal_Interview >= 0 ? row.assessment_scores.Personal_Interview : "-"
                                                    }</TableCell>
                                                    <TableCell align="center">
                                                        {
                                                        row.assessment_scores.Business_Challenge >= 0 ? row.assessment_scores.Business_Challenge : "-"
                                                    }</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                    })
                                } </Table>
                            </TableContainer>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                            props.close
                        }
                        color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
