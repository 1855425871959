import React from 'react';
import { useState , useEffect } from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    TextField
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'; 
import { Select } from '@material-ui/core';
import { Redirect } from 'react-router';
import Navigation from '../../Nav/Nav';
import { useHistory } from 'react-router-dom'; 
import * as api from '../../Api'; 
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    cardroot: {
        minWidth: 275
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    }
}));

export default function CandidatePolicyAdd() {
    const classes = useStyles();
    const history = useHistory();
    const [location, setLocation] = useState(sessionStorage.getItem("location_id")); 
    const [PassedCandidates , setPassedCandidates] = useState([]);
    const [PolicyList, setPolicyList] = useState([]);
    const [AnnualList, setAnnualList] = useState([]);
    const [DependentList, setDependentList] = useState([]);
    const [LivestockList, setLivestockList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);
    const [InfraList, setInfraList] = useState([]);
    const [LandList, setLandList] = useState([]);
    const [IncomeEarnList, setIncomeEarnList] = useState([]);
    const [IncomeSourceList, setIncomeSourceList] = useState([]);
    const [AgrIncomeList, setAgrIncomeList] = useState([]); 
    const [State , setState] = useState(
        {
            dependent_score_id: "",
            candidate_id: "",
            location_tier_id: "",
            annual_income_score_id: "",
            livestock_score_id: "",
            vehicle_score_id: "",
            earning_score_id: "",
            infrastructure_score_id: "",
            land_score_id: "",
            income_score_id: "",
            agricultural_score_id: "",
            requested_scholarship:"",
            policy_detail_id: "" 
        }
    )
    const [Open, setOpen] = useState(false); 
    const [isAddBatch, setAddBatch] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpen(true); 
       api.addMeanScores(State)
                .then(response => {
                    toast.success('Candidate Successfully Created!', {
                        position: "bottom-center",
                        autoClose: 3000,
                        onClose: () => { setAddBatch(true)},
                        hideProgressBar: false,
                        closeOnClick: true,
                    });
                    setOpen(false)
                }).catch(error => {
                    setOpen(false)
                    console.error(error)
                    toast.error('Error in Connection!', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    });
                })
       
    };

    const getCandidatePassed = () => {
        api.getUnallottedScholarshipCandidates(location)
         .then(function (response) {
          // handle success
          if(response.data){
          setPassedCandidates(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getPolicyList = () => {
        api.getActivePolicyDetails()
         .then(function (response) {
          // handle success
          if(response.data){
          setPolicyList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getAnnualList = (SelectedTier) => { 
        api.getAnnualIncomeDetail(State.policy_detail_id,SelectedTier)
         .then(function (response) {
          // handle success
          if(response.data){
          setAnnualList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getDependentList = (PolicyId) => { 
      api.getDependentsDetail(PolicyId)
       .then(function (response) {
        // handle success
        if(response.data){
        setDependentList(response.data);  
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    }

    const getLivestockList = (PolicyId) => {  
      api.getLivestockDetail(PolicyId)
       .then(function (response) {
        // handle success
        if(response.data){
        setLivestockList(response.data);  
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    }

    const getVehicleList = (PolicyId) => {  
        api.getVehiclesDetail(PolicyId)
         .then(function (response) {
          // handle success
          if(response.data){
          setVehicleList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getInfraList = (PolicyId) => {  
        api.getHouseInfrastructureDetail(PolicyId)
         .then(function (response) {
          // handle success
          if(response.data){
          setInfraList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getLandList = (PolicyId) => {  
        api.getLandHoldingsDetail(PolicyId)
         .then(function (response) {
          // handle success
          if(response.data){
          setLandList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getIncomeEarnList = (PolicyId) => {  
        api.getEarningMembersDetail(PolicyId)
         .then(function (response) {
          // handle success
          if(response.data){
          setIncomeEarnList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getIncomeSourceList = (PolicyId) => {  
        api.getIncomeSourceDetail(PolicyId)
         .then(function (response) {
          // handle success
          if(response.data){
          setIncomeSourceList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

      const getAgrIncomeList = (PolicyId) => {  
        api.getAgriculturalDetail(PolicyId)
         .then(function (response) {
          // handle success
          if(response.data){
          setAgrIncomeList(response.data);  
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      }

    const inputHandler = (event) =>{  
        if(event.target.value < 0)
        {  
            setState(prev => ({
                ...prev,
                requested_scholarship: 0
            })) 
        }
        else if(event.target.value > 100){  
            setState(prev => ({
                ...prev,
                requested_scholarship: 100
            }))    
        }
        else { 
            if((event.target.value >=0) && (event.target.value <=100))
            {
                setState(prev => ({
                    ...prev,
                    requested_scholarship: event.target.value
                }))
            }   
        }
    }

      

    useEffect(() => {   
        getCandidatePassed()
        getPolicyList() 
    }, []);

    return (
        <Navigation main={
            <div className={
                classes.root
            }>
                <main className={
                    classes.content
                }><ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <BackDrop open={Open} handleClose={() => { setOpen(false) }} />
                    <Grid container direction="row" justify="flex-end" alignItems="center"
                        style={
                            { marginBottom: '2%' }
                        }> </Grid>
                    <Grid container justify="center" alignItems="center">
                        <Grid item
                            xs={12}
                            lg={6}>
                            <form className="form"
                                 onSubmit={handleSubmit}
                                >
                                <Card className={
                                    classes.classroot
                                }>
                                    <CardContent>
                                        <Typography className={
                                            classes.title
                                        }
                                            variant="h5"
                                            style={
                                                { color: "black" }
                                            }
                                            color="textSecondary"
                                            gutterBottom>
                                            Candidate Scholarship Details
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            <TableContainer component={Paper}>
                                                <Table className={
                                                    classes.table
                                                }
                                                    aria-label="spanning table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Name<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Select native fullWidth
                                                                    value={State.candidate_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                candidate_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Candidate</option>
                                                                    {PassedCandidates.length>0 ? PassedCandidates.map((row)=>(
                                                                             <option value={row.id}>{row.candidate_name}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Scholarship Policy<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.policy_detail_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                policy_detail_id: e.target.value
                                                                            })) 
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                location_tier_id: ""
                                                                            })) 
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                annual_income_score_id: ""
                                                                            })) 
                                                                            getDependentList(e.target.value)
                                                                            getLivestockList(e.target.value)
                                                                            getVehicleList(e.target.value)
                                                                            getInfraList(e.target.value)
                                                                            getLandList(e.target.value)
                                                                            getIncomeEarnList(e.target.value)
                                                                            getIncomeSourceList(e.target.value)
                                                                            getAgrIncomeList(e.target.value)
                                                                           
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Policy</option>
                                                                    {PolicyList.length>0 ? PolicyList.map((row)=>(
                                                                             <option value={row.id}>{row.name}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>location<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Select native fullWidth
                                                                    value={State.location_tier_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                location_tier_id: e.target.value
                                                                            }))
                                                                            getAnnualList(e.target.value)
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Location Tier</option>
                                                                    <option value="2">Tier 2</option>
                                                                    <option value="3">Tier 3</option> 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Annual Income<spam style={
                                                                { color: 'red' }
                                                            }>*</spam></TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.annual_income_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                annual_income_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Income</option>
                                                                    {AnnualList.length>0 ? AnnualList.map((row)=>(  
                                                                            <option value={row.annual_income_score_id}>{row.income}</option>  
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>No. of Dependents<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.dependent_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                dependent_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Dependents</option>
                                                                    {DependentList.length>0 ? DependentList.map((row)=>(
                                                                             <option value={row.dependent_score_id}>{row.no_of_dependents}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Cattle / large livestock<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.livestock_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                livestock_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Households</option>
                                                                    {LivestockList.length>0 ? LivestockList.map((row)=>(
                                                                             <option value={row.livestock_score_id}>{row.no_of_livestock}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Owns vehicle<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.vehicle_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                vehicle_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Households</option>
                                                                    {vehicleList.length>0 ? vehicleList.map((row)=>(
                                                                             <option value={row.vehicle_score_id}>{row.vehicles_owned}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>House Infrastructure<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.infrastructure_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                infrastructure_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Infrastructure</option>
                                                                    {InfraList.length>0 ? InfraList.map((row)=>(
                                                                             <option value={row.infrastructure_score_id}>{row.specification}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Landholdings<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.land_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                land_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Landholdings</option>
                                                                    {LandList.length>0 ? LandList.map((row)=>(
                                                                             <option value={row.land_score_id}>{row.no_of_acres}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Income Earning Members<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.earning_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                earning_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Earning Members</option>
                                                                    {IncomeEarnList.length>0 ? IncomeEarnList.map((row)=>(
                                                                             <option value={row.earning_score_id}>{row.no_of_earning_members}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Income Sources<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.income_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                income_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Source</option>
                                                                    {IncomeSourceList.length>0 ? IncomeSourceList.map((row)=>(
                                                                             <option value={row.income_score_id}>{row.source}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Agricultural Income<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            <Select native fullWidth
                                                                    value={State.agricultural_score_id}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                            setState(prev => ({
                                                                                ...prev,
                                                                                agricultural_score_id: e.target.value
                                                                            }))
                                                                        }
                                                                    }>
                                                                    <option selected value=''>Select Source</option>
                                                                    {AgrIncomeList.length>0 ? AgrIncomeList.map((row)=>(
                                                                             <option value={row.agricultural_score_id}>{row.yield}</option>
                                                                    )):<option value="">No Records</option>} 
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={
                                                                { fontWeight: 'bold' }
                                                            }>Requested Scholarship<spam style={
                                                                { color: 'red' }
                                                            }>*</spam>
                                                            </TableCell>
                                                            <TableCell align="left"> 
                                                            <TextField 
                                                            fullWidth
                                                            required
                                                            type="number" 
                                                            id="numberfield" 
                                                            name="number"
                                                            inputProps={{ min: 0, max: 100 }}  
                                                            value={State.requested_scholarship}    
                                                            onChange={ (e) => {inputHandler(e)}}
                                                            /> 
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Typography>
                                    </CardContent>
                                    <Button margin="normal" variant="contained" type="submit"
                                        style={
                                            {
                                                color: "white",
                                                backgroundColor: "#f37721"
                                            }
                                        }
                                        color='default'>Submit</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button margin="normal" variant="contained"
                                        style={
                                            { backgroundColor: "#d9d9d9" }
                                        }
                                        href="/scholarship"
                                        color='default'>Cancel</Button>
                                    <CardActions></CardActions>
                                </Card>
                            </form>
                        </Grid>
                    </Grid>
                </main>
                <div> { 
                        isAddBatch ? <Redirect to={history.push({ pathname: `/scholarship` })
                        } /> : null
                     
                } </div>
            </div>} drawerIndex={11}/>
    );
}

