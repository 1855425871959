import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'; 
import * as api from '../../Api';
import { Link } from 'react-router-dom'; 
import {Grid} from '@material-ui/core'; 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Typography } from '@material-ui/core'; 
import Navigation from '../../Nav/Nav';
import ReactExport from 'react-data-export';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
 
const columns = [
    { id: 'code', label: 'Sl\u00a0No.', minWidth: 100 ,align: 'left', },
  { id: 'name', label: 'Name', minWidth: 170 ,align: 'center', },
  { id: 'code', label: 'Phone', minWidth: 100 ,align: 'center',}, 
  { id: 'code', label: 'Date\u00a0of\u00a0Birth', minWidth: 170 ,align: 'center',},   
  { id: 'code', label: 'Aadhar\u00a0Number', minWidth: 100 ,align: 'center',},
  { id: 'code', label: 'Village\u00a0Address', minWidth: 170 ,align: 'center',},
  { id: 'code', label: 'Education', minWidth: 100 ,align: 'center',},
  { id: 'code', label: 'Category', minWidth: 100 ,align: 'center',}
];
 
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor:'#d9d9d9',
    height: '50px', 
    fontWeight: 'bold',
    fontSize: '18px',
  }
});

 function CandidateList(props) { 
    const [viewNavigate,setViewNavigate] = useState(false)
  const [program, setProgram] = useState(window.location.href.split('/').pop(1))  
  if(program == "results"){
    setProgram(sessionStorage.getItem("location_id"))
    setViewNavigate(true)

  } 
   
  const [list, setList] = useState([]); 
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15); 
  const [batch_id,setBatchId]= useState(null) 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  const splitAadhar =(arr, len) => {
    let aadhar= ""
    var chunks =[], i = 0, n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    aadhar = chunks[0]+"-"+chunks[1]+"-"+chunks[2]
    return aadhar;
  }
   
 
      const DataSet = [
        {
            columns: [
                {title: "Sl.No", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true },fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 50}}, // width in pixels
                {title: "Name", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 30}}, // width in characters
                {title: "Phone", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 100}}, // width in pixels
                {title: "Date of Birth", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 125}}, // width in pixels
                {title: "Aadhar Number", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 150}}, // width in pixels
                {title: "Village Address", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 30}}, // width in pixels
                {title: "Education", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wch: 15}}, // width in characters
                {title: "Category", style: {alignment:{horizontal: "center"},font: {sz: "18", bold: true},fill: {patternType: "solid", fgColor: {rgb: "D9D9D9"}}}, width: {wpx: 110}}, // width in pixels  
            ],
            data: list.map((row,i) => [
                {value: i+1, style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
                {value: row.candidate_name, style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
                {value: row.phone_number  ? row.phone_number : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
                {value: row.dob ? new Date(row.dob).toLocaleDateString() : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
                {value: row.government_id ? splitAadhar(row.government_id,4) : "",  style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
                {value: row.village_location ? row.village_location : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
                {value: row.education ? row.education : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}},
                {value: row.category ? row.category : "", style: {alignment:{horizontal: "center"},font: {sz: "14"}}}, 
            ])
        }
      ] 
 
    useEffect(() => {
     // Make a request for a user with a given ID
     api.getCandidatesList(program) 
    .then(function (response) {
      // handle success 
      setList(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
   }, []);
 
  useEffect(()=>{
    setBatchId(sessionStorage.getItem("batch_id"))
   },[])
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
   
  return (
    <Navigation main={
    (program) ?
    <>
    <Grid container  direction="row"  justify="flex-end" alignItems="center" style={{ marginBottom: '2%'}}> 
    <Grid item xs="6" lg="10">
        {viewNavigate ? 
    <Breadcrumbs separator={
                        <NavigateNextIcon
                    fontSize="small"/>
                    }
                    aria-label="breadcrumb">
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/program">
                        {
                        sessionStorage.getItem("program_name")
                    }
                        ({
                        sessionStorage.getItem("program_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}}to="/batch">
                        {
                        sessionStorage.getItem("batch_name")
                    }
                        ({
                        sessionStorage.getItem("batch_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/location">
                        {
                        sessionStorage.getItem("location_name")
                    }
                        ({
                        sessionStorage.getItem("location_code")
                    })
                    </Link>
                    <Typography color="textPrimary">Candidate Report</Typography>
                </Breadcrumbs> :  
                 <Typography variant="h4" align="left">
            Candidate Report
          </Typography> }
      </Grid>
      <Grid item alignItems="center" xs="6" lg="2"> 
  <ExcelFile 
    filename={`CandidateReport${new Date().toLocaleString()}`} 
    element={
    <Button style={{ color: "white" ,backgroundColor: "#f37721"  }}>Export
      </Button>
    }>
        <ExcelSheet dataSet={DataSet} name={`${new Date().toLocaleString()}`}/>
    </ExcelFile>
      </Grid> 
         </Grid>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))} 
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length>0 ? list.map((row,index) => {
              return (   
                 <TableRow hover    tabIndex={-1} key={row.id}  > 
                  <TableCell align="left">{index+1}</TableCell> 
                <TableCell align="center">{row.candidate_name}</TableCell>  
                  <TableCell align="center">
                    {row.phone_number ?  
                            row.phone_number  
                            : "-"
                        }
                  </TableCell>
                  <TableCell align="center">
                    {row.dob? 
                            row.dob && new Date(row.dob).toLocaleDateString()
                            : "-"
                        }
                  </TableCell>
                  <TableCell align="center">
                  {row.government_id? 
                            splitAadhar(row.government_id,4)
                            : "-"
                        }
                  </TableCell> 
                  <TableCell align="center"> 
                  {row.village_location? 
                            row.village_location
                            : "-"
                        }
                 </TableCell>
                 <TableCell align="center"> 
                  {row.education? 
                            row.education
                            : "-"
                        }
                 </TableCell>
                 <TableCell align="center"> 
                  {row.category? 
                            row.category
                            : "-"
                        }
                 </TableCell>
                </TableRow>              
              );
            }): <TableCell colSpan="8" align="center" style={{ color:'red' }}>  <h5>No Records Found</h5> </TableCell>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </>
    :
     <Paper className={classes.root}>
       <h5 style={{ color:'red' }}>Location is not Selected</h5>
     </Paper> }drawerIndex={9}/>
  );
}
export default CandidateList
