import React , { useEffect , useState }from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'; 
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab'; 
import EditIcon from '@material-ui/icons/Edit';
import * as api from '../../Api'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialog from '../Alert'; 
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 100,
  },
   container: {
    maxHeight: 440,
  },
  listItem: {
    marginTop: 20,
    width: '95%',
    backgroundColor: '#fff',
    borderRadius: 5,
  },
    title: {
    marginTop: 0,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    color:"#f37721" ,   
  },
  gridwidth: {
    marginTop: '2%',
    width: '85%',
    marginLeft: '8%',
    marginBottom: '2%',
  },
  fab:
  { marginTop : 25,
    marginLeft: '88%',
    width: '4%',
    height: '4%',
  },
  addgroup: {
    marginTop : 0,
    marginLeft: '85%',
    paddingRight: '5%',
  },
});
 
function GroupDiscussionList() {
  const localRole = sessionStorage.getItem("user_role_id") 
  const [open,setOpen]=useState(false)
  const [alert, setAlert]= useState(false)
  const [deleteId,setDeleteId]= useState("")
  const [selectId,setSelectId]= useState("")
  const [groupName,setGroupName]= useState("")
  const classes = useStyles(); 
  const [list, setList] = useState([]);
  const location_id=sessionStorage.getItem("location_id")

  const handelDelete = async() => {
     setAlert(false)
     setOpen(true)
     await api.deleteGroup(deleteId)  
    .then(response => {
      if(response.data){
       getData()
       setGroupName("")
       setDeleteId("") 
       toast.success('The Selected group is deleted!', {
        position: "bottom-center",
        autoClose: 3000, 
        hideProgressBar: false,
        closeOnClick: true, 
        }); 
      } 
  }).catch(error => {
    console.error(error)
    toast.error('Error in Connection!', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true, 
      });
  })
  setOpen(false) 
  }

  const getData =() =>{
    api.getGroupsList(location_id,2)
    .then(function (response) {
      // handle success 
      setList(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }

    useEffect(() => {
      // Make a request for a user with a given ID
    getData()
    }, []);
 
  return (
    <>
     <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                <BackDrop open={open} handleClose={()=>{setOpen(false)}}/>
<AlertDialog open={alert} close={()=>{setAlert(false)}} delete={handelDelete} message={`Are you sure you want to remove this ${groupName} ?`} />
{selectId != "" && 
<AlertDialog open={alert} close={()=>{setAlert(false);setSelectId("")}} delete={()=>{window.location.href= `/grouplistHandler/${selectId}`}} message={`Your scores will be recorded, Confirm your action ?`} />
}  
    <Grid container xs={12} lg={12}  spacing={2}> 
        <Grid item xs={12}> 
          <Grid container spacing={2} justify= "center"> 
           <Grid item xs={12} className={classes.listItem} id= "grid">  
            <Tooltip title="Add Group" aria-label="Add Group" className={classes.fab}>
                <a href= '/addgroup/2'>
                <Fab style={{color:"white",backgroundColor:"#f37721"}} >
                  <AddIcon />
                </Fab>
                </a>
            </Tooltip>
             <Grid className={classes.gridwidth}>
             <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Group Discussion Batch List
               </Typography>   
                <Paper className={classes.root}>   
                <TableContainer component={Paper} id="table" >
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead style={{ backgroundColor: '#eff3f5' }}>
                      <TableRow >
                        <TableCell style={{ fontWeight: 'bold' }} >#</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Group Name</TableCell>
                        {/* <TableCell align="right" style={{ fontWeight: 'bold' }}>Action</TableCell> */}
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Members</TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold',width:'20px' }}>Evaluate</TableCell>
                        {(localRole === '4' || localRole === '1' ) && <TableCell  align="right" style={{ fontWeight: 'bold',width:'20px' }}>Delete</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {list.length>0 ? list.map((row,i) => (
                        <TableRow key={row.id} >
                          <TableCell component="th" scope="row" >
                            {i+1}
                          </TableCell>
                          <TableCell align="center" >{row.group_name}</TableCell> 
                          <TableCell align="center" >
                          {row.group_candidates.length>0 ? row.group_candidates.map((name,i)=>(
                                 (i+1) == row.group_candidates.length ? name : name+", "
                            )): "No Memebers Added"
                          }
                          </TableCell> 
                          {/* <TableCell align="right" > <Button variant="contained" style={{ marginLeft: 5, fontWeight: 'bold'}} onClick={(value)=>handelDelete(row.id)} >Delete</Button></TableCell> */}
                          <TableCell align="right" >
                          <Fab style={{color:"#f37721",backgroundColor:"white"}} size="small" aria-label="edit" onClick={()=>{setAlert(true);setSelectId(row.id)}}>
                              <EditIcon />
                           </Fab>
                            </TableCell >
                            {(localRole === '4' || localRole === '1' ) && 
                            <TableCell align="right" >
                          <Fab style={{color:"#f37721",backgroundColor:"white"}} size="small" aria-label="edit"  onClick={()=>{setAlert(true);setDeleteId(row.id);setGroupName(row.group_name)}}>
                              <DeleteIcon />
                           </Fab>
                            </TableCell >
                            }
                        </TableRow>
                      )):<TableCell colSpan="3" align="center" style={{ color:'red' }}>    <h5>No Data Found</h5></TableCell>}
                      </TableBody>
                  </Table>
                </TableContainer>
                </Paper>    
              </Grid>                        
           </Grid>
          </Grid>
        </Grid>
      </Grid>
      </>
  );
}
export default GroupDiscussionList


