import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom'; 
import Navigation from '../../Nav/Nav';
import * as api from '../../Api'; 
import BackDrop from '../Backdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardroot: {
    minWidth: 275,
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function CandidatePolicyView(props) { 
  const candidateQuery = props.location.query;
  {!candidateQuery && (window.location.href = "/scholarship") }
  const candidate = candidateQuery.candidate; 
  const [Open, setOpen]= useState(false)
  const [List, setList] = useState([])
  const classes = useStyles();
  const history = useHistory(); 

  const getCandidateDetails = async() => {
    setOpen(true) 
    await api.getMeanScoreDetail(candidate.candidate_id)
     .then(function (response) {
      // handle success
      if(response.data){
      setList(response.data); 
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    }) 
    setOpen(false)
  }

  useEffect(() => { 
    getCandidateDetails()
}, []);

  return (
    <Navigation main={
      <div className={classes.root}>
        <main className={classes.content}>
        <BackDrop open={Open} handleClose={() => { setOpen(false) }} />
          <div className={classes.drawerHeader} />
          <Grid container justify="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={6}>
              <Card className={classes.classroot}>
                <CardContent>
                  <Typography variant="h5" className={classes.title} style={{ color: "black" }} gutterBottom>
                    Candidate Scholarship Details
                  </Typography>
                  <Typography variant="body2" component="p">
                    <TableContainer component={Paper}>
                      {List.map((row)=>(
                      <Table className={classes.table} aria-label="spanning table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell align="left">{row.candidate_name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Policy</TableCell>
                            <TableCell align="left">{row.policy_detail_name}</TableCell>
                          </TableRow> 
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Location Tier</TableCell>
                            <TableCell align="left">Tire {row.tier}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Annual Income</TableCell>
                            <TableCell align="left">{row.income}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>No. of Dependents</TableCell>
                            <TableCell align="left">{row.no_of_dependents}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Households own the cattle/ large livestock</TableCell>
                            <TableCell align="left">{row.no_of_livestock}</TableCell>
                          </TableRow> 
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Households owns vehicle</TableCell>
                            <TableCell align="left">{row.vehicles_owned}</TableCell>
                          </TableRow> <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>House infrastructure</TableCell>
                            <TableCell align="left">{row.specification}</TableCell>
                          </TableRow> <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Landholdings</TableCell>
                            <TableCell align="left">{row.no_of_acres}</TableCell>
                          </TableRow> <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>No. of Income Earning Members</TableCell>
                            <TableCell align="left">{row.no_of_earning_members}</TableCell>
                          </TableRow> <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Income Sources</TableCell>
                            <TableCell align="left">{row.source}</TableCell>
                          </TableRow> <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Agricultural Income</TableCell>
                            <TableCell align="left">{row.yield}</TableCell>
                          </TableRow> <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Requested Scholarship</TableCell>
                            <TableCell align="left">{row.requested_scholarship}</TableCell>
                          </TableRow>  
                        </TableBody>
                      </Table> 
                      ))
                      }
                      
                    </TableContainer>
                  </Typography>
                </CardContent>
                <Button margin="normal" style={{ backgroundColor: "#d9d9d9" }} variant="contained" onClick={() => { history.push({ pathname: `/scholarship`  }) }} color='default'>Back</Button> 
                <CardActions />
              </Card>
            </Grid></Grid>
        </main>
      </div>}
     drawerIndex={11}
    />
  );
}
export default CandidatePolicyView
