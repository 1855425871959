import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Grid} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {useHistory} from 'react-router-dom'; 
import {Link} from '@material-ui/core'
import {useState} from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {Typography} from '@material-ui/core'; 
import BackDrop from '../Backdrop';
import * as api from '../../Api';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import Navigation from '../../Nav/Nav';
import GetAppIcon from '@material-ui/icons/GetApp';

const columns = [
    {
        id: 'code',
        label: 'Sl.No',
        minWidth: 10,
        align: 'center'
    },
    {
        id: 'name',
        label: 'Name',
        minWidth: 100,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Photo',
        minWidth: 20,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Goverment ID',
        minWidth: 20,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Pass Book',
        minWidth: 20,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Offer Letter',
        minWidth: 20,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Background Information',
        minWidth: 20,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Registration Form',
        minWidth: 20,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Education Certificate',
        minWidth: 20,
        align: 'center'
    },
    {
        id: 'code',
        label: 'Deposit Amount',
        minWidth: 100,
        align: 'center'
    } ,
    {
        id: 'code',
        label: '',
        minWidth: 100,
        align: 'center'
    } 
];

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        maxHeight: 440
    },
    head: {
      backgroundColor:'#d9d9d9',
      height: '50px', 
      fontWeight: 'bold' ,
      fontSize: '18px' 
    }
});

function DocumentTable() { 
    const location=sessionStorage.getItem("location_id")
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState([]); 
    const [open, setOpen] = useState(false); 
    const location_id = sessionStorage.getItem("location_id");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        setOpen(true)
        api.getDocumentVerifyList(location)
         .then(function (response) {
          // handle success 
          setList(response.data); 
          setOpen(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
       
    }, []); 

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+ event.target.value);
        setPage(0);
    };

    return(
        <Navigation main={
        (location_id && sessionStorage.getItem('program_id') && sessionStorage.getItem('batch_id')) ? <>
     <BackDrop open={open} handleClose={()=>{setOpen(false)}}/>
        <Grid container direction="row" justify="flex-end" alignItems="center"
            style={
                {marginBottom: '2%'}
        }>
            <Grid item xs="6" lg="10">
                <Breadcrumbs separator={
                        <NavigateNextIcon
                    fontSize="small"/>
                    }
                    aria-label="breadcrumb">
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/program">
                        {
                        sessionStorage.getItem("program_name")
                    }
                        ({
                        sessionStorage.getItem("program_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}}to="/batch">
                        {
                        sessionStorage.getItem("batch_name")
                    }
                        ({
                        sessionStorage.getItem("batch_code")
                    })
                    </Link>
                    <Link style={{color:"#f37721",textDecoration:"none"}} to="/location">
                        {
                        sessionStorage.getItem("location_name")
                    }
                        ({
                        sessionStorage.getItem("location_code")
                    })
                    </Link>
                    <Typography color="textPrimary">Verification</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs="6" lg="2">
                <Fab variant="extended" style={{color:"#f37721",backgroundColor:"white",fontWeight:"bold"}} aria-label="add"
                      onClick={()=>{history.push({pathname: `/documentadd`})}}>
                    <AddIcon className={
                        classes.extendedIcon
                    }/>
                    Verify
                </Fab>
            </Grid>
        </Grid>
        <Paper className={
            classes.root
        }>
            <TableContainer className={
                classes.container
            }>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow> {
                            columns.map((column) => (
                                <TableCell key={
                                        column.id
                                    }
                                    className={classes.head}
                                    align={
                                        column.align
                                    }
                                    style={
                                        {
                                            minWidth: column.minWidth,
                                            fontWeight: 'bold'
                                        }
                                }>
                                    {
                                    column.label
                                } </TableCell>
                            ))
                        } </TableRow>
                    </TableHead>
                    <TableBody> {
                        list.length > 0 ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                            return (
                                <TableRow hover role="checkbox"
                                    tabIndex={-1}
                                    key={
                                        row.id
                                }>
                                    <TableCell align="center">
                                        {index+1}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.candidate_name}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.photo === true ? row.photo_doc ? <Link href={row.photo_doc} target="_blank" download><GetAppIcon style={{color:"#f37721"}}/></Link>:<CheckCircleIcon style={{color:"green"}}/> : <HighlightOffIcon style={{color:"red"}}/>}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.gov_id === true ? row.gov_id_doc ? <Link href={row.photo_doc} target="_blank" download><GetAppIcon style={{color:"#f37721"}}/></Link>:<CheckCircleIcon style={{color:"green"}}/> : <HighlightOffIcon style={{color:"red"}}/>}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.pass_book === true ? row.pass_book_doc ? <Link href={row.pass_book_doc} target="_blank" download><GetAppIcon style={{color:"#f37721"}}/></Link>: <CheckCircleIcon style={{color:"green"}}/> : <HighlightOffIcon style={{color:"red"}}/>}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.offer_letter === true ? row.offer_letter_doc ? <Link href={row.offer_letter_doc} target="_blank" download><GetAppIcon style={{color:"#f37721"}}/></Link>:<CheckCircleIcon style={{color:"green"}}/> : <HighlightOffIcon style={{color:"red"}}/>}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.background_information === true ? row.background_information_doc ? <Link href={row.background_information_doc} target="_blank" download><GetAppIcon style={{color:"#f37721"}}/></Link>:<CheckCircleIcon style={{color:"green"}}/> : <HighlightOffIcon style={{color:"red"}}/>}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.reg_form === true ? row.reg_form_doc ? <Link href={row.reg_form_doc} target="_blank" download><GetAppIcon style={{color:"#f37721"}}/></Link>:<CheckCircleIcon style={{color:"green"}}/> : <HighlightOffIcon style={{color:"red"}}/>}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.edu_cert === true ? row.edu_cert_doc ? <Link href={row.edu_cert_doc} target="_blank" download><GetAppIcon style={{color:"#f37721"}}/></Link>:<CheckCircleIcon style={{color:"green"}}/> : <HighlightOffIcon style={{color:"red"}}/>}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {row.deposit_amount === null ? "0" : row.deposit_amount}
                                    </TableCell>
                                    <TableCell>
                                            <Fab style={{color:"black",backgroundColor:"white"}} size="small" aria-label="edit" onClick={()=>{history.push({pathname: `/documentedit/${row.id}`, query: {document:row}} )}}  >
    
                                            <EditIcon />
                                            </Fab> 
                                    </TableCell> 
                                </TableRow>
                            );
                        }) : <TableCell colSpan="10" align="center"
                            style={
                                {color: 'red'}
                        }>
                            <h5>
                                No Records Found
                            </h5>
                        </TableCell>
                    } </TableBody>
                </Table>
            </TableContainer>
            <TablePagination rowsPerPageOptions={
                    [10, 25, 100]
                }
                component="div"
                count={
                    list.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}/>
        </Paper>
    </> : <Paper className={
        classes.root
    }>
        <h5 style={
            {color: 'red'}
        }>Location is not Selected</h5>
    </Paper>
        }  drawerIndex={8}/> 
    );
}   
export default DocumentTable

