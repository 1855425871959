import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import Navigation from '../../Nav/Nav';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardroot: {
    minWidth: 275,
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function UserView(props) {
  const userQuery = props.location.query;
  { !userQuery && (window.location.href = "/users") } 
  console.log(userQuery)
  const user = userQuery.user;
  const classes = useStyles();
  const history = useHistory();

  return (
    <Navigation main={
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.drawerHeader} />
          <Grid container justify="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={6}>
              <Card className={classes.classroot}>
                <CardContent>
                  <Typography variant="h5" className={classes.title} style={{ color: "black" }} gutterBottom>
                    User Details
                  </Typography>
                  <Typography variant="body2" component="p">
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="spanning table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell align="left">{user.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Phone</TableCell>
                            <TableCell align="left">{user.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                            <TableCell align="left">{user.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Education</TableCell>
                            <TableCell align="left">{user.education}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Date of Joining</TableCell>
                            <TableCell align="left">{user.date_of_joining}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Date of Leaving</TableCell>
                            <TableCell align="left">{user.date_of_leaving}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                            <TableCell align="left">
                            {user.roles.map((out) => {
                        return (
                          "(" + out.role_name + " - " + out.location_name + ") "
                        )
                      })}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                            {user.isactive ?
                              <TableCell  >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label="Active"
                                  style={{ color: "green", borderColor: "green" }}
                                  onDelete
                                  deleteIcon={<DoneIcon style={{ color: "green" }} />}
                                />
                              </TableCell>
                              :
                              <TableCell  >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label="Inactive"
                                  style={{ color: "red", borderColor: "red" }}
                                  onDelete
                                  deleteIcon={<CancelIcon style={{ color: "red" }} />}
                                />
                              </TableCell>
                            }
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </CardContent>
                <Button margin="normal" style={{ backgroundColor: "#d9d9d9" }} variant="contained" onClick={() => { history.push({ pathname: `/users` }) }} color='default'>Back</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                <Button margin="normal" variant="contained" style={{ color: "white", backgroundColor: "#f37721" }} onClick={() => { history.push({ pathname: `/useredit`, query: { user: user } }) }} color='default'>Edit</Button>
                <CardActions />
              </Card>
            </Grid></Grid>
        </main>
      </div>}
      drawerIndex={2}
    />
  );
}
export default UserView
