import React, { useState , useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider'; 
import { useHistory } from "react-router-dom";
import * as api from '../../Api';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Navigation from '../../Nav/Nav';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 100,
  },
   container: {
    maxHeight: 440,
  },
  listItem: {
    marginTop: 35,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
  },
    title: {
    marginTop: 0,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'left',    
  },
  gridwidth: {
    marginTop: 5,
    width: '95%',
    marginLeft: '2%',
    marginBottom: '2%',
  },
 score: {
    marginTop: 10,
    marginLeft: '50%',
    marginBottom: '2%',
  },
  button: {
    textAlign: 'center',
    marginBottom: '2%',
  },
  tabletitle:{
    marginTop: 25,
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: '40%', 
    textAlign: 'left',    
  },
  evaluator: {
    width: '60%',
  },
  evaluatorcontainer: {
    marginTop: '2%',
    marginBottom: '2%',
  }, 
});
 
function BusinessList() {
  const classes = useStyles() 
  const group = window.location.href.split('/').pop()
  const program = sessionStorage.getItem("program_id")
  const [lists, setLists] = useState([])
  const [questionLists, setQuestionLists] = useState([])
  const [candidateLists, setCandidateLists] = useState([])
  const [totalscore, setTotalScore] = useState(0)
  const history = useHistory()

  const inputHandler = (id,event,max_score) =>{ 
    if(event.target.value < 0)
    { 
      event.target.value = 0 
      lists.map((row) => {
        if (parseInt(row.id) === parseInt(id)) {
          row.outcome = 0 
        }        
    })  
}
else if(event.target.value > max_score){ 
  event.target.value = max_score
  lists.map((row) => {
    if (parseInt(row.id) === parseInt(id)) {
      row.outcome = max_score
    }        
})
}
else {
  lists.map((row) => {
    if (parseInt(row.id) === parseInt(id)) {
      row.outcome = event.target.value 
    }        
})
} 
}

  const getTotalScores = (id) =>{
    let sumOfScore = 0
    lists.map((row) => {
      if (parseInt(row.candidate_id) === parseInt(id)) {
        sumOfScore += parseInt(row.outcome)
      } 
    }); 
    return sumOfScore
  }

  const handleSubmit=(inputScore,processId,candidateId,questionId)=>
  {
     
    let score = {
     "candidate_id" : candidateId,
     "program_question_id" : questionId,
     "user_id" : sessionStorage.getItem("user_id"),
     "outcome" : inputScore
     } 
       api.setScore(processId,score)
      .then(response => { 
          if(response.data) 
          { 
            document.getElementById(response.data.id).style.visibility="visible"
              getListData()
          }     
        }).catch(error => {
           })
  }

  const getListData = () => {
    api.getGroupScore(sessionStorage.getItem("user_id"),group,4)
     .then(function (response) {
      // handle success
      if(response.data){
      setLists(response.data);
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }

  useEffect(() => {
    // Make a request for a user with a given ID
    getListData()
    api.getGroupCandidates(group) 
   .then(function (response) {
     // handle success
     if(response.data){
     setCandidateLists(response.data);
     }
   })
   .catch(function (error) {
     // handle error
     console.log(error);
   })
   api.getQuestions(program,4)
    .then(function (response) {
     // handle success
     if(response.data)
     {
     setQuestionLists(response.data);
     }
   })
   .catch(function (error) {
     // handle error
     console.log(error);
   })
  }, []);

  const cancelClick = () => history.push('/evaluation/4')

  return (
    <Navigation main={
    <> 
    <Grid container  spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} justify= "center" > 
           <Grid item className={classes.listItem} id= "grid">   
           <Grid className={classes.gridwidth}>
             <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Business Challenges – Assessment 
               </Typography>  
               <Divider /> <br />
                <Paper className={classes.root}>   
                <TableContainer component={Paper} id="table">
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead style={{ backgroundColor: '#eff3f5'}}>
                      <TableRow >   
                        <TableCell  style={{ fontWeight: 'bold' }}>#</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Characteristic</TableCell>
                        <TableCell  style={{ fontWeight: 'bold' }}>Questions</TableCell>
                        {candidateLists.map((candidates)=>(
                          <TableCell  style={{ fontWeight: 'bold' }}>{candidates.candidate_name}</TableCell>
                        ))} 
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {questionLists.map((row,i) => ( 
                        <TableRow key={row.id} style={{cursor: 'pointer'}} id="tablerow">
                          <TableCell component="th" scope="row" >
                            {i+1}
                          </TableCell>
                          <TableCell >{row.characteristic}</TableCell>
                          <TableCell >{row.question}</TableCell>
                          {lists.map(answer=>{
                            if(row.id === answer.program_question_id)
                            {
                              return(
                              <TableCell key={answer.id} >
                              <input 
                                 type="number" 
                                 id="numberfield" 
                                 name="number" 
                                 min="1" 
                                 defaultValue = {answer.outcome}
                                 max={row.max_score} 
                                 style={{ width: 50, height: 20 }}  
                                onChange={(event)=> {inputHandler(answer.id,event,row.max_score)}} 
                                 onBlur={(event) => handleSubmit(event.target.value,answer.id,answer.candidate_id,row.id)}   
                               />&nbsp;/{row.max_score}&nbsp;
                               <CheckCircleIcon id={answer.id} style={{color:"green",visibility:"hidden"}}  />   
                              </TableCell>
                              )
                            } 
                          })} 
                        </TableRow>  
                      ))}
                       <TableRow > 
                         <TableCell colSpan="3" align="right"  style={{ fontWeight: 'bold' }}>Total Score</TableCell>
                          {candidateLists.map((candidate,i)=>{
                            return(
                              <TableCell  key={candidate.id}  >
                      {lists.length >0 && getTotalScores(candidate.id)}
                        </TableCell>
 ) })} 
                         </TableRow> 
                    </TableBody>  
                  </Table>
                  <br/>
                   <div className={classes.button} > 
                     <Button variant="contained" onClick={()=>{history.push("/evaluation/4")}} style={  {  color: "white", backgroundColor: "#f37721", marginLeft: 15, fontWeight: 'bold'   }  } href="#contained-buttons"  >
                        Save & Close
                      </Button>      
                    </div>
                </TableContainer>
                </Paper> 
               <Divider /> <br /> 
              </Grid>                        
           </Grid>
          </Grid>
        </Grid>
      </Grid>
      </>
    } drawerIndex={5}/>
  );
} 
export default BusinessList

