import { React , useState , useEffect } from 'react';
import { makeStyles , useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button'; 
import { useHistory } from "react-router-dom"; 
import * as api from '../../Api';  
import MobileStepper from '@material-ui/core/MobileStepper';  
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';   
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';  
import Navigation from '../../Nav/Nav';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  imageList: {
    flexWrap: 'nowrap', 
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  table: {
    minWidth: 100,
  },
   container: {
    maxHeight: 440,
  },
  listItem: {
    marginTop: 35,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
  },
    title: {
    marginTop: 0,
    fontSize: 16, 
    color:"black",
    textAlign: 'left',    
  },
  gridwidth: {
    marginTop: 5,
    width: '95%',
    marginLeft: '2%',
    marginBottom: '2%',
   
  }, 
  display: {
    marginLeft: '88%',
  },
  button: {
    textAlign: 'center',
    marginBottom: '2%',
  },
  tablecell:{
    display: 'flex'
  }
});

function WrittenList() {
  const classes = useStyles()
  const name = window.location.href.split('/').pop()
  const theme = useTheme();
  const [list,setList]=useState([]); 
  const [isValidImage, setIsValidImage]=useState(0) 
  const [activeStep, setActiveStep] = useState(0)  
  const history = useHistory() 
   
 useEffect(()=>{
   api.getImages(name) 
  .then(response =>{ 
  if(response.data.answer_sheets)
    {
      setIsValidImage(response.data.answer_sheets) 
    }
    }) 
 },[])
  
 const handleSubmit=(programId,inputScore,processId)=>
 {  
   let score = {
    "candidate_id" : name,
    "program_question_id" : programId,
    "user_id" : sessionStorage.getItem("user_id"),
    "outcome" : inputScore
  }
 api.setScore(processId,score)
 .then(response => { 
          if(response.data) 
          { 
            document.getElementById(response.data.id).style.visibility="visible" 
              getData() 
          }     
        }).catch(error => {
          toast.error('Error in Connection!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true, 
          });}) 
 }

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
}

const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
}

  const inputHandler = (id, event , max_score) =>{  
    if(event.target.value < 0)
    { 
      event.target.value = 0 
      list.map((row) => {
        if (parseInt(row.id) === parseInt(id)) {
          row.outcome = 0 
        }        
    })  
}
else if(event.target.value > max_score){ 
  event.target.value = max_score
  list.map((row) => {
    if (parseInt(row.id) === parseInt(id)) {
      row.outcome = max_score
    }        
})
}
else {
  list.map((row) => {
    if (parseInt(row.id) === parseInt(id)) {
      row.outcome = event.target.value 
    }        
})
}
}
 
const getTotalScores = (id) =>{
 let sumOfScore = 0
 list.map((row) => { 
    sumOfScore += row.outcome
})
return sumOfScore
}

const cancelClick = () => history.push('/WrittenList/'+name)

const getData = () =>{
  api.getScore(sessionStorage.getItem("user_id"),name,1)
    .then(function (response) {
     // handle success
     if(response.data)
     {
      setList(response.data); 
     } 
     //console.log(list)
   })
   .catch(function (error) {
     // handle error
     console.log(error);
   })
}

  useEffect(() => {
    // Make a request for a user with a given ID
   getData()
  }, []);

  return (
    <Navigation main={
            <> 
          <Grid container xs={12} lg={12} spacing={2} justify= "center" >   
          <Grid item xs={12} lg={6} className={classes.listItem} id= "grid">  
            <Grid className={classes.gridwidth}>
           <Typography className={classes.title}   gutterBottom>
             Answer Sheet  
               </Typography>  
               <Divider /> <br/>
               {isValidImage ?
               <>
           <img src={isValidImage[activeStep]} alt={isValidImage[activeStep]}  width="500px" height="600px" />
                <MobileStepper
       steps={isValidImage.length}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === isValidImage.length - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      /> 
       </> : <Typography>No Images</Typography> }
           </Grid>
           </Grid>
           <Grid item xs={12} lg={6} className={classes.listItem} id= "grid">   
           <Grid className={classes.gridwidth}> 
             <Typography className={classes.title} color="textSecondary" gutterBottom>
              Questions <span style={{fontSize:12}}>(Total Score = {list.length>0 ? getTotalScores(list.id):0})</span>
                </Typography>  
                <Divider /> <br />
                <Paper className={classes.root}>   
                <TableContainer component={Paper} id="table">
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead  style={{ backgroundColor: '#eff3f5'}}>
                      <TableRow >   
                        <TableCell style={{ fontWeight: 'bold' }}>SlNo</TableCell> 
                        <TableCell style={{ fontWeight: 'bold' }}>Question Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Question #</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {list.map((row,i) => (
                        <TableRow key={row.id} style={{cursor: 'pointer'}} id="tablerow">
                          <TableCell component="th" scope="row" >
                            {i+1}
                          </TableCell>
                          <TableCell >{row.characteristic}</TableCell>
                          <TableCell >{row.question}</TableCell>
                          <TableCell className={classes.tablecell}> 
                          <input 
                             type="number" 
                             id="numberfield" 
                             name="number" 
                             min="0"
                             max={row.max_score} 
                             defaultValue={row.outcome} 
                             style={{ width: 50, height: 20 }}   
                             onChange={(event) =>  inputHandler(row.id, event ,row.max_score)} 
                             onBlur={(e)=>handleSubmit(row.program_question_id,e.target.value,row.id)}
                           /> 
                            &nbsp;/{row.max_score}&nbsp;
                             <CheckCircleIcon id={row.id} style={{color:"green",visibility:"hidden"}}  />  
                          </TableCell>
                        </TableRow>
                      ))} 
                    </TableBody>
                  </Table>
                  <row className = {classes.display} style={{align:"center"}}  > 
                  <column className = {classes.score} style={{align:"center"}} > 
                   </column> 
                    </row>
                   <div className={classes.button} > 
                     <Button style={{color:"white",backgroundColor:"#f37721",marginLeft: 15, fontWeight: 'bold' }} onClick={()=>{history.push("/evaluation/1")}} >
                        Save & Close
                      </Button>  
                    </div>
                </TableContainer>        
                </Paper>
              </Grid>                        
           </Grid> 
      </Grid>
       </>
    } drawerIndex={5}/>
  );
} 
export default WrittenList

