import React, { useState }  from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow'; 
import TextField from '@material-ui/core/TextField';
import {Select} from '@material-ui/core';
import {Redirect} from 'react-router'; 
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../../Nav/Nav';
import { useHistory } from 'react-router-dom';
import * as api from '../../Api';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    cardroot: {
        minWidth: 275
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    }, 
    textInput: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1.8px',
            borderColor: "grey"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1.5px',
            borderColor: "#f37721"
        }
    }
}));

export default function LocationAdd() {
    const location = sessionStorage.getItem("batch_id")
    const classes = useStyles(); 
    const history = useHistory();
    const [locationCodeError, setLocationCodeError] = useState("")
    const [nameError, setNameError] = useState("")
    const [startDateError, setStartDateError] = useState("")
    const [cutOff, setCutOff] = useState("")
    const [open, setOpen] = useState(false);
    const [endDateError, setEndDateError] = useState("")
    const [isAddLocation, setAddLocation] = useState(false);
    const [locationObj, setLocationObj] = useState({
        "location_code": "",
        "location_name": "",
        "description": "",
        "start_date": "",
        "end_date": "",
        "isactive": "true",
        "cut_off": "",
        "batch_id": location
    })

    const createLocation = () => {
        if(locationObj.location_code === "" )
        {
           setLocationCodeError("Enter the Location Code")
        }
        else if(locationObj.location_name === "" )
        {
           setNameError("Enter the Location Name")
        }
        else if(locationObj.start_date === "" )
        {
           setStartDateError("Enter the Start Date")
        }
        else if(locationObj.end_date === "" )
        {
           setEndDateError("Enter the End Date")
        }
        else if(locationObj.cut_off === "" )
        {
           setCutOff("Enter the Cut Off Mark")
        }
        else
        { 
        setOpen(true)
            api.addLocation(locationObj)
            .then(response => {
            toast.success('Location Saved Successfully!', {
                position: "bottom-center",
                autoClose: 3000,
                onClose: ()=>{setAddLocation(true)},
                hideProgressBar: false,
                closeOnClick: true, 
                });  
                setOpen(false)     
        
        }).catch(error => {
            setOpen(false)
            console.error(error)
            toast.error('Error in Connection!', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true, 
                });
        })
        }
    }

    return (
        <Navigation main={
        <div className={
            classes.root
        }>
            <main className={
                classes.content
            }>
                 <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                <BackDrop open={open} handleClose={()=>{setOpen(false)}}/>
               
                <Grid container justify="center" alignItems="center">
                    <Grid item
                        xs={12}
                        lg={12}>
                        <Card className={
                            classes.classroot
                        }>
                            <CardContent>
                                <Typography className={
                                        classes.title
                                    }
                                    variant="h5" style={{color: "black"}}
                                    gutterBottom>
                                    Add Location
                                </Typography>
                                <Typography variant="body2" component="p">
                                    <TableContainer >
                                        <Table className={
                                                classes.table
                                            }
                                            aria-label="spanning table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width="150px" style={
                                                        {fontWeight: 'bold'}
                                                    }>Location Code<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField 
                                                            className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            error
                                                            helperText={locationCodeError} 
                                                            onInput={
                                                                e => {setLocationObj(prev => ({
                                                                    ...prev,
                                                                    location_code: e.target.value
                                                                }))
                                                                setLocationCodeError("")
                                                            }
                                                            }
                                                            lable="Location Code"/></TableCell>
                                                            <TableCell width="150px" style={
                                                        {fontWeight: 'bold'}
                                                    }>Location Name<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField 
                                                            className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            error
                                                            helperText={nameError} 
                                                            onInput={
                                                                e => {setLocationObj(prev => ({
                                                                    ...prev,
                                                                    location_name: e.target.value
                                                                }))
                                                                setNameError("")
                                                            }
                                                            }
                                                            lable="Location Name"/></TableCell>
                                                </TableRow>  
                                                <TableRow>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Start Date<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField 
                                                    className={
                                                        classes.textInput
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    error
                                                    helperText={startDateError} 
                                                    type='date'
                                                            onInput={
                                                                e => {setLocationObj(prev => ({
                                                                    ...prev,
                                                                    start_date: e.target.value
                                                                }))
                                                                setStartDateError("")
                                                            }
                                                            }/></TableCell> 
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>End Date<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField 
                                                    className={
                                                        classes.textInput
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    error
                                                    helperText={endDateError} 
                                                    type='date'
                                                            onInput={
                                                                e => {setLocationObj(prev => ({
                                                                    ...prev,
                                                                    end_date: e.target.value
                                                                }))
                                                                setEndDateError("")
                                                            }
                                                            }/></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Cut-Off Mark<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left"><TextField 
                                                            className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            error
                                                            helperText={cutOff} 
                                                            onInput={
                                                                e => {setLocationObj(prev => ({
                                                                    ...prev,
                                                                    cut_off: e.target.value
                                                                }))
                                                                setCutOff("")
                                                            }
                                                            }
                                                            lable="Cut-Off Mark"/></TableCell> 
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Status<spam style={
                                                            {color: 'red'}
                                                        }>*</spam>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Select native 
                                                            className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            onChange={
                                                                e => setLocationObj(prev => ({
                                                                    ...prev,
                                                                    isactive: e.target.value
                                                                }))
                                                        }> 
                                                            <option selected value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={
                                                        {fontWeight: 'bold'}
                                                    }>Description</TableCell>
                                                    <TableCell align="left" colSpan={3}><TextField 
                                                            className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            multiline
                                                            maxRows={4}
                                                            onInput={
                                                                e => setLocationObj(prev => ({
                                                                    ...prev,
                                                                    description: e.target.value
                                                                }))
                                                            }
                                                            lable="Location Description"/></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </CardContent> 
                               <Button margin="normal" variant="contained"
                                style={
                                    {
                                        color: "white",
                                        backgroundColor: "#f37721"
                                    }
                                }
                                onClick={
                                    (value) => createLocation()
                                }
                                color='default'>Submit</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button margin="normal" variant="contained"
                                style={
                                    {backgroundColor: "#d9d9d9"}
                                }
                                href="/location"
                                color='default'>Cancel</Button> 
                            <CardActions></CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </main>
            <div> {
                isAddLocation ? <Redirect to={history.push({pathname: `/location`}) 
                }/> : null
            } </div>
        </div>}
        drawerIndex={3}
        />
    );
}

