import React, { useEffect, useState } from 'react';  
import ReactExport from 'react-data-export';
import * as api from '../../Api';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


function Run ()  { 
    const [program, setProgram] = useState(window.location.href.split('/').pop(1))  
    const [exporData, setExportData] = useState([]); 


    useEffect(() => {
        // Make a request for a user with a given ID
        api.getCandidatesList(program) 
       .then(function (response) {
         // handle success 
         setExportData(response.data);
       })
       .catch(function (error) {
         // handle error
         console.log(error);
       })
      }, []);

    const override =`
        display: flex;
        align-items: center;
        justify-content: center;    
        border-color: red;
    `;

    const DataSet = [
        {
            columns: [
                {title: "Province State", style: {font: {sz: "18", bold: true}}, width: {wpx: 125}}, // width in pixels
                {title: "Country Region", style: {font: {sz: "18", bold: true}}, width: {wch: 30}}, // width in characters
                {title: "Confirmed", style: {font: {sz: "18", bold: true}}, width: {wpx: 100}}, // width in pixels
                {title: "Deaths", style: {font: {sz: "18", bold: true}}, width: {wpx: 125}}, // width in pixels
                {title: "Recovered", style: {font: {sz: "18", bold: true}}, width: {wpx: 100}}, // width in pixels
                {title: "Active", style: {font: {sz: "18", bold: true}}, width: {wpx: 125}}, // width in pixels
                {title: "Incident Rate", style: {font: {sz: "18", bold: true}}, width: {wch: 30}}, // width in characters
                {title: "Latitude", style: {font: {sz: "18", bold: true}}, width: {wpx: 125}}, // width in pixels
                {title: "Longitude", style: {font: {sz: "18", bold: true}}, width: {wpx: 125}}, // width in pixels
                {title: "Last Update", style: {font: {sz: "18", bold: true}}, width: {wpx: 110}}, // width in pixels
                
            ],
            data: exporData.map((data) => [
                {value: data.candidate_name, style: {font: {sz: "14"}}},
                {value: data.candidate_name, style: {font: {sz: "14"}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "3461eb"}}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "eb1207"}}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "4bd909"}}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "ebc907"}}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "35bdb4"}}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "ed14f5"}}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "ed14f5"}}}},
                {value: data.candidate_name, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "000000"}}}},
            ])
        }
    ]  
 
    return (
        <div className="container">
           <ExcelFile 
                         filename="Covid-19 Data" 
                         element={<button type="button" className="btn btn-success float-right m-3">Export Data</button>}>
                             <ExcelSheet dataSet={DataSet} name="Covid-19 Country Report"/>
                         </ExcelFile>
        </div>
    );
}

export default Run;