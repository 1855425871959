import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import Navigation from '../../Nav/Nav';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardroot: {
    minWidth: 275,
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function BatchView(props) {
  const localRole = sessionStorage.getItem("user_role_id")
  const batchQuery = props.location.query;
  { !batchQuery && (window.location.href = "/batch") }
  const batch = batchQuery.batch;
  const classes = useStyles();
  const history = useHistory();
  const [selectedProgram, setProgram] = React.useState({
    "id": batch['program_id']
  })

  return (
    <Navigation main={
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.drawerHeader} />
          <Grid container justify="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={6}>
              <Card className={classes.classroot}>
                <CardContent>
                  <Typography variant="h5" className={classes.title} style={{ color: "black" }} gutterBottom>
                    Batch Details
                  </Typography>
                  <Typography variant="body2" component="p">
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="spanning table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Batch Name</TableCell>
                            <TableCell align="left">{batch.batch_name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Batch Code</TableCell>
                            <TableCell align="left">{batch.batch_code}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Batch Description</TableCell>
                            <TableCell align="left">{batch.description}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Start Date</TableCell>
                            <TableCell align="left">{batch.start_date}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>End Date</TableCell>
                            <TableCell align="left">{batch.end_date}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>BatchStatus</TableCell>
                            {batch.isactive ?
                              <TableCell  >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label="Active"
                                  style={{ color: "green", borderColor: "green" }}
                                  onDelete
                                  deleteIcon={<DoneIcon style={{ color: "green" }} />}
                                />
                              </TableCell>
                              :
                              <TableCell  >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label="Inactive"
                                  style={{ color: "red", borderColor: "red" }}
                                  onDelete
                                  deleteIcon={<CancelIcon style={{ color: "red" }} />}
                                />
                              </TableCell>
                            }
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </CardContent>
                <Button margin="normal" style={{ backgroundColor: "#d9d9d9" }} variant="contained" onClick={() => { history.push({ pathname: `/batch/${batch['program_id']}`, query: { program: selectedProgram } }) }} color='default'>Back</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                {(localRole === '4') && <Button margin="normal" variant="contained" style={{ color: "white", backgroundColor: "#f37721" }} onClick={() => { history.push({ pathname: `/batchedit/${batch['id']}`, query: { batch: batch } }) }} color='default'>Edit</Button>}
                <CardActions />
              </Card>
            </Grid></Grid>
        </main>
      </div>}
      drawerIndex={2}
    />
  );
}
export default BatchView
