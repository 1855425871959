import{ React , useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ListIcon from '@material-ui/icons/List';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'; 
import DnsIcon from '@material-ui/icons/Dns'; 
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useHistory , BrowserRouter as Router } from 'react-router-dom'; 
import { Grid,Avatar } from '@material-ui/core'; 
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';  
import firebase from 'firebase'; 
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { Select } from '@material-ui/core';
import * as api from '../Api';
import SchoolIcon from '@material-ui/icons/School';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#f37721',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color:"white",
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  }, 
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: '4%',
    alignItems: 'center'
  },
  selected: { 
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#fef0e7"}
  }, 
}));

export default function Navigation(props) { 
  const phone = localStorage.getItem('number') 
  const [fetchRoles ,setFetchRoles] = useState(JSON.parse(localStorage.getItem('userdata'))) 
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [selectedRole, setSelectedRole] = useState(sessionStorage.getItem("user_role_id")+"/"+sessionStorage.getItem("location_id")) 
  const [open, setOpen] = useState(true); 
  const [anchorEl, setAnchorEl] = useState(null);
  let localRole = sessionStorage.getItem("user_role_id") 

 
   
  const handleDrawerOpen = () => { 
    setOpen(true)
    //localStorage.setItem("drawer",true)
  }; 
 
  const handleSelect= async(event) => {  
    sessionStorage.setItem("location_id", event.target.value.split('/')[1])
    sessionStorage.setItem("user_role_id", event.target.value.split('/')[0])
    await api.getPath(event.target.value.split('/')[1]) 
    .then(function (response) {
      // handle success   
      sessionStorage.setItem("batch_id",response.data[0].batch_id)
      sessionStorage.setItem("program_id",response.data[0].program_id )  
      sessionStorage.setItem("program_name", response.data[0].program_name )
      sessionStorage.setItem("location_name", response.data[0].location_name )
      sessionStorage.setItem("batch_name", response.data[0].batch_name )
      sessionStorage.setItem("program_code", response.data[0].program_code )
      sessionStorage.setItem("batch_code", response.data[0].batch_code )
      sessionStorage.setItem("location_code", response.data[0].location_code ) 
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    }) 
    window.location.reload() 
  }; 

  const handleClick = (event) => {
    console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => { 
    setOpen(false)
    //localStorage.setItem("drawer",false)
  }; 

  const logout = () => {   
    firebase.auth().signOut().then(() => {
      // Sign-out successful. 
      localStorage.clear() 
      sessionStorage.clear()
      history.push('/login')
    }).catch((error) => {
      alert("invaild access")
      // An error happened.
    });
  }
  
  const handleLogout = () => { 
    logout() 
  }
 
  return (
    <div className={classes.root}>
      <CssBaseline /> 
      <Router history={history}> 
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >  
         <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}>
            <MenuIcon />
          </IconButton>
          <Grid   xs={12} lg={12} container alignItems="center" direction="row" justify="space-between">
              <div>
              <Typography variant="h6" noWrap>
                                                    <Select native 
                                                        className={
                                                            classes.textInput
                                                        }
                                                        fullWidth 
                                                        variant="outlined"
                                                        value={selectedRole}
                                                        onChange={(event)=>{handleSelect(event)}}
                                                        margin="dense"   
                                                        >
                                                            {fetchRoles.roles && fetchRoles.roles.map((name,id) => {return(
                        <option key={id} value={name.role_id+"/"+name.location_id}>
                            {name.location_name ? `${name.location_name} - ${name.role}` : `${name.role}`}
                        </option>)}
                        )}  
                                                        </Select> 
                </Typography>
              </div>
              <div>
              <ListItem aria-controls="simple-menu"  aria-haspopup="true" onClick={handleClick} style={{cursor:"pointer"}} > 
                <Avatar alt="Profile Icon"   />
                &nbsp;
                <Typography variant="h6" noWrap>
                  {sessionStorage.getItem("user_name")}
                </Typography>
                </ListItem>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted  
                  open={Boolean(anchorEl)}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={handleClose}>
                   < MenuItem >{sessionStorage.getItem("user_role_id") == 4 ? `Central Team` 
                                :
                                sessionStorage.getItem("user_role_id") == 1 ? `Cluster Head`
                                :
                                sessionStorage.getItem("user_role_id") == 2 ? `Executive`
                                :
                                sessionStorage.getItem("user_role_id") == 3 && `Evaluator`
                                }</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                </div> 
          </Grid>
        </Toolbar>
      </AppBar> 
      <Drawer 
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
         <Typography variant="h6" style={{color:"#f37721 "}} >
                 ALC Learning 
          </Typography> 
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div> 
         <List >
          <ListItem className={classes.selected}  button selected={props.drawerIndex === 0}   onClick={() => history.push("/")}>
           <ListItemIcon > <HomeIcon style={{color:"#f37721"}}/></ListItemIcon>
              <ListItemText primary='Home' /> 
            </ListItem> 
            {(localRole === '4')  &&  
            <>
            <ListItem className={classes.selected}  button  selected={props.drawerIndex === 1}   onClick={() => history.push("/program")}>
              <ListItemIcon> <ListIcon style={{color:"#f37721"}}/></ListItemIcon>
              <ListItemText primary='Program' />
            </ListItem>  
             <ListItem className={classes.selected}  button selected={props.drawerIndex === 2} onClick={() => history.push("/batch")}>
              <ListItemIcon> <DnsIcon style={{color:"#f37721"}}/> </ListItemIcon>
              <ListItemText primary='Batch' />
            </ListItem> 
           <ListItem className={classes.selected}  button selected={props.drawerIndex === 3} onClick={() => history.push("/location")}>
            <ListItemIcon> <LocationOnIcon style={{color:"#f37721"}}/></ListItemIcon>
             <ListItemText primary='Location' />
           </ListItem> 
           </>
            } 
            {(localRole === '4'  || localRole === '1' || localRole ==='2' )  &&  
            <ListItem className={classes.selected}  button selected={props.drawerIndex === 4} onClick={() => history.push("/candidate")}>
              <ListItemIcon> <PersonPinIcon style={{color:"#f37721"}}/></ListItemIcon>
              <ListItemText primary='Candidates' />  
            </ListItem>  }
            <ListItem className={classes.selected}  button selected={props.drawerIndex === 5} onClick={() => history.push("/evaluation/1")}>
              <ListItemIcon> <BorderColorIcon style={{color:"#f37721"}}/></ListItemIcon>
              <ListItemText primary='Evaluation' />
            </ListItem> 
            {(localRole === '1' || localRole === '2')  && 
            <ListItem className={classes.selected}  button selected={props.drawerIndex === 6} onClick={() => history.push("/Report/results/"+sessionStorage.getItem("location_id"))}>
              <ListItemIcon> <AssessmentIcon style={{color:"#f37721"}}/></ListItemIcon>
              <ListItemText primary='Result Report' />
            </ListItem> }
            {localRole === '4'  &&
            <ListItem className={classes.selected}  button selected={props.drawerIndex ===11} onClick={() => history.push("/scholarship")}>
             <ListItemIcon> <SchoolIcon style={{color:"#f37721"}}/></ListItemIcon>
             <ListItemText primary='Scholarship' />
           </ListItem>
            }
            {(localRole === '4' || localRole === '1' || localRole === '2') &&
            <ListItem className={classes.selected}  button selected={props.drawerIndex === 8} onClick={() => history.push("/documents")}>
              <ListItemIcon> <CheckCircleIcon style={{color:"#f37721"}}/></ListItemIcon>
              <ListItemText primary='Document Check' />
            </ListItem>
            }
            { localRole === '1' &&  
            <ListItem className={classes.selected}  button selected={props.drawerIndex === 9} onClick={() => history.push("/report/candidates/"+sessionStorage.getItem("location_id"))}>
             <ListItemIcon> <PeopleOutlineIcon style={{color:"#f37721"}}/></ListItemIcon>
             <ListItemText primary='Candidate Report' />
           </ListItem>  
            }
            {localRole === '4'  && <> 
            <ListItem className={classes.selected}  button selected={props.drawerIndex === 9} onClick={() => history.push("/Report/candidate")}>
             <ListItemIcon> <PeopleOutlineIcon style={{color:"#f37721"}}/></ListItemIcon>
             <ListItemText primary='Candidate Report' />
           </ListItem>
             <ListItem className={classes.selected}  button selected={props.drawerIndex === 7} onClick={() => history.push("/Report/result")}>
             <ListItemIcon> <AssessmentIcon style={{color:"#f37721"}}/></ListItemIcon>
             <ListItemText primary='Result Report' />
           </ListItem>
            <ListItem button key='4' selected={props.drawerIndex === 10} onClick={() => history.push("/users")}>
              <ListItemIcon> <PersonAddIcon style={{color:"#f37721"}}/></ListItemIcon>
              <ListItemText primary='Users' />
            </ListItem> </>}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content} >
        {props.main}
      </main>
      </Router>
    </div>
  );
}
