import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    Select,
    TextField,
    IconButton
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import Navigation from '../../Nav/Nav';
import * as api from '../../Api';
import MenuItem from '@material-ui/core/MenuItem';
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function UserEdit(props) {
    const userQuery = props.location.query;
    const user = userQuery.user;
    const initalArray = user.roles;
    const classes = useStyles();
    const [programList, setProgramList] = useState([])
    const [batchList, setBatchList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [location, setLocation] = useState("");
    const [locationName, setLocationName] = useState("");
    const history = useHistory();
    const [addProgram, setAddProgram] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [value, setValue] = useState(0);
    const [rolesList, setRolesList] = useState([]);
    const [personName, setPersonName] = useState();
    const [roleName, setRoleName] = useState({ name: "", id: "" });
    const [rolesArray, setRolesArray] = useState(user.roles)
    const phone = user['phone'].substring(3);
    const [userObj, setUserObj] = useState({
        "name": user['name'],
        "email": user['email'],
        "phone": phone,
        "education": user['education'],
        "date_of_joining": user['date_of_joining'],
        "date_of_leaving": user['date_of_leaving'],
        "isactive": user['isactive'],
        "user_roles": user['roles']
    })

    const handleCreateRoles = () => {
        let array = rolesArray
        if (roleName.id === 4) {
            array = []
        }
        let temp = { "role_name": roleName.name, "location_name": roleName.id === 4 ? "All Location" : locationName.name, "role_id": roleName.id, "location_id": locationName.id }
        array.push(temp)
        setRolesArray(array)
    }

    const handleChangeProgram = (event) => {
        api.getBatches(event.target.value)
            .then(function (response) {
                // handle success 
                setBatchList(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }

    const handleChangeBatch = (event) => {
        api.getLocations(event.target.value)
            .then(function (response) {
                // handle success 
                if (response.data) {
                    if (rolesArray.length > 0) {
                        let tempObj = response.data.filter((data) =>
                            !(rolesArray.map((row) => {
                                return row['location_id']
                            }).includes(data.id))
                        )
                        setLocationList(tempObj);
                    }
                    else {
                        setLocationList(response.data)
                    }
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }

    const handleRoles = (event) => {
        setRoleName({ name: event.target.value.split('/')[1], id: +(event.target.value.split('/')[0]) });
        setPersonName(event.target.value);
    };

    const createUser = () => {
        setOpen(true)
        let array = []
        initalArray.map((inital) => {
            if (inital.id) {
                array.push({ "id": inital.id, "_destroy": true })
            }
        })
        rolesArray.map((role) => {
            if (role.role_id == 4) {
                array.push({ "role_id": role.role_id })
            }
            else {
                array.push({ "role_id": role.role_id, "location_id": role.location_id })
            }
        })
        let objectAdd = {
            "name": userObj.name,
            "email": userObj.email,
            "phone": `+91${userObj.phone}`,
            "education": userObj.education,
            "date_of_joining": userObj.date_of_joining,
            "date_of_leaving": userObj.date_of_leaving,
            "isactive": userObj.isactive,
            "user_roles_attributes": array
        }
        api.setUser(user.id, objectAdd)
            .then(response => {
                toast.success('User Updated Successfully!', {
                    position: "bottom-center",
                    autoClose: 3000,
                    onClose: () => { setAddProgram(true) },
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                setOpen(false)
            }).catch(error => {
                setOpen(false)
                console.error(error)
                toast.error('Error in Connection!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRolesDelete = (index) => {
        let temp = []
        rolesArray.map((row, i) => {
            if (index !== i) {
                temp.push(row)
            }
        }
        )
        setRolesArray(temp)
    }

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const getPrograms = () => {
        api.getPrograms()
            .then(function (response) {
                // handle success 
                setProgramList(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }

    useEffect(() => {
        getPrograms()
        api.getRoles()
            .then(function (response) {
                // handle success 
                setRolesList(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    return (
        <Navigation main={
            <div className={classes.root} style={{ width: "100%" }}>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <BackDrop open={open} handleClose={() => { setOpen(false) }} />
                <Card className={
                    classes.classroot
                }>
                    <CardContent>
                        <Typography className={
                            classes.title
                        }
                            variant="h5"
                            style={
                                { color: "black" }
                            }
                            gutterBottom>
                            Edit User
                        </Typography>
                        <Typography variant="body2" component="p">
                            <TableContainer >
                                <Table className={
                                    classes.table
                                }
                                    aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width="150px" style={
                                                { fontWeight: 'bold' }
                                            }>Name<spam style={
                                                { color: 'red' }
                                            }>*</spam>
                                            </TableCell>
                                            <TableCell align="left"><TextField className={
                                                classes.textInput
                                            }
                                                fullWidth
                                                defaultValue={user['name']}
                                                variant="outlined"
                                                margin="dense"
                                                error
                                                //  helperText={batchCodeError}   
                                                onInput={
                                                    e => {
                                                        setUserObj(prev => ({
                                                            ...prev,
                                                            name: e.target.value
                                                        }))
                                                    }
                                                }
                                                lable="Batch Code" /></TableCell>
                                            <TableCell width="150px" style={
                                                { fontWeight: 'bold' }
                                            }>Email<spam style={
                                                { color: 'red' }
                                            }>*</spam>
                                            </TableCell>
                                            <TableCell align="left"><TextField className={
                                                classes.textInput
                                            }
                                                fullWidth
                                                error
                                                // helperText={nameError}
                                                defaultValue={user['email']}
                                                variant="outlined"
                                                margin="dense"
                                                onInput={
                                                    e => {
                                                        setUserObj(prev => ({
                                                            ...prev,
                                                            email: e.target.value
                                                        }))
                                                    }
                                                }
                                                lable="Batch Name" /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell width="150px" style={
                                                { fontWeight: 'bold' }
                                            }>Phone<spam style={
                                                { color: 'red' }
                                            }>*</spam>
                                            </TableCell>
                                            <TableCell align="left"><TextField className={
                                                classes.textInput
                                            }
                                                fullWidth
                                                type='number'
                                                defaultValue={phone}
                                                variant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                                }}
                                                onInput={
                                                    e => {
                                                        setUserObj(prev => ({
                                                            ...prev,
                                                            phone: e.target.value
                                                        }))
                                                    }
                                                }
                                                lable="Batch Code" /></TableCell>
                                            <TableCell width="150px" style={
                                                { fontWeight: 'bold' }
                                            }>Education<spam style={
                                                { color: 'red' }
                                            }>*</spam>
                                            </TableCell>
                                            <TableCell align="left"><TextField className={
                                                classes.textInput
                                            }
                                                fullWidth
                                                error
                                                // helperText={nameError}
                                                variant="outlined"
                                                margin="dense"
                                                efaultValue={userObj['education']}
                                                onInput={
                                                    e => {
                                                        setUserObj(prev => ({
                                                            ...prev,
                                                            education: e.target.value
                                                        }))
                                                    }
                                                }
                                                lable="Batch Name" /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={
                                                { fontWeight: 'bold' }
                                            }>Date of Join<spam style={
                                                { color: 'red' }
                                            }>*</spam>
                                            </TableCell>
                                            <TableCell align="left"><TextField
                                                className={
                                                    classes.textInput
                                                }
                                                fullWidth
                                                error
                                                //helperText={startDateError}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={userObj['date_of_joining']}
                                                type='date'
                                                onInput={
                                                    e => {
                                                        setUserObj(prev => ({
                                                            ...prev,
                                                            date_of_joining: e.target.value
                                                        }))
                                                    }
                                                }
                                            /></TableCell>
                                            <TableCell style={
                                                { fontWeight: 'bold' }
                                            }>Date of Leaving
                                            </TableCell>
                                            <TableCell align="left"><TextField
                                                className={
                                                    classes.textInput
                                                }
                                                fullWidth
                                                error
                                                // helperText={endDateError}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={userObj['date_of_leaving']}
                                                type='date'
                                                onInput={
                                                    e => {
                                                        setUserObj(prev => ({
                                                            ...prev,
                                                            date_of_leaving: e.target.value
                                                        }))
                                                    }
                                                }
                                            />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>

                                            <TableCell style={
                                                { fontWeight: 'bold' }
                                            }>Status
                                            </TableCell>
                                            <TableCell align="left">
                                                <Select native
                                                    className={
                                                        classes.textInput
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={userObj['isactive']}
                                                    onChange={
                                                        e => {
                                                            setUserObj(prev => ({
                                                                ...prev,
                                                                isactive: e.target.value
                                                            }))
                                                        }
                                                    }
                                                >
                                                    <option value="true">Active</option>
                                                    <option value="false">Inactive</option>
                                                </Select>
                                            </TableCell>
                                            <TableCell colSpan="2" align="center">
                                                {(rolesArray.length > 0 && rolesArray[0].role_id === 4) ? <></> :
                                                    <Fab variant="extended" color="primary" style={{ color: "#f37721", backgroundColor: "white", fontWeight: "bold" }} aria-label="add" onClick={() => { setOpenDialog(true) }}>
                                                        <AddIcon className={classes.selected} />
                                                        Add Role & Location
                                                    </Fab>}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Typography>
                        <Typography variant="body2" component="p"  >
                            <TableContainer >
                                <Table style={{ width: "500px", margin: "auto" }}
                                    aria-label="spanning table" size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={
                                                { fontWeight: 'bold' }
                                            }>Sl.No.</TableCell>
                                            <TableCell align="center" style={
                                                { fontWeight: 'bold' }
                                            }>Role & Location</TableCell>
                                            <TableCell width="10px"></TableCell>
                                        </TableRow>
                                        {rolesArray.length > 0 && rolesArray.map((role, ind) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{ind + 1}</TableCell>
                                                    <TableCell align="center" ><span style={{ color: 'green' }}>"{role.role_name}"</span> Access to <span style={{ color: 'green' }}>"{role.location_name}"</span></TableCell>
                                                    <TableCell><IconButton onClick={() => { handleRolesDelete(ind) }}><HighlightOffIcon color="secondary" /></IconButton> </TableCell>
                                                </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Typography>
                        <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
                            <DialogTitle align="center">Select Role & Location</DialogTitle>
                            <DialogContent>
                                <Typography variant="body2" component="p">
                                    <TableContainer >
                                        <Table className={
                                            classes.table
                                        }
                                            aria-label="spanning table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width="100px" style={
                                                        { fontWeight: 'bold' }
                                                    }>Role<spam style={
                                                        { color: 'red' }
                                                    }>*</spam></TableCell>
                                                    <TableCell width="300px" align="left" >
                                                        <Select native
                                                            className={
                                                                classes.textInput
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={personName}
                                                            onChange={handleRoles}
                                                        >
                                                            <option value="">
                                                                Select Role
                                                            </option>
                                                            {rolesList.map((name) => (
                                                                <option key={name.id} name={name.role_name} value={name.id + "/" + name.role_name}>
                                                                    {name.role_name}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                                {(roleName.id === 1 || roleName.id === 2 || roleName.id === 3) && <>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', width: "200px" }}>Select Program<spam style={{ color: 'red' }}>*</spam></TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                fullWidth
                                                                variant="outlined"
                                                                margin="dense"
                                                                labelId="demo-controlled-open-select-label"
                                                                id="demo-controlled-open-select"
                                                                onChange={handleChangeProgram}
                                                            >
                                                                {programList.map((row) => {
                                                                    return <MenuItem value={row.id}>{row.program_name}</MenuItem>
                                                                })
                                                                }
                                                            </Select>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', width: "200px" }}>Select Batch<spam style={{ color: 'red' }}>*</spam></TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                fullWidth
                                                                variant="outlined"
                                                                margin="dense"
                                                                labelId="demo-controlled-open-select-label"
                                                                id="demo-controlled-open-select"
                                                                onChange={handleChangeBatch}
                                                            >
                                                                {batchList.length > 0 ? batchList.map((row) => {
                                                                    return <MenuItem value={row.id}>{row.batch_name}</MenuItem>
                                                                }) : <MenuItem style={{ color: "red" }}>Choose A Program</MenuItem>
                                                                }
                                                            </Select>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', width: "200px" }}>Select Location<spam style={{ color: 'red' }}>*</spam></TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                fullWidth
                                                                variant="outlined"
                                                                margin="dense"
                                                                labelId="demo-controlled-open-select-label"
                                                                id="demo-controlled-open-select"
                                                                onChange={(event) => { setLocation(event.target.value); setLocationName({ name: event.target.value.split('/')[1], id: +(event.target.value.split('/')[0]) }) }}
                                                            >
                                                                {locationList.length > 0 ? locationList.map((row) => {
                                                                    return <MenuItem value={row.id + "/" + row.location_name}>{row.location_name}</MenuItem>
                                                                }) : <MenuItem style={{ color: "red" }}>Choose A Batch</MenuItem>
                                                                }
                                                            </Select>
                                                        </TableCell>
                                                    </TableRow>
                                                </>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { setOpenDialog(false) }} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => { handleCreateRoles(); setOpenDialog(false); setLocationName(""); setRoleName("") }} color="primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </CardContent>
                    <Button margin="normal" variant="contained"
                        style={
                            {
                                color: "white",
                                backgroundColor: "#f37721"
                            }
                        }
                        onClick={
                            (value) => createUser()
                        }
                        color='default'>Update</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button margin="normal" variant="contained"
                        style={
                            { backgroundColor: "#d9d9d9" }
                        }
                        href="/users"
                        color='default'>Cancel</Button>
                    <CardActions></CardActions>
                </Card>
                <div> {
                    addProgram ? <Redirect to={history.push({ pathname: "/users" })
                    } /> : null
                } </div>
            </div>
        } />
    );
}
