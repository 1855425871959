import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Select } from '@material-ui/core';
import { Redirect } from 'react-router';
import BackDrop from '../Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../../Nav/Nav';
import { useHistory } from 'react-router-dom';
import * as api from '../../Api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardroot: {
    minWidth: 275,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  textInput: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: '1.8px',
      borderColor: "grey"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: '1.5px',
      borderColor: "#f37721"
    }
  }
}));

export default function BatchEdit(props) {

  const batchQuery = props.location.query;
  const batch = batchQuery.batch;
  const classes = useStyles();
  const history = useHistory();
  const [nameError, setNameError] = useState("")
  const [startDateError, setStartDateError] = useState("")
  const [open, setOpen] = useState(false);
  const [endDateError, setEndDateError] = useState("")
  const [isAddBatch, setAddBatch] = React.useState(false);
  const [batchObj, setBatchObj] = React.useState({
    "batch_code": batch['batch_code'],
    "batch_name": batch['batch_name'],
    "description": batch['description'],
    "start_date": batch['start_date'],
    "end_date": batch['end_date'],
    "isactive": batch['isactive']
  })

  const createBatch = () => {
    if (batchObj.batch_name === "") {
      setNameError("Enter the Batch Name")
    }
    else if (batchObj.start_date === "") {
      setStartDateError("Enter the Start Date")
    }
    else if (batchObj.end_date === "") {
      setEndDateError("Enter the End Date")
    }
    else {
      setOpen(true)
      api.setBatch(batch['id'], batchObj)
        .then(response => {
          toast.success('Batch Updated Successfully!', {
            position: "bottom-center",
            autoClose: 3000,
            onClose: () => { setAddBatch(true) },
            hideProgressBar: false,
            closeOnClick: true,
          });
          setOpen(false)
        })
        .catch(error => {
          setOpen(false)
          console.error(error)
          toast.error('Error in Connection!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        })
    }
  }
  return (
    <Navigation main={
      <div className={classes.root}>
        <main
          className={classes.content}
        >
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <BackDrop open={open} handleClose={() => { setOpen(false) }} />
          <Grid container justify="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={12}>
              <Card className={classes.classroot}>
                <CardContent>
                  <Typography className={classes.title} variant="h5" style={{ color: "black" }} gutterBottom>
                    Edit Batch
                  </Typography>
                  <Typography variant="body2" component="p">
                    <TableContainer >
                      <Table className={classes.table} aria-label="spanning table">
                        <TableBody>
                          <TableRow>
                            <TableCell width="150px" style={{ fontWeight: 'bold' }}>Batch Code</TableCell>
                            <TableCell align="left">{batch['batch_code']}</TableCell>
                            <TableCell width="150px" style={{ fontWeight: 'bold' }}>Batch Name<spam style={{ color: 'red' }}>*</spam></TableCell>
                            <TableCell align="left"><TextField error helperText={nameError} className={classes.textInput} fullWidth variant="outlined" margin="dense" defaultValue={batch['batch_name']} onInput={e => {
                              setBatchObj(prev => ({ ...prev, batch_name: e.target.value }))
                              setNameError("")
                            }} /></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Start Date<spam style={{ color: 'red' }}>*</spam></TableCell>
                            <TableCell align="left"><TextField error helperText={startDateError} className={classes.textInput} fullWidth variant="outlined" margin="dense" type='date' defaultValue={batch['start_date']} onInput={e => {
                              setBatchObj(prev => ({ ...prev, start_date: e.target.value }))
                              setStartDateError("")
                            }} /></TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>End Date<spam style={{ color: 'red' }}>*</spam></TableCell>
                            <TableCell align="left"><TextField error helperText={endDateError} className={classes.textInput} fullWidth variant="outlined" margin="dense" type='date' defaultValue={batch['end_date']} onInput={e => {
                              setBatchObj(prev => ({ ...prev, end_date: e.target.value }))
                              setEndDateError("")
                            }} /></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                            <TableCell align="left" colSpan="3"><TextField className={classes.textInput} fullWidth variant="outlined" margin="dense" multiline maxRows={4} defaultValue={batch['description']} onInput={e => setBatchObj(prev => ({ ...prev, description: e.target.value }))} /></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Status<spam style={{ color: 'red' }}>*</spam></TableCell>
                            <TableCell align="left">
                              <Select native className={classes.textInput} fullWidth variant="outlined" margin="dense"
                                value={batchObj['isactive']}
                                onChange={e => setBatchObj(prev => ({ ...prev, isactive: e.target.value }))}>
                                <option value="true">Active</option>
                                <option value="false">In-Active</option>
                              </Select>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </CardContent>
                <Button margin="normal" variant="contained" style={{ color: "white", backgroundColor: "#f37721" }}
                  onClick={(value) => createBatch()} color='default'>Update</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                <Button margin="normal" variant="contained" style={{ backgroundColor: "#d9d9d9" }} href="/batch" color='default'>Cancel</Button>
                <CardActions >
                </CardActions>
              </Card>
            </Grid></Grid>
        </main>
        <div>
          {isAddBatch ?
            <Redirect to={history.push({ pathname: `/batch` })} />
            : null}
        </div>
      </div>}
      drawerIndex={2}
    />
  );
}


